import React from 'react';
import styled from 'styled-components';
import CTAGreen from 'components/buttons/CTAGreen';
import { URL_NUTRITASTIC_ASSETS } from 'constants/URLS';

const StyledShowSuccessGraph = styled.div`
  .onboarding-question {
    font-size: 34px;
    line-height: 44px;

    span {
      padding: 0px 4px;
      background: rgb(254, 240, 194);
    }
  }

  .onboarding-question-text {
    font-size: 20px;
    margin: 20px 0 !important;
  }

  .lose-weight-wrapper {
    text-align: center;
    position: relative;
    padding-top: 20px;
    margin-top: 20px;

    .start-marker {
      position: absolute;
      background-color: rgb(0, 0, 0);
      color: #fff;
      border-radius: 6px;
      padding: 5px 10px;
      top: 0;
      left: 0;
      font-weight: 700;
      font-size: 16px;
    }

    .goal-marker {
      position: absolute;
      background-color: rgb(18, 156, 144);
      color: #fff;
      border-radius: 6px;
      padding: 5px 10px;
      bottom: 40%;
      font-weight: 700;
      font-size: 16px;
      right: 0;
      z-index: 9;
    }
  }

  .gain-weight-wrapper {
    text-align: center;
    position: relative;
    padding-top: 30px;

    .start-marker {
      position: absolute;
      background-color: rgb(0, 0, 0);
      color: #fff;
      border-radius: 6px;
      padding: 5px 10px;
      bottom: 20%;
      left: 0;
      font-weight: 700;
      font-size: 16px;
      z-index: 9;
    }

    .goal-marker {
      position: absolute;
      background-color: rgb(18, 156, 144);
      color: #fff;
      border-radius: 6px;
      padding: 5px 10px;
      top: 0;
      font-weight: 700;
      font-size: 16px;
      right: 0;
      z-index: 9;
      box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    }
  }

  .features-headline {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }

  .features {
    margin: 20px 0 0;

    li {
      color: #222;
      font-size: 16px;
      margin-bottom: 10px;
      padding-left: 38px;
      position: relative;
      list-style-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTExLjY2MzkgMC44NzE1MjVDMTAuMDA0NCAyLjgwMDcxIDguMzI0MSA0LjgwMjQ5IDYuODcyMDIgNi44NzY4OEM2LjEzNTYxIDUuNTkwNzYgNS40OTI1NSA0LjI5NDI3IDQuNzk3NjMgMy4wMDgxNUMzLjI0MTg0IDAuMDYyNTA5NyAtMS4xNzY2MiAyLjY0NTEzIDAuMjk2MjAyIDUuNjczNzRDMS41ODIzMyA4LjE2MzAxIDIuOTYxOCAxMC42NDE5IDQuMzQxMjcgMTMuMDM3OEM1LjEyOTU0IDE0LjI4MjUgNi43NjgzMSAxNC42NTU5IDguMDEyOTQgMTMuODc4QzguMzU1MjIgMTMuNjcwNSA4LjYzNTI2IDEzLjM4MDEgOC44NTMwNyAxMy4wMzc4QzEwLjg3NTYgMTAuMTMzNyAxMy4wNTM3IDcuMzMzMjUgMTUuMzc3IDQuNjY3NjZDMTcuNDUxNCAxLjk4MTMyIDEzLjg2MjcgLTEuNzAwNzIgMTEuNjYzOSAwLjg3MTUyNVoiIGZpbGw9IiNBMERGREEiLz4KPHBhdGggZD0iTTEuMTY3NDggNS45MzMxM0MzLjAxMzY5IDguNjkyMDcgNC45NDI4NyAxMS40NTEgNy4wNTg3NSAxNC4wMzM2QzkuOTYyOSAxMC4zNzIzIDEyLjYzODkgNi41MjQzMyAxNS4wNjU5IDIuNTMxMTMiIHN0cm9rZT0iIzAzMDAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0yLjI3NzEgNS4wMDk5OUMyLjg2ODMgNC41ODQ3NCAzLjUxMTM2IDQuMjExMzUgNC4xNzUxNyAzLjkxMDU3QzUuMDY3MTYgNS40MzUyNSA2LjA1MjQ5IDYuOTE4NDQgNy4xMjA4MSA4LjMyOTAyQzguNTAwMjggNi4yNTQ2MyAxMi4xODIzIDAuODcxNTgyIDEyLjMwNjggMC44NzE1ODJDMTIuNDMxMyAwLjg3MTU4MiAxNS4xNTkxIDIuNjI0NDQgMTUuMDY1NyAyLjYyNDQ0IiBzdHJva2U9IiMwMzAwMDAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
      margin-left: 0;
      padding-left: 8px;
      font-weight: 450;
    }
  }

  .compare-wrapper {
    margin-top: 40px;

    .compare {
      display: flex;
      align-items: center;
      justify-content: center;

      .compare-line-us {
        width: 32px;
        height: 12px;
        margin-right: 11px;
        display: inline-block;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0px;
          top: 50%;
          height: 5px;
          transform: translateY(-50%);
          width: 100%;
          background-color: rgb(18, 156, 144);
          border-radius: 5px;
        }
      }

      .compare-line-others {
        width: 32px;
        height: 12px;
        margin-right: 11px;
        display: inline-block;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0px;
          top: 50%;
          height: 5px;
          transform: translateY(-50%);
          width: 100%;
          background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzUiIGhlaWdodD0iNCIgdmlld0JveD0iMCAwIDM1IDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDJIMzMiIHN0cm9rZT0iIzhGOEY4RiIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1kYXNoYXJyYXk9IjggOCIvPgo8L3N2Zz4K);
          border-radius: 5px;
        }
      }
    }
  }
`;

export default function ShowSuccessGraph({
  weight,
  goalWeight,
  setCurrentStep,
  scrollToWidget,
  currentStep,
}) {
  return (
    <StyledShowSuccessGraph>
      <div className="onboarding-question">Was dich in unserer App erwartet:</div>

      <ul className="features">
        <li>Dein persönlicher Ernährungsplan</li>
        <li>5 Zutaten & 15 Minuten oder weniger pro Mahlzeit</li>
        <li>Ohne Kalorien zählen & ohne Kochen</li>
        <li>100% angepasst an persönliche Ziele & Zeit</li>
        <li>Einfacher geht's nicht!</li>
      </ul>

      {weight >= goalWeight && (
        <div className="lose-weight-wrapper">
          <div className="start-marker">🚀 {weight}kg</div>
          <div className="goal-marker">🤩 {goalWeight}kg</div>
          <img src={`${URL_NUTRITASTIC_ASSETS}onboarding/lose-weight-graph.png`} alt="" />
        </div>
      )}

      {weight < goalWeight && (
        <div className="gain-weight-wrapper">
          <div className="start-marker">🚀 {weight}kg</div>
          <div className="goal-marker">🤩 {goalWeight}kg</div>
          <img src={`${URL_NUTRITASTIC_ASSETS}onboarding/gain-weight-graph.png`} alt="" />
        </div>
      )}

      <div className="compare-wrapper">
        <div className="compare">
          <div className="compare-line-us"></div>
          <div className="compare-text">Unser Ernährungs-App</div>
        </div>

        <div className="compare">
          <div className="compare-line-others"></div>
          <div className="compare-text">Crash-Diäten</div>
        </div>
      </div>

      <div className="text-center mt-4 mb-4">
        <small>Dies ist ein vorläufiger Zeitplan, der auf deinen Antworten basiert.</small>
      </div>

      <div className="mt-4">
        <CTAGreen
          text={'Weiter'}
          onClick={() => {
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        ></CTAGreen>
      </div>
    </StyledShowSuccessGraph>
  );
}
