import React from 'react';
import styled from 'styled-components';

const StyledTopline = styled.div`
  color: #929292;
  font-size: 16px;
  text-align: center;
`;

export default function Topline({ children, ...props }) {
  return <StyledTopline {...props}>{children}</StyledTopline>;
}
