import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MinusCircle from './SVG/MinusCircle';
import PlusCircle from './SVG/PlusCircle';

const StyledServingsControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 20px;

  .servings-select-value {
    font-size: 16px;
    font-weight: 700;
  }

  .servings-decrease,
  .servings-increase {
    margin: 0 20px;

    &.is-disabled {
      opacity: 0.5;
    }

    svg {
      width: 26px;
      height: 26px;
      fill: #ddd;
      cursor: pointer;
      border-radius: 50%;
      transition: 0.1s all ease;

      &:hover {
        fill: #03a9f4;
        box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
      }
    }
  }
`;

export default function ServingsControl({ servings, onIncrease, onDeacrease, maxServings }) {
  const { t } = useTranslation('common');

  return (
    <StyledServingsControl>
      {onDeacrease && (
        <div
          className={`servings-decrease ${servings <= 0 ? 'is-disabled' : ''}`}
          onClick={() => {
            if (servings > 1) {
              onDeacrease();
            }
          }}
        >
          <MinusCircle />
        </div>
      )}
      <div className="servings-select-value text-center">
        {maxServings && <>{`${servings} / ${maxServings} ${t('servings')}`}</>}
        {!maxServings && <>{`${servings} ${t('servings')}`}</>}
      </div>
      {onIncrease && (
        <div
          className={`servings-increase`}
          onClick={() => {
            if (servings < maxServings) {
              onIncrease();
            }
          }}
        >
          <PlusCircle />
        </div>
      )}
    </StyledServingsControl>
  );
}
