export function filterMeals(mixAndMatchChoices, diet, nutrition) {
  let filteredMixAndMatchChoices = mixAndMatchChoices.filter((choice) => {
    return choice.possibleDiets.includes(diet) && choice.possibleNutrition.includes(nutrition);
  });

  filteredMixAndMatchChoices.forEach((choice) => {
    choice.steps.forEach((step) => {
      let filteredIngredients = step.ingredients.filter((ingredient) => {
        return (
          ingredient.detail.diet.includes(diet) && ingredient.detail.nutrition.includes(nutrition)
        );
      });

      step.ingredients = filteredIngredients;
    });
  });

  filteredMixAndMatchChoices = filteredMixAndMatchChoices.filter((choice) => {
    let isChoiceValid = true;
    choice.steps.forEach((step) => {
      step.ingredients.length === 0 && (isChoiceValid = false);
    });

    if (isChoiceValid) {
      return choice;
    }
  });

  return filteredMixAndMatchChoices;
}
