import { MEAL_TYPE_MIX_MATCH } from 'constants/mealPlanConstants';
import Fuse from 'fuse.js';
import { getColorByIndex } from './colorGenerator';

export function enhanceRecipes(recipes, foodData) {
  const fuse = new Fuse(foodData, {
    includeScore: false,
    shouldSort: true,
    includeMatches: false,
    minMatchCharLength: 2,
    //location: 0,
    threshold: 0.35,
    //distance: 100,
    useExtendedSearch: false,
    ignoreLocation: true,
    ignoreFieldNorm: false,
    keys: ['title'],
  });

  recipes.forEach((recipe) => {
    recipe.ingredients.forEach((ingredient) => {
      let food = fuse.search(ingredient.title);

      if (food.length > 0) {
        ingredient.ingredientDetail = food[0].item;
        ingredient.thumbnail = food[0].item.thumbnail;
        ingredient.image = food[0].item.image;
      } else {
        ingredient.ingredientDetail = {
          category: ['other'],
          title: ingredient.title,
        };
        ingredient.thumbnail = 'https://low-carb-kitchen.de/database/data/no-image2.png';
        ingredient.image = 'https://low-carb-kitchen.de/database/data/no-image2.png';
      }
    });
  });
}

export async function enhanceMixAndMatchItems(data) {
  let mixAndMatchItems = data.mix_and_match;

  mixAndMatchItems.map((mix_and_match_item, mix_and_match_index) => {
    mix_and_match_item.type = MEAL_TYPE_MIX_MATCH;
    mix_and_match_item.bgColor = getColorByIndex(mix_and_match_index);
    mix_and_match_item.steps.map((mix_and_match_step) => {
      mix_and_match_step.ingredients.map((mix_and_match_step_ingredient) => {
        const ingredientDetail = data.ingredients.find((ingredient) => {
          return ingredient.id === mix_and_match_step_ingredient.ingredient;
        });
        mix_and_match_step_ingredient.detail = ingredientDetail;
        mix_and_match_step_ingredient.defaultAmount = mix_and_match_step_ingredient.amount;

        /*const food = fuse.search(mix_and_match_step_ingredient.detail.title);
        if (food.length > 0) {
          mix_and_match_step_ingredient.detail.thumbnail = food[0].item.thumbnail;
          mix_and_match_step_ingredient.detail.image = food[0].item.image;
        } else {
          mix_and_match_step_ingredient.detail.thumbnail =
            'https://low-carb-kitchen.de/database/data/no-image2.png';
          mix_and_match_step_ingredient.detail.image =
            'https://low-carb-kitchen.de/database/data/no-image2.png';
        }*/

        let possibleDiets = [];
        mix_and_match_step_ingredient.detail.diet.forEach((diet) => {
          if (!possibleDiets.includes(diet)) {
            possibleDiets.push(diet);
          }
        });
        mix_and_match_item.possibleDiets = possibleDiets;

        let possibleNutrition = [];
        mix_and_match_step_ingredient.detail.nutrition.forEach((nutrition) => {
          if (!possibleNutrition.includes(nutrition)) {
            possibleNutrition.push(nutrition);
          }
        });
        mix_and_match_item.possibleNutrition = possibleNutrition;
      });
    });
  });

  return mixAndMatchItems;
}
