import React from 'react';
import styled from 'styled-components';

export const StyledSectionHeader = styled.div`
  margin: 0 0 20px;
  font-weight: 700;
  border-bottom: 1px solid whitesmoke;
  padding-bottom: 10px;
  width: 100%;
  font-size: 16px;
  text-align: center;
`;

export default function SectionHeader({ children, ...props }) {
  return <StyledSectionHeader {...props}>{children}</StyledSectionHeader>;
}
