import React, { useEffect, useState, useRef } from 'react';
import { useAPI } from '../../context/APIcontext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import SectionHeader from 'components/Typo/SectionHeader';
import EmptyState from 'components/EmptyState';
import CartIcon from 'components/SVG/CartIcon';
import { displayIngredientAmount } from 'helper/displayIngredientAmount';
import { MEAL_TYPE_MIX_MATCH, MEAL_TYPE_RECIPE } from 'constants/mealPlanConstants';
import DeleteIcon from 'components/SVG/DeleteIcon';
import StyledCTAGrey from 'components/buttons/CTAGrey';
import Fuse from 'fuse.js';

const StyledShoppingListPage = styled.div`
  .shopping-list-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    border-radius: 8px;
    color: #222;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    &.is-checked {
      text-decoration: line-through;
    }

    .shopping-list-item-image {
      height: 40px;
      min-width: 40px;
      width: 40px;
      background-size: cover;
      background-position: 50% 50%;
      display: inline-block;
      margin-right: 10px;
      border-radius: 8px;
      background-color: #222;
      border: 1px solid #ddd;
    }

    .shopping-list-item-amount {
      color: rgba(0, 0, 0, 0.75);
      width: 100px;
    }
  }
`;

const StyledMealPlanCalender = styled.div`
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 4px 0 10px 4px;

  @media (min-width: 768px) {
    overflow: hidden;
  }

  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 100px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    pointer-events: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .meal-plan-calender-item {
    background-color: whitesmoke;
    width: 40px;
    height: 40px;
    padding: 30px 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s all;
    margin-right: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

    small {
      font-size: 14px;
      font-weight: 400;
    }

    &:hover {
      color: #03a9f4;
    }

    &.is-selected {
      background-color: #03a9f4;
      color: #fff;
    }
  }
`;

export default function ShoppingListPage({ ...props }) {
  const { userData, setUserData, saveUserData, dbData } = useAPI();
  const { t } = useTranslation('common');

  const [shoppingListByCategory, setShoppingListByCategory] = useState([]);

  useEffect(() => {
    triggerCalculation(userData);
  }, []);

  function triggerCalculation(userData) {
    let shoppingListArray = [];

    userData.mealPlan.forEach((day) => {
      if (day.addedToShoppingList) {
        day.meals.forEach((meal) => {
          meal.detail.steps.forEach((mealStep) => {
            mealStep.selectedIngredient.forEach((selectedIngredient) => {
              let foundIndex = shoppingListArray.findIndex(
                (item) => item.id === selectedIngredient.detail.id,
              );
              if (foundIndex !== -1) {
                shoppingListArray[foundIndex].amount += Number(selectedIngredient.amount);
              } else {
                shoppingListArray.push({
                  id: selectedIngredient.detail.id,
                  amount: Number(selectedIngredient.amount),
                  totalAmountInGrams: Number(selectedIngredient.totalAmountInGrams),
                  shoppingCategory: selectedIngredient.detail.shoppingCategory,
                  title: selectedIngredient.detail.title,
                  image: selectedIngredient.detail.image,
                  thumbnail: selectedIngredient.detail.thumbnail,
                  serving_unit: selectedIngredient.detail.serving_unit,
                  markedOnShoppingList: selectedIngredient.markedOnShoppingList,
                  additional_text: selectedIngredient.detail.additional_text,
                  serving_unit_in_grams: selectedIngredient.detail.serving_unit_in_grams,
                  type: MEAL_TYPE_MIX_MATCH,
                });
              }
            });
          });
        });

        day.recipes.forEach((recipe) => {
          recipe.ingredients.forEach((ingredient) => {
            const fuse = new Fuse(dbData.food, {
              includeScore: false,
              shouldSort: true,
              includeMatches: false,
              minMatchCharLength: 2,
              //location: 0,
              threshold: 0.35,
              //distance: 100,
              useExtendedSearch: false,
              ignoreLocation: true,
              ignoreFieldNorm: false,
              keys: ['title'],
            });
            let food = fuse.search(ingredient.title);

            if (food.length > 0) {
              ingredient.ingredientDetail = food[0].item;
              ingredient.thumbnail = food[0].item.thumbnail;
              ingredient.image = food[0].item.image;
            } else {
              ingredient.ingredientDetail = {
                category: ['other'],
                title: ingredient.title,
              };
              ingredient.thumbnail = 'https://low-carb-kitchen.de/database/data/no-image2.png';
              ingredient.image = 'https://low-carb-kitchen.de/database/data/no-image2.png';
            }
          });

          recipe.ingredients.forEach((ingredient) => {
            shoppingListArray.push({
              id: ingredient.ingredientDetail.id,
              amount: Number(ingredient.amount),
              totalAmountInGrams: 0,
              shoppingCategory: ingredient.ingredientDetail.category,
              title: ingredient.title,
              image: ingredient.image,
              thumbnail: ingredient.thumbnail,
              serving_unit: ingredient.unit,
              markedOnShoppingList: ingredient.markedOnShoppingList,
              additional_text: ingredient.note,
              serving_unit_in_grams: 0,
              type: MEAL_TYPE_RECIPE,
            });
          });
        });
      }
    });

    setShoppingListByCategory(sortShoppingListByCategory(shoppingListArray));
  }

  function sortShoppingListByCategory(shoppingList) {
    let shoppingListByCategory = [];

    shoppingList.forEach((shoppingListItem) => {
      let category = shoppingListItem.shoppingCategory[0] || 'other';
      let foundIndex = shoppingListByCategory.findIndex((item) => item.slug === category);
      if (foundIndex === -1) {
        shoppingListByCategory.push({
          slug: category,
          items: [shoppingListItem],
        });
      } else {
        shoppingListByCategory[foundIndex].items.push(shoppingListItem);
      }
    });

    return shoppingListByCategory;
  }

  function addDayToShoppingList(dayIndex) {
    let state = { ...userData };
    if (state.mealPlan[dayIndex].addedToShoppingList) {
      state.mealPlan[dayIndex].addedToShoppingList = false;
    } else {
      state.mealPlan[dayIndex].addedToShoppingList = true;
    }
    triggerCalculation(state);
    setUserData(state, saveUserData(state));
  }

  function handleToggleIngredient(selectedIngredientID, type) {
    let state = { ...userData };
    userData.mealPlan.forEach((day) => {
      if (day.addedToShoppingList) {
        if (type === MEAL_TYPE_MIX_MATCH) {
          day.meals.forEach((meal) => {
            meal.detail.steps.forEach((mealStep) => {
              mealStep.selectedIngredient.forEach((selectedIngredient) => {
                if (selectedIngredient.detail.id === selectedIngredientID) {
                  if (selectedIngredient.markedOnShoppingList) {
                    selectedIngredient.markedOnShoppingList = false;
                  } else {
                    selectedIngredient.markedOnShoppingList = true;
                  }
                }
              });
            });
          });
        } else {
          day.recipes.forEach((recipe) => {
            recipe.ingredients.forEach((ingredient) => {
              if (ingredient.ingredientDetail.id === selectedIngredientID) {
                if (ingredient.markedOnShoppingList) {
                  ingredient.markedOnShoppingList = false;
                } else {
                  ingredient.markedOnShoppingList = true;
                }
              }
            });
          });
        }
      }
    });
    triggerCalculation(state);
    setUserData(state, saveUserData(state));
  }

  function deleteShoppingList() {
    let state = { ...userData };
    state.mealPlan.forEach((day) => {
      day.addedToShoppingList = false;
      day.meals.forEach((meal) => {
        meal.detail.steps.forEach((mealStep) => {
          mealStep.selectedIngredient.forEach((selectedIngredient) => {
            selectedIngredient.markedOnShoppingList = false;
          });
        });
      });
      if (day.recipes) {
        day.recipes.forEach((recipe) => {
          recipe.ingredients.forEach((ingredient) => {
            ingredient.markedOnShoppingList = false;
          });
        });
      }
    });
    triggerCalculation(state);
    setUserData(state, saveUserData(state));
  }

  return (
    <StyledShoppingListPage {...props}>
      <SectionHeader>{t('shopping-page.select-days')}</SectionHeader>
      <StyledMealPlanCalender className="d-flex justify-content-between align-items-center">
        {userData.mealPlan.map((day, dayIndex) => (
          <div
            className={`meal-plan-calender-item d-flex ${
              day.addedToShoppingList ? 'is-selected' : ''
            }`}
            key={`date-${dayIndex}`}
            onClick={() => addDayToShoppingList(dayIndex)}
          >
            {day.weekDay.slice(0, 2)}
            <small>{day.dateOfMonth}</small>
          </div>
        ))}
      </StyledMealPlanCalender>

      {shoppingListByCategory.length > 0 ? (
        <div className="row">
          {shoppingListByCategory.map((category, categoryIndex) => (
            <div className="col-12 col-md-6 mb-4" key={`category-${categoryIndex}`}>
              <SectionHeader>{t(`shopping-page.${category.slug}`)}</SectionHeader>
              <div className="shopping-list-items">
                {category.items.map((item, itemIndex) => (
                  <div
                    className={`shopping-list-item ${
                      item.markedOnShoppingList ? 'is-checked' : ''
                    }`}
                    key={`item-${categoryIndex}-${itemIndex}`}
                    onClick={() => {
                      handleToggleIngredient(item.id, item.type);
                    }}
                  >
                    <div
                      className="shopping-list-item-image"
                      style={{ backgroundImage: `url(${item.image})` }}
                    ></div>
                    <div className="shopping-list-item-amount me-1">
                      {`${displayIngredientAmount(item.amount, item.serving_unit)} ${t(
                        'unit.' + item.serving_unit,
                      )}`}
                    </div>
                    <div className="shopping-list-item-title">
                      <b>{item.title}</b>
                      {item.additional_text != '' && item.additional_text != null && (
                        <>{`, ${item.additional_text}`}</>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="col-12">
            <StyledCTAGrey
              text={t('shopping-page.cta-delete')}
              icon={<DeleteIcon></DeleteIcon>}
              onClick={() => {
                deleteShoppingList();
              }}
            ></StyledCTAGrey>
          </div>
        </div>
      ) : (
        <EmptyState
          title={t('shopping-page.list-empty')}
          text={t('shopping-page.list-empty-text')}
          icon={<CartIcon></CartIcon>}
        ></EmptyState>
      )}
    </StyledShoppingListPage>
  );
}
