import CTABlueSmall from 'components/buttons/CTABlueSmall';
import CTAGreySmall from 'components/buttons/CTAGreySmall';
import CloseIcon from 'components/SVG/CloseIcon';
import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';

const StyledAlertBackgrounddrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const StyledAlert = styled.div`
  background-color: #fff;
  display: block;
  pointer-events: initial;
  height: auto;
  min-height: auto;
  z-index: 99999;
  border-radius: 5px;
  width: 95%;
  overflow: hidden;
  max-width: 1000px;
  width: 94%;
  margin-right: 1%;
  box-sizing: border-box;
  color: #222;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 1140px) {
  }

  .alert-header {
    border-bottom: 1px solid whitesmoke;
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      margin-bottom: 0;
      font-weight: 700;
    }

    .alert-close {
      background-color: whitesmoke;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.1s ease-in;
      width: 30px;
      height: 30px;
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #222;

        & > svg {
          fill: #fff;
        }
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .alert-close-absolute {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: whitesmoke;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.1s ease-in;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #222;

      & > svg {
        fill: #fff;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .alert-content {
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .alert-footer {
    border-top: 1px solid whitesmoke;
    padding: 20px;
  }
`;

export default function Alert({ children, title, isOpen, onClose, onConfirm, onCancel }) {
  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <>
      {isOpen && (
        <>
          <StyledAlertBackgrounddrop onClick={() => onClose()}></StyledAlertBackgrounddrop>

          <StyledAlert>
            {title && (
              <div className="alert-header">
                <h4>{title}</h4>
                <div className="alert-close" onClick={() => onClose()}>
                  <CloseIcon></CloseIcon>
                </div>
              </div>
            )}

            {!title && (
              <div className="alert-close-absolute" onClick={() => onClose()}>
                <CloseIcon></CloseIcon>
              </div>
            )}

            <div className="alert-content">{children}</div>

            {onConfirm || onCancel ? (
              <div className="alert-footer row">
                <div className={`${onConfirm ? 'col-6' : 'col-12'}`}>
                  {onCancel && (
                    <CTAGreySmall text={'Abbrechen'} onClick={() => onClose()}></CTAGreySmall>
                  )}
                </div>
                <div className="col-6">
                  {onConfirm && (
                    <div className="mb-3">
                      <CTABlueSmall text={'Speichern'} onClick={() => onConfirm()}></CTABlueSmall>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </StyledAlert>
        </>
      )}
    </>
  );
}
