import { ASSETS_URL } from 'constants/assets';
import React from 'react';
import styled from 'styled-components';
import Headline from './Typo/Headline';
import Topline from './Typo/Topline';

const StyledLoadingState = styled.div`
  width: 100%;
  text-align: center;
  margin: 10% auto;

  .loading-state-icon {
    margin-bottom: 20px;

    img {
      border-radius: 5px;
      width: 200px;
      height: 200px;
      background-color: #fff;
      padding: 20px;
    }
  }
`;

export default function LoadingState({ title, text, image }) {
  return (
    <StyledLoadingState>
      <div className="loading-state-icon">
        {image && <img src={`${ASSETS_URL}${image}`} />}
        {!image && <img src={`${ASSETS_URL}loading.gif`} />}
      </div>
      <Headline>{title}</Headline>
      <Topline>{text}</Topline>
    </StyledLoadingState>
  );
}
