import styled from 'styled-components';

export const StyledTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;

  .tab {
    display: block;
    width: 100%;
    text-align: center;
    color: #000;
    font-size: 13px;
    cursor: pointer;
    padding: 10px 0;
    transition: all 0.1s ease-in;

    &:hover {
      color: #fff;
      background-color: #03a9f4;
    }

    &.is-active {
      color: #fff;
      background-color: #03a9f4;
    }
  }

  @media (min-width: 768px) {
    .tab {
      font-size: 16px;
    }
  }
`;
