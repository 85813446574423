import React from 'react';
import styled from 'styled-components';
import InlineButton from './buttons/InlineButton';
import SmallButton from './buttons/SmallButton';
import FakeLink from './FakeLink';
import ArrowRightShort from './SVG/ArrowRightShort';
import BatteryIcon from './SVG/BatteryIcon';
import DeleteIcon from './SVG/DeleteIcon';
import TimerIcon from './SVG/TimerIcon';

const StyledRecipeCard = styled.div`
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s all;
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: whitesmoke;
  transition: transform 250ms;
  transition: all 0.1s ease-in;
  //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  background-color: #fff;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  transition: 0.2s all ease;

  &:hover {
    &:before {
      opacity: 1;
    }

    .recipe-image:after {
      animation: shine 0.75s;
    }
  }

  .recipe-image {
    height: 260px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    pointer-events: none;
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -75%;
      z-index: 2;
      display: block;
      width: 50%;
      height: 100%;
      transform: skewX(-25deg);
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 100%
      );
    }
  }

  @-webkit-keyframes shine {
    100% {
      left: 125%;
    }
  }
  @keyframes shine {
    100% {
      left: 125%;
    }
  }

  .recipe-line {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzA5IiBoZWlnaHQ9IjcyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiPjxwYXRoIGQ9Ik0tMTEgMjVsNzQgMzAuNSA2OSA1IDY4LjUtMTZMMzQ2IDI1djc5SC0xMVYyNXoiIGZpbGw9IiNmZmYiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTS00Mi43LTIzMS4ybC0xLjMgMWMtMzIuNCAyMy45LTQ3LjUgNTcuNS00Ni4xIDEwMC42IDEuNCA0NCAxNy4xIDgzIDQyIDExOC44YTE5OCAxOTggMCAwMDE3IDIxLjNjMTUuMyAxOCAzMi43IDMzLjEgNTMuNSA0MyAyNS4xIDEyLjEgNTEuMyAxNi43IDc4IDE0LjZsMi4zLS4xYzMuNS0uMyA2LjktLjUgMTAuMy0xIDQxLTYuMyA4Mi40LTEwIDEyNC0xMiAzMS43LTEuNiA2Mi05LjIgODguNS0yN2E1MC4zIDUwLjMgMCAwMS02LTUuNiA2MyA2MyAwIDAwLTYzLjgtMTkuNiA5My42IDkzLjYgMCAwMC0yNy42IDEyLjhsLTIuMiAxLjNjLTE5LjQgMTAuOS0zOC44IDIxLjktNTkuNSAyOS40LTU0LjggMTkuOS0xMDUgMTUtMTQ3LjQtMjAuMS01MS43LTQzLTc1LjItMTAwLjctNjcuNS0xNzIuNi44LTYuOCAxLjktMjQgMy4zLTQ3bDIuNS0zNy44eiIgZmlsbD0iI0M1RTNEOSIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImNsaXAwIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDMwOXY3MkgweiIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPgo=);
    background-size: cover;
    bottom: 0;
    position: absolute;
    height: 80px;
    width: 100%;
  }

  .recipe-stats {
    border-bottom: 1px solid #eef0ef;
    font-size: 16px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: 600;

    .recipe-time,
    .recipe-calories {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    svg {
      margin-right: 4px;
      width: 16px;
      height: 16px;
    }
  }

  .recipe-card-content {
    padding: 10px 15px 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .recipe-title {
      margin: 0;
      color: #000;
      font-weight: 700;
      font-size: 20px;
      position: relative;
    }

    .recipe-desc {
      font-size: 12px;
      color: rgba(87, 87, 87);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 5px 0;

      p {
        display: inline;
      }
    }

    .recipe-link {
      margin-top: 10px;
    }

    .recipe-categorys {
      overflow: hidden;
      margin-top: 10px;

      &.after {
      }

      .recipe-category {
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        display: inline-block;
        padding: 8px 16px;
        text-align: center;
        background-color: #fff;
        margin-right: 6px;
        margin-bottom: 6px;
        border-radius: 8px;

        &:hover {
          background-image: linear-gradient(117deg, #03a9f4, #0288d1);
          color: #fff;
        }
      }
    }

    .recipe-info {
      margin-top: 4px;
      color: #969696;
      font-size: 14px;
      font-weight: 600;

      span {
        margin-right: 4px;
      }
    }
  }

  .action-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s all;

    svg {
      fill: #fff;
      width: 18px;
      height: 18px;
      transition: 0.2s all;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.75);

      svg {
        fill: #222;
      }
    }
  }
`;

export default function RecipeCard({ recipe, onClick, onDelete, ...props }) {
  return (
    <>
      <StyledRecipeCard onClick={() => onClick(recipe)}>
        <div className="recipe-image" style={{ backgroundImage: `url(${recipe.image})` }}>
          <div className="recipe-line"></div>
        </div>

        <div className="recipe-card-content">
          <div className="recipe-stats d-flex justify-content-between align-items-center">
            <div className="recipe-time">
              <TimerIcon></TimerIcon>
              {`${recipe.time} `} Min
            </div>
            <div className="recipe-calories">
              <BatteryIcon></BatteryIcon>
              {`${recipe.calories} `} kcal
            </div>
          </div>
          <h4
            className="recipe-title"
            dangerouslySetInnerHTML={{ __html: recipe.title }}
            {...props}
          ></h4>
          <div className="recipe-info">
            {`${recipe.protein}g`} Eiweiß&nbsp;-&nbsp;
            {`${recipe.carbs}g`} Kohlenhydrate&nbsp;-&nbsp;
            {`${recipe.fat}g`} Fett
          </div>

          {/*<div className="recipe-categorys">
          {recipe.category.map((category, index) => (
            <div className="recipe-category" key={`category-${index}`}>
              {category}
            </div>
          ))}
        </div>*/}

          {/*<div className="recipe-desc" dangerouslySetInnerHTML={{ __html: recipe.description }}></div>*/}
        </div>
      </StyledRecipeCard>
      {onDelete && (
        <div className="mt-4 text-center">
          <SmallButton
            text={'Entfernen'}
            onClick={() => {
              onDelete();
            }}
          ></SmallButton>
        </div>
      )}
    </>
  );
}
