import React from 'react';

export default function EmptyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" width="60" height="60" fill="#000">
      <g id="Unbox-2" data-name="Unbox">
        <path d="M86.69,32.6084,78.0391,27.74,86.69,22.8716a1,1,0,0,0,0-1.7432l-32-18a1.0015,1.0015,0,0,0-.98,0L44,8.5928,34.29,3.1284a1.0015,1.0015,0,0,0-.98,0l-32,18a1,1,0,0,0,0,1.7432L9.9609,27.74,1.31,32.6084a1,1,0,0,0,0,1.7432L11,39.8024V66a1.0011,1.0011,0,0,0,.51.8716l32,18A1.2026,1.2026,0,0,0,44,85a1.232,1.232,0,0,0,.49-.1284l32-18A1.0011,1.0011,0,0,0,77,66V39.8024l9.69-5.4508a1,1,0,0,0,0-1.7432ZM43,44.03,14.04,27.74,43,11.45Zm2-32.58L73.96,27.74,45,44.03Zm9.2-6.3027L84.1611,22,76,26.5928,46.0394,9.74Zm-20.4,0L41.9606,9.74,19.49,22.38,12,26.5928,3.8389,22ZM12,28.8877c10.578,5.95,5.975,3.3608,29.9606,16.8527L33.8,50.3325,3.8389,33.48Zm1,12.0415L33.31,52.3516a1,1,0,0,0,.98,0L43,47.45V82.29L13,65.415Zm62,0V65.415L45,82.29V47.45l8.71,4.9012a1,1,0,0,0,.98,0ZM54.2,50.3325,46.0394,45.74C72.7863,30.6953,65.1109,35.0126,76,28.8877L84.1611,33.48Z" />
      </g>
    </svg>
  );
}
