export function getRandomColor() {
  const colorList = [
    '#E49994',
    '#BE8365',
    '#E4B9A0',
    '#DAB372',
    '#56888F',
    '#6A7372',
    '#7A567C',
    '#E39A94',
    '#0db4b9',
    '#68c3a3',
    '#6c7a89',
    '#95a5a6',
  ];
  return colorList[Math.floor(Math.random() * colorList.length)];
}

export function createGradient() {
  var r = (Math.round(Math.random() * 127) + 127).toString(16);
  var g = (Math.round(Math.random() * 127) + 127).toString(16);
  var b = (Math.round(Math.random() * 127) + 127).toString(16);
  return '#' + r + g + b;
}

export function getColorByIndex(index) {
  const colorList = [
    '#6a7372',
    '#BE8365',
    '#E4B9A0',
    '#DAB372',
    '#56888F',
    '#7A567C',
    '#0db4b9',
    '#68c3a3',
    '#6c7a89',
    '#95a5a6',
    '#E49994',
    '#6a7372',
    '#BE8365',
    '#E4B9A0',
    '#DAB372',
    '#56888F',
    '#7A567C',
    '#0db4b9',
    '#68c3a3',
    '#6c7a89',
    '#95a5a6',
    '#E49994',
    '#6a7372',
    '#BE8365',
    '#E4B9A0',
    '#DAB372',
    '#56888F',
    '#7A567C',
    '#0db4b9',
    '#68c3a3',
    '#6c7a89',
    '#95a5a6',
    '#E49994',
    '#6a7372',
    '#BE8365',
    '#E4B9A0',
    '#DAB372',
    '#56888F',
    '#7A567C',
    '#0db4b9',
    '#68c3a3',
    '#6c7a89',
    '#95a5a6',
    '#E49994',
  ];
  return colorList[index];
}
