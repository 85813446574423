import React, { useState } from 'react';
import styled from 'styled-components';
import CTATransparent from 'components/buttons/CTATransparent';
import DeleteIcon from 'components/SVG/DeleteIcon';
import { useAPI } from 'context/APIcontext';
import SmallButton from 'components/buttons/SmallButton';
import Alert from 'components/Alert/Alert';
import SaveMealPlanTemplate from './SaveMealPlanTemplate';
import PenIcon from 'components/SVG/PenIcon';

const StyledMealPlanTemplate = styled.div`
  margin-top: 20px;
  //box-shadow: 0 2px 2px 0 rgb(0 0 0 / 12%);
  position: relative;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  //background-image: linear-gradient(122deg, #fafafa, #eee);
  background-color: whitesmoke;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  transition: 0.2s all ease;

  /*&:hover {
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    background-color: #fff;
    cursor: pointer;
  }*/

  .plan-content {
    padding: 20px;

    .plan-title {
      h4 {
        color: #222;
        font-weight: 700;
        font-size: 26px;
      }
    }
  }

  .plan-info {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }

  .plan-images {
    overflow: hidden;
    white-space: nowrap;
    margin-top: 10px;

    .plan-image {
      display: inline-block;
      width: 140px;
      height: 140px;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      margin-top: 10px;
      margin-left: -60px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
`;

export default function MealPlanTemplate({ mealplan, mealplanIndex, isRenameActive, onSelect }) {
  const { handleDeleteTemplate, handleRenameTemplate } = useAPI();

  const [showSaveMealplanSavePrompt, setShowSaveMealplanSavePrompt] = useState(false);
  const [saveMealPlanTitle, setSaveMealPlanTitle] = useState(mealplan.title);

  return (
    <>
      <StyledMealPlanTemplate>
        <div className="row">
          <div className="col-12 col-md-6 ">
            <div className="plan-content">
              <div className="plan-title">
                <h4>{mealplan.title}</h4>
              </div>
              <div className="plan-info">
                {mealplan.targetCalories} Kalorien - {mealplan.meals.length} Mahlzeit(en)
                {mealplan.recipes && mealplan.recipes.length > 0 && (
                  <> - {mealplan.recipes.length} Rezept(e)</>
                )}
              </div>

              <div className="d-flex mt-3">
                {isRenameActive && (
                  <div className="me-2 w-100">
                    <CTATransparent
                      text={'Umbenennen'}
                      icon={<PenIcon></PenIcon>}
                      onClick={() => setShowSaveMealplanSavePrompt(true)}
                    ></CTATransparent>
                  </div>
                )}
                {onSelect && (
                  <div className="me-2 w-100">
                    <CTATransparent
                      text={'Auswählen'}
                      icon={<PenIcon></PenIcon>}
                      onClick={() => onSelect(mealplan)}
                    ></CTATransparent>
                  </div>
                )}
                <div className="me-2 w-100">
                  <CTATransparent
                    text={'Löschen'}
                    onClick={() => handleDeleteTemplate(mealplanIndex)}
                    icon={<DeleteIcon></DeleteIcon>}
                  ></CTATransparent>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
            <div className="plan-images">
              {mealplan.meals.map((meal, mealIndex) => (
                <div
                  className="plan-image"
                  key={mealIndex}
                  style={{ backgroundImage: `url(${meal.detail.image})` }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </StyledMealPlanTemplate>

      {showSaveMealplanSavePrompt && (
        <Alert
          isOpen={true}
          onClose={() => setShowSaveMealplanSavePrompt(false)}
          onConfirm={() => {
            handleRenameTemplate(mealplanIndex, saveMealPlanTitle);
            setShowSaveMealplanSavePrompt(false);
          }}
          onCancel={() => setShowSaveMealplanSavePrompt(false)}
        >
          <SaveMealPlanTemplate
            onChange={(title) => {
              setSaveMealPlanTitle(title);
            }}
            currentTitle={mealplan.title}
          ></SaveMealPlanTemplate>
        </Alert>
      )}
    </>
  );
}
