import React, { useState } from 'react';
import { useAPI } from 'context/APIcontext';
import styled from 'styled-components';
import ReplaceIcon from 'components/SVG/ReplaceIcon';
import SmallButton from 'components/buttons/SmallButton';
import CTATransparent from 'components/buttons/CTATransparent';
import CheckIcon from 'components/SVG/CheckIcon';
import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import DeleteIcon from 'components/SVG/DeleteIcon';
import NewIcon from 'components/SVG/NewIcon';
import CopyIcon from 'components/SVG/CopyIcon';
import Alert from 'components/Alert/Alert';
import Ingredient from 'components/Ingredient/Ingredient';
import { useTranslation } from 'react-i18next';
import {
  MEAL_SLOT_BREAKFAST,
  MEAL_SLOT_DINNER,
  MEAL_SLOT_EMPTY,
  MEAL_SLOT_LUNCH,
  MEAL_SLOT_SNACK,
} from 'constants/mealSlotNames';
import { displayIngredientAmount } from 'helper/displayIngredientAmount';
import { sendTrackingPixel } from 'helper/tracking';

const StyledDemoMeal = styled.div`
  margin-bottom: 20px;
  transition: 0.2s all;
  border-radius: 8px;
  overflow: hidden;
  //background-image: linear-gradient(122deg, #fafafa, #eee);
  background-color: #fff;
  //border: 1px solid whitesmoke;

  position: relative;
  //border: 1px solid whitesmoke;
  //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  //box-shadow: 0 2px 2px 0 rgb(0 0 0 / 12%);
  //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  //background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQ1IiBoZWlnaHQ9IjIwMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjguMjQzIDMwNC43OTFjLTYuNzEtMi44Ny0xMS41My03LjgwMi0xNS4yOC0xMy45MTEtMy4zMjUtNS40MTUtNi4zNzYtMTAuOTYzLTguNTktMTYuOTQxLS4wOTYtLjI2LS4yODYtLjQ4NS0uNDMzLS43MjdsLTEuNjYtNS40MjljLS4zMS0xLjc3NC0uNjMzLTMuNTQ3LS45MjUtNS4zMjQtMS4zMzYtOC4xNDMtMS4wMDEtMTYuMjQ3LjYyOS0yNC4zMTcgMi44MjMtMTMuOTg3IDguMTU5LTI2Ljk3MiAxNS45MzctMzguOTM4IDQuMTA2LTYuMzE2IDguMDM1LTEyLjc5NyAxMi42OS0xOC42OTQgNy41MzktOS41NTIgMTUuNzM1LTE4LjU2MiAyNi4wOTItMjUuMjY0IDcuMzg4LTQuNzc5IDE1LjM5NC04LjEyOSAyMy45MDYtMTAuMzkzIDEwLjYyNy0yLjgyNSAyMS40Ny00LjE5OCAzMi4zNDktNS41MTMgNy44MzctLjk0NyAxNS42NzEtMi4wODQgMjMuNDE2LTMuNTk4IDEwLjE5Mi0xLjk5MyAyMC4wNTctNS4xMDYgMjguOTU3LTEwLjY3NCAxMy42MDEtOC41MDUgMjIuMDk3LTIwLjc5MiAyNi4zOTUtMzYuMDUzIDIuNjQ3LTkuNDE2IDQuNTY3LTMzLjM5OCAxMS43OTItNDQuODM2IDYuMjk3LTkuOTcgMTQuNTU5LTE4LjQ2IDIzLjg1MS0yNS44MjVhNTkuNjE3IDU5LjYxNyAwIDAxOS41NjktNi4xOSA2My42MjggNjMuNjI4IDAgMDExNy40MTktOC4zODNjMjguNTYtOC43MzcgNTguNTI3IDMuNTkzIDczLjExMiAyOC4wOTMuNzM3Ljk3MyAxLjQ2NCAxLjk1MyAyLjE4MSAyLjk0MiA1LjY5NCA3Ljg1NSAxMC4yMjcgMTYuMzc1IDE0LjMwNyAyNS4xNiA0LjU1MSA5Ljc5OSA4LjMyMSAxOS44ODcgMTAuOTc3IDMwLjMzOSA2LjY0MSAyNi4xMTggOC4zMzYgNTIuNDUzIDIuNTU5IDc5LTMuNDcyIDE1Ljk1NC0xMC4xNzUgMzAuNDM5LTIwLjI5OSA0My4zMTktLjEyMS4xNTMtLjE4My4zNS0uMjc0LjUyOEwzMi4yMDggMzA2LjM3OWMtMS4zMi0uNTI1LTIuNjU0LTEuMDI2LTMuOTY1LTEuNTg4eiIgZmlsbD0iI0M1RTNEOSIvPjwvc3ZnPg==);
  //background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjk2IiBoZWlnaHQ9IjI4MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjcyLjE4MiA2MTQuOTUxYy0zMS43NzggMjUuODA5LTY2Ljc5NiA0Ny4zNDMtMTA2LjIzNSA2MC41MTYtNTQuNjA0IDE4LjE4OC0xMDkuOTQgMjEuNzc5LTE2NS45MTUgNy44NDItMzkuNzI4LTEwLjAzLTc2LjIwNC0yNy4yMDItMTA5LjY4LTUwLjY3MS00NC4yMy0zMC42OC04MS4yNDctNjguMzExLTExMS4wMjEtMTEzLjIzNi0xMi40NTgtMTguNzgyLTIzLjgyMy0zOC4xNTUtMzIuNDQyLTU5LjE4LTExLjE3MS0yNy4xMzktMTguMjg2LTU1LjExOS0xOC41MDUtODQuNzE3LS4zMTUtMjguNTY4IDUuNjM5LTU1LjY5OSAyMC4wNDMtODAuNjc0IDE2LjI3OS0yOC40MzYgNDAuNDEtNDguMTk4IDcwLjQ2LTYwLjg0NiAzNC43My0xNC42NDEgNzEuMDc0LTIwLjQ4NyAxMDguNjktMTkuNDcxIDMzLjQ2Ny45ODMgNjUuNDYyIDguNTc3IDk2LjcwMyAyMC42IDE2Ljg2OCA2LjU1NyAzMi43MDUgMTQuOTIzIDQ4LjczIDIzLjEzNGwzNi4xOTQgMTguMzU2YzE0LjcxNSA3LjM5OSAzMC42MiA5LjM3MSA0Ni43NzYgMTAuNTAyIDIuOTMyLjA5NSA1Ljc0LS4zNDEgOC43MzUtLjkzMiAxMC4zODgtMS45OTEgMjAuMzEtNC41NDQgMzAuNjY3LTYuMTkyIDE1LjgxNy0yLjcwNSAzMS44MjEtNS41NjcgNDcuODg2LTcuMjEyIDI3LjA3Ny0yLjg4OCA1My4wMjQuODY4IDc2Ljk2NSAxNS4xNjMgMTQuOTMyIDguODAzIDI2LjYxNiAyMC45NDEgMzYuMjA5IDM1LjEzNiAyMi42NzMgMzMuNzI1IDMwLjE1MyA3MC45MDUgMjQuNTYxIDExMS4wNDItNC44MDYgMzMuNDYzLTE2LjgxNCA2NC4zNjUtMzMuNjg3IDkzLjYxMS0xOS45OTYgMzMuMTEyLTQ0Ljc5MSA2MS45ODEtNzUuMTM0IDg3LjIyOXoiIGZpbGw9IiNDNUUzRDkiLz48L3N2Zz4=);
  //background-repeat: no-repeat;
  //background-position: 100% 100%;
  //background-size: cover;
  //background-color: whitesmoke;
  //box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

  .demo-hint {
    background-color: aliceblue;
  }

  .done-checkmark {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background: rgba(0, 0, 0, 0.75);

    svg {
      width: 100px;
      height: 100px;
      fill: #fff;
      opacity: 0.5;
    }
  }

  &.is-done {
    .meal-cta,
    .meal-parts {
      display: none;
    }

    .done-checkmark {
      display: flex;
    }
  }

  .meal-top {
    background-color: #eee;
    padding: 20px;
    background-color: #37474f;

    .meal-slotname {
      //border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      margin-bottom: 20px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;

      select {
        background-color: transparent;
        border: 0 none;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border-radius: 0;
        box-shadow: none;
        border-bottom: 1px solid #fff;
        padding: 10px 20px 10px 0;
      }

      option {
        color: #000;
      }
    }

    .meal-title {
      width: 100%;
      position: relative;
      z-index: 1;
      color: #fff;
      max-width: 60%;

      h4 {
        color: #fff;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 6px;
      }

      .meal-facts {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.75);
      }
    }

    .meal-image {
      width: 220px;
      height: 220px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: -50px;
      right: -50px;
      pointer-events: none;
      transition: all 0.2s ease-in-out;
      border-radius: 50%;

      @media (min-width: 550px) {
        width: 300px;
        height: 300px;
        top: -100px;
        right: -60px;
      }
    }
  }

  .step-headline {
    display: inline-block;
    margin-bottom: 10px;
    color: #222;
    font-size: 16px;
    font-weight: 600;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;

    .step-number {
      display: inline-block;
      background-color: #03a9f4;
      color: #fff;
      width: 24px;
      height: 24px;
      margin-right: 6px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .meal-parts {
    padding: 0 20px 10px;

    .meal-parts-headline {
      margin-bottom: 10px;

      h4 {
        color: #222;
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: 700;
      }

      span {
        display: block;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.75);
      }
    }

    .meal-ingredient {
      font-size: 14px;
      margin-bottom: 4px;
      padding: 4px 0;
      border-radius: 8px;
      color: #222;

      .meal-ingredient-image {
        height: 40px;
        min-width: 40px;
        width: 40px;
        background-size: cover;
        background-position: 50% 50%;
        display: inline-block;
        margin-right: 10px;
        border-radius: 8px;
        background-color: #222;
        border: 1px solid #ddd;
      }

      .meal-ingredient-amount {
        color: rgba(0, 0, 0, 0.75);
        min-width: 100px;
      }

      .meal-ingredient-replace {
        cursor: pointer;

        svg {
          transition: 0.2s all;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`;

export default function DemoMeal({ dayIndex, mealIndex, meal, showFull }) {
  const {
    handleReplaceIngredient,
    handleReplaceMeal,
    markMealAsDone,
    removeMealFromDay,
    handleAddIngredient,
    handleRemoveIngredient,
    duplicateMealFromDay,
    setMealSlotTitle,
  } = useAPI();
  const { t } = useTranslation('common');

  let [replaceModal, setReplaceModal] = useState();

  return (
    <StyledDemoMeal className={`${meal.isDone ? 'is-done' : ''}`}>
      <div className="meal-top">
        {/*<div className="meal-slotname">
          <select
            value={meal.slotTitle}
            onChange={(event) => setMealSlotTitle(dayIndex, mealIndex, event)}
          >
            <option value={MEAL_SLOT_BREAKFAST}>{t(MEAL_SLOT_BREAKFAST)}</option>
            <option value={MEAL_SLOT_LUNCH}>{t(MEAL_SLOT_LUNCH)}</option>
            <option value={MEAL_SLOT_DINNER}>{t(MEAL_SLOT_DINNER)}</option>
            <option value={MEAL_SLOT_SNACK}>{t(MEAL_SLOT_SNACK)}</option>
            <option value={MEAL_SLOT_EMPTY}>{t(MEAL_SLOT_EMPTY)}</option>
          </select>
        </div>*/}
        <div className="meal-header d-flex justify-content-between align-items-center">
          <div className="meal-title">
            <h4>{meal.detail.title}</h4>
            <div className="meal-facts ">
              {`${meal.detail.calories * meal.detail.ratio} Kalorien - ${
                meal.detail.carbs * meal.detail.ratio
              }g Kohlenhydrate - ${meal.detail.protein * meal.detail.ratio}g Eiweiß - ${
                meal.detail.fat * meal.detail.ratio
              }g Fett`}
            </div>
          </div>
          <div
            className="meal-image text-left d-flex"
            style={{ backgroundImage: `url(${meal.detail.image})` }}
          ></div>
        </div>

        <div className="done-checkmark">
          <CheckCircleIcon />
        </div>

        <div className="row meal-cta mt-3">
          <div className="col-6 col-md-3">
            <CTATransparent
              text={'Tauschen'}
              onClick={() => {
                sendTrackingPixel('meal-demo-meal-replace');
                handleReplaceMeal(dayIndex, mealIndex);
              }}
              disabled={!meal.isReplaceable}
              icon={<ReplaceIcon></ReplaceIcon>}
            ></CTATransparent>
          </div>

          <div className="col-6 col-md-3">
            <CTATransparent
              text={'Kopieren'}
              disabled
              icon={<CopyIcon></CopyIcon>}
              onClick={() => duplicateMealFromDay(dayIndex, mealIndex)}
            ></CTATransparent>
          </div>
          <div className="col-6 col-md-3 mt-md-0 mt-3">
            <CTATransparent
              text={'Löschen'}
              disabled
              icon={<DeleteIcon></DeleteIcon>}
              onClick={() => removeMealFromDay(dayIndex, mealIndex)}
            ></CTATransparent>
          </div>
          <div className="col-6 col-md-3 mt-md-0 mt-3">
            <CTATransparent
              text={'Fertig'}
              disabled
              icon={<CheckIcon></CheckIcon>}
              onClick={() => markMealAsDone(dayIndex, mealIndex)}
            ></CTATransparent>
          </div>
        </div>
      </div>

      {showFull && (
        <div className="row meal-parts">
          {meal && (
            <>
              {meal.detail.steps.map((step, stepIndex) => {
                return (
                  <div className="col-12" key={`meal-${stepIndex}`}>
                    <div className="step-headline mt-3">
                      <span className="step-number">{stepIndex + 1}</span>
                      {`${step.step_title_de}`}
                    </div>
                    {step.selectedIngredient.map((selectedIngredient, ingredientIndex) => (
                      <div
                        className="meal-ingredient"
                        key={`selected-ingredient-${ingredientIndex}`}
                      >
                        <div className="d-md-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-md-between align-items-center">
                            <div
                              className="meal-ingredient-image"
                              style={{
                                backgroundImage: `url(${selectedIngredient.detail.thumbnail})`,
                              }}
                            ></div>
                            <div className="meal-ingredient-amount me-1">
                              {`${displayIngredientAmount(
                                selectedIngredient.amount,
                                selectedIngredient.detail.serving_unit,
                              )} ${t('unit.' + selectedIngredient.detail.serving_unit)}`}
                            </div>
                            <div className="meal-ingredient-title">
                              <b>{selectedIngredient.detail.title}</b>
                              {selectedIngredient.detail.additional_text != '' &&
                                selectedIngredient.detail.additional_text != null && (
                                  <>{`, ${selectedIngredient.detail.additional_text}`}</>
                                )}
                            </div>
                          </div>
                          <div className="text-right d-flex mt-3 mt-md-0">
                            {step.ingredients.length > 1 && (
                              <SmallButton
                                text={'Tauschen'}
                                icon={<ReplaceIcon></ReplaceIcon>}
                                onClick={() => {
                                  sendTrackingPixel('meal-demo-ingredient-replace');
                                  setReplaceModal({
                                    step: step,
                                    dayIndex: dayIndex,
                                    mealIndex: mealIndex,
                                    stepIndex: stepIndex,
                                    ingredientIndex: ingredientIndex,
                                  });
                                }}
                              ></SmallButton>
                            )}
                            {step.multiple &&
                              step.ingredients.length > 1 &&
                              step.selectedIngredient.length <= 2 && (
                                <div className="ms-3">
                                  <SmallButton
                                    text={'Weitere hinzufügen'}
                                    icon={<NewIcon></NewIcon>}
                                    onClick={() => {
                                      sendTrackingPixel('meal-demo-ingredient-add-more');
                                      handleAddIngredient(
                                        dayIndex,
                                        mealIndex,
                                        stepIndex,
                                        ingredientIndex,
                                      );
                                    }}
                                  ></SmallButton>
                                </div>
                              )}
                            {step.selectedIngredient.length > 1 && (
                              <div className="ms-3">
                                <SmallButton
                                  text={'Entfernen'}
                                  icon={<DeleteIcon></DeleteIcon>}
                                  onClick={() => {
                                    sendTrackingPixel('meal-demo-ingredient-remove');
                                    handleRemoveIngredient(
                                      dayIndex,
                                      mealIndex,
                                      stepIndex,
                                      ingredientIndex,
                                    );
                                  }}
                                ></SmallButton>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </>
          )}
        </div>
      )}

      {replaceModal && (
        <Alert
          title={'Zutat tauschen'}
          isOpen={true}
          onClose={() => setReplaceModal(null)}
          onCancel={() => setReplaceModal(null)}
        >
          {replaceModal.step.ingredients.map((ingredient, newIngredientIndex) => (
            <Ingredient
              key={`ingredient-${newIngredientIndex}`}
              ingredient={ingredient}
              onClick={() => {
                handleReplaceIngredient(
                  replaceModal.dayIndex,
                  replaceModal.mealIndex,
                  replaceModal.stepIndex,
                  replaceModal.ingredientIndex,
                  newIngredientIndex,
                );
                setReplaceModal(null);
              }}
            ></Ingredient>
          ))}
          <div className="demo-hint p-4">
            <b>Hinweis:</b> Die Demoversion enthält nicht alle Zutaten.
          </div>
        </Alert>
      )}
    </StyledDemoMeal>
  );
}
