import {
  NUTRITION_PESCETARIEN,
  NUTRITION_ANYTHING,
  NUTRITION_VEGETARIAN,
  NUTRITION_VEGAN,
} from 'constants/userMetrics';
import React from 'react';
import styled from 'styled-components';
import CheckIcon from 'components/SVG/CheckIcon';

const StyledSelectNutrition = styled.div``;

export default function SelectNutrition({
  nutrition,
  setNutrition,
  setCurrentStep,
  scrollToWidget,
  currentStep,
}) {
  return (
    <StyledSelectNutrition>
      <div className="onboarding-question">Deine Ernährungsweise</div>

      <div className="onboarding-question-text mt-2 mb-4">
        Keine Sorge, du kannst alle Angaben später jederzeit selbst anpassen.
      </div>

      <div className="onboarding-answer-wrapper">
        <div
          className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
            nutrition === NUTRITION_ANYTHING ? 'is-selected' : ''
          }`}
          onClick={() => {
            setNutrition(NUTRITION_ANYTHING);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="onboarding-icon-wrapper">
            <div className="onboarding-icon">🍗</div>
          </div>
          <div className="text-left w-100">
            <span className="d-block">Mischkost</span>
            <small>Ausgewogene Ernährung, mehrmals pro Woche Gemüse, Fisch und Fleisch</small>
          </div>
        </div>
        <div
          className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
            nutrition === NUTRITION_PESCETARIEN ? 'is-selected' : ''
          }`}
          onClick={() => {
            setNutrition(NUTRITION_PESCETARIEN);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="onboarding-icon-wrapper">
            <div className="onboarding-icon">🐟</div>
          </div>
          <div className="text-left w-100">
            <span className="d-block">Pescetarisch</span>
            <small>Pescetarische Ernährung mit viel Gemüse und manchmal Fisch</small>
          </div>
        </div>
        <div
          className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
            nutrition === NUTRITION_VEGETARIAN ? 'is-selected' : ''
          }`}
          onClick={() => {
            setNutrition(NUTRITION_VEGETARIAN);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="onboarding-icon-wrapper">
            <div className="onboarding-icon">🧀</div>
          </div>
          <div className="text-left w-100">
            <span className="d-block">Vegetarisch</span>
            <small>Kein Fisch und Fleisch</small>
          </div>
        </div>
        <div
          className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
            nutrition === NUTRITION_VEGAN ? 'is-selected' : ''
          }`}
          onClick={() => {
            setNutrition(NUTRITION_VEGAN);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="onboarding-icon-wrapper">
            <div className="onboarding-icon">🥑</div>
          </div>
          <div className="text-left w-100">
            <span className="d-block">Vegan</span>
            <small>Keine tierischen Produkte</small>
          </div>
          <div className="text-right col-2 d-flex justify-content-end align-items-end">
            <div
              className={`checkmark-icon ${
                nutrition === NUTRITION_VEGAN ? 'visible' : 'invisible'
              }`}
            >
              <CheckIcon />
            </div>
          </div>
        </div>
      </div>
    </StyledSelectNutrition>
  );
}
