import React from 'react';
import { useAPI } from 'context/APIcontext';
import styled from 'styled-components';
import SectionHeader from 'components/Typo/SectionHeader';
import { displayIngredientAmount } from 'helper/displayIngredientAmount';
import { useTranslation } from 'react-i18next';

const StyledMealPlanTable = styled.div`
  margin: 20px 0;

  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid whitesmoke;
    margin-bottom: 20px;

    thead {
      tr {
        padding: 20px;
        border-bottom: 4px solid #dddddd;
        font-size: 16px;
      }

      small {
        font-size: 12px;
      }
    }

    th,
    td {
      padding: 10px 15px;
      vertical-align: middle;
    }

    tbody tr {
      //border-bottom: 1px solid #dddddd;
    }

    tbody tr:nth-of-type(even) {
      background-color: whitesmoke;
    }

    tbody tr:last-of-type {
    }
  }
`;

export default function MealPlanTable() {
  const { userData } = useAPI();
  const { t } = useTranslation('common');

  console.log('userData', userData);

  return (
    <StyledMealPlanTable>
      {userData.mealPlan.map((day, dayIndex) => (
        <div key={`table-day-${dayIndex}`}>
          {day.meals.length > 0 && (
            <>
              <SectionHeader>
                {day.weekDay} - <small>{day.date} </small>
              </SectionHeader>

              {day.meals.map((meal, mealIndex) => (
                <table key={`table-day-meal-${mealIndex}`}>
                  <thead>
                    <tr>
                      <td>
                        <b>
                          Mahlzeit {t(mealIndex + 1)}:&nbsp;{meal.detail.title}&nbsp;-&nbsp;
                        </b>
                        <small>{`${
                          Math.round(meal.detail.calories) * meal.detail.ratio
                        } Kalorien - ${Math.round(
                          meal.detail.carbs * meal.detail.ratio,
                        )}g Kohlenhydrate - ${Math.round(
                          meal.detail.protein * meal.detail.ratio,
                        )}g Eiweiß - ${Math.round(
                          meal.detail.fat * meal.detail.ratio,
                        )}g Fett`}</small>
                      </td>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    {meal.detail.steps.map((step, stepIndex) => (
                      <React.Fragment key={`table-day-meal-step-${stepIndex}`}>
                        {step.selectedIngredient.map(
                          (selectedIngredient, selectedIngredientIndex) => (
                            <tr
                              key={`table-day-meal-selectedIngredientIndex-${selectedIngredientIndex}`}
                            >
                              <td>
                                <b>{selectedIngredient.detail.title}</b>
                                {selectedIngredient.detail.additional_text != '' &&
                                  selectedIngredient.detail.additional_text != null && (
                                    <>{`, ${selectedIngredient.detail.additional_text}`}</>
                                  )}
                              </td>
                              <td>
                                {`${displayIngredientAmount(
                                  selectedIngredient.amount,
                                  selectedIngredient.detail.serving_unit,
                                )} ${t('unit.' + selectedIngredient.detail.serving_unit)}`}
                                <br />
                                <small>
                                  {`${t('each')}                               ${
                                    selectedIngredient.totalAmountInGrams
                                  }
                              ${
                                selectedIngredient.detail.is_liquid
                                  ? t('unit.MILLI_LITER')
                                  : t('unit.g')
                              }`}
                                </small>
                              </td>
                            </tr>
                          ),
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ))}
            </>
          )}
        </div>
      ))}
    </StyledMealPlanTable>
  );
}
