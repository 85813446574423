import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

const StyledReactSlickSlider = styled.div`
  padding-bottom: 40px;
  overflow: hidden;
  padding-left: 5px;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    pointer-events: none;
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
    overflow: visible;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: auto;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-dots,
  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
  }

  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus:before,
  .slick-next:hover:before,
  .slick-prev:focus:before,
  .slick-prev:hover:before {
    opacity: 1;
  }
  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
  }
  .slick-prev {
    left: -25px;
  }
  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }
  .slick-prev:before {
    content: '←';
  }
  .slick-next:before,
  [dir='rtl'] .slick-prev:before {
    content: '→';
  }
  .slick-next {
    right: -25px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
  }
  [dir='rtl'] .slick-next:before {
    content: '←';
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    top: 100%;
    width: 100%;
    list-style: none;
    text-align: center;
    padding-top: 10px;
  }

  .slick-dots li {
    display: inline-block;

    button {
      width: 100%;
      position: relative;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
      width: 30px;
      height: 30px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
      background-color: whitesmoke;
      color: #222;

      &:hover {
        background-color: #03a9f4;
        color: #fff;
      }
    }

    &.slick-active button {
      background-color: #03a9f4;
      color: #fff;
    }
  }
`;

export default function StyledSlider({ children, ...props }) {
  return (
    <StyledReactSlickSlider>
      <Slider {...props}>{children}</Slider>
    </StyledReactSlickSlider>
  );
}
