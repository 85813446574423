import React from 'react';
import styled from 'styled-components';

const StyledRedButton = styled.div`
  display: inline-block;
  background: linear-gradient(130deg, #ffa634, #ff435b);
  border: none;
  font-weight: 700;

  box-shadow: 0 8px 40px 0 rgb(0 0 0 / 35%);
  color: #fff;
  font-size: 17px;
  border-radius: 5px;
  padding: 12px 40px;
  border: 0 none !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    color: #fff;
    text-decoration: none !important;
    border: 2px solid #17a0ff;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
  }
`;

export default function RedButton({ text, onClick, disabled, disabledClick }) {
  function handleOnClick() {
    if (disabled) {
      disabledClick();
    } else {
      onClick();
    }
  }

  return (
    <StyledRedButton
      className={`${disabled ? 'is-disabled' : ''}`}
      onClick={() => {
        handleOnClick();
      }}
    >
      {text}
    </StyledRedButton>
  );
}
