import React, { useState } from 'react';
import { useAPI } from 'context/APIcontext';
import styled from 'styled-components';
import Topline from 'components/Typo/Topline';
import Headline from 'components/Typo/Headline';
import StyledCTAGrey from 'components/buttons/CTAGrey';
import MarkIcon from 'components/SVG/MarkIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import CopyIcon from 'components/SVG/CopyIcon';
import ActionCard from 'components/ActionCard';
import Alert from 'components/Alert/Alert';
import MealPlanTemplate from './MealPlanTemplate';
import EmptyState from 'components/EmptyState';
import EmptyIcon from 'components/SVG/EmptyIcon';
import PrevMealPlanDayTemplate from './PrevMealPlanDayTemplate';

const StyledNoMeals = styled.div`
  .input-label-wrapper {
    position: relative;

    select {
      border: 0;
      font-family: inherit;
      padding: 12px 0;
      height: 48px;
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      color: #37474f;
      border-bottom: 2px solid #37474f;
      background: 0 0;
      border-radius: 0;
      line-height: 1.18;
      padding: 0 10px;
      display: inline-block;
      width: auto;
      appearance: auto;
    }

    .label {
      position: absolute;
      top: 16px;
      margin-left: 7px;
    }

    .input {
      -webkit-appearance: none;
      width: 94px;
      border: 0;
      font-family: inherit;
      padding: 12px 0;
      height: 48px;
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: #37474f;
      border-bottom: 2px solid #37474f;
      background: 0 0;
      border-radius: 0;
      line-height: 1.18;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
`;

export default function NoMealsCard({ currentDayIndex }) {
  const { userData, handleGenerateDay, handlePasteTemplate } = useAPI();

  const [showRestoreTemplatePrompt, setShowRestoreTemplatePrompt] = useState(false);
  const [showCopyFromPreviousMealPlanPrompt, setShowCopyFromPreviousMealPlanPrompt] =
    useState(false);

  const [calories, setCalories] = useState(
    userData.profile.overwriteCalories
      ? userData.profile.manualCalories
      : userData.profile.targetCalories,
  );
  const [mealsAmount, setMealsAmount] = useState(userData.profile.mealsAmount);

  function handleGenerateMealPlan() {
    handleGenerateDay(currentDayIndex, Number(calories), Number(mealsAmount));
  }

  return (
    <>
      <StyledNoMeals>
        <div className="text-center tour-create-mealplan-start">
          <Topline>Ernährungsplan erstellen</Topline>
          <Headline>Erstelle deinen Ernährungsplan für diesen Tag</Headline>
        </div>

        <div className="row">
          <div className="col-md-6">
            <ActionCard title={'Kalorien'}>
              <div className="input-label-wrapper tour-calories">
                <div className="mt16-32">
                  <input
                    type="tel"
                    className="input dual-number js-input-field invalid-highlight js-max-length focused"
                    placeholder={2000}
                    maxLength={4}
                    id="calculator-step-age"
                    name="calculator-step-age"
                    pattern="[0-9]*"
                    defaultValue={calories}
                    onChange={(e) => {
                      setCalories(e.target.value);
                    }}
                    inputMode="tel"
                  />
                  <label htmlFor="calculator-step-age" className="label invalid-highlight">
                    Kalorien
                  </label>
                </div>
              </div>
            </ActionCard>
          </div>
          <div className="col-md-6">
            <ActionCard title={'Anzahl der Mahlzeiten'}>
              <div className="input-label-wrapper order-1 order-md-0 tour-meals">
                <select
                  className="form-control"
                  id="num_meals_selector"
                  defaultValue={mealsAmount}
                  onChange={(e) => {
                    setMealsAmount(e.target.value);
                  }}
                >
                  <option value="1">1 Mahlzeit</option>
                  <option value="2">2 Mahlzeiten</option>
                  <option value="3">3 Mahlzeiten</option>
                  <option value="4">4 Mahlzeiten</option>
                  <option value="5">5 Mahlzeiten</option>
                  <option value="6">6 Mahlzeiten</option>
                  <option value="7">7 Mahlzeiten</option>
                  <option value="8">8 Mahlzeiten</option>
                  <option value="9">9 Mahlzeiten</option>
                </select>
              </div>
            </ActionCard>
          </div>
        </div>

        <div className="mt-3 tour-create-mealplan">
          <StyledCTAGrey
            text={'Ernährungsplan erstellen'}
            icon={<MarkIcon></MarkIcon>}
            onClick={() => {
              handleGenerateMealPlan();
            }}
          ></StyledCTAGrey>
        </div>

        <div className="row">
          <div className="col-md-6 mt-3">
            <StyledCTAGrey
              disabled={true}
              text={'Kopiere einen anderen Tag'}
              icon={<CopyIcon></CopyIcon>}
              onClick={() => setShowCopyFromPreviousMealPlanPrompt(true)}
            ></StyledCTAGrey>
          </div>
          <div className="col-md-6 mt-3">
            <StyledCTAGrey
              text={'Gespeicherte Ernährungspläne'}
              icon={<DownloadIcon></DownloadIcon>}
              onClick={() => {
                setShowRestoreTemplatePrompt(true);
              }}
            ></StyledCTAGrey>
          </div>
        </div>
      </StyledNoMeals>

      {showRestoreTemplatePrompt && (
        <Alert
          isOpen={true}
          onClose={() => setShowRestoreTemplatePrompt(false)}
          title={'Gespeicherte Ernährungspläne'}
        >
          <div className="ps-4 pe-4 pb-4">
            <>
              {!userData.templates || userData.templates.length == 0 ? (
                <EmptyState
                  title={'Keine Vorlagen'}
                  text={'Du hast noch keine Ernährungspläne als Vorlage gespeichert'}
                  icon={<EmptyIcon></EmptyIcon>}
                ></EmptyState>
              ) : (
                <>
                  {userData.templates.map((mealplan, mealplanIndex) => (
                    <MealPlanTemplate
                      mealplan={mealplan}
                      mealplanIndex={mealplanIndex}
                      key={`template-${mealplanIndex}`}
                      onSelect={(mealplan) => {
                        handlePasteTemplate(currentDayIndex, mealplan);
                      }}
                    ></MealPlanTemplate>
                  ))}
                </>
              )}
            </>
          </div>
        </Alert>
      )}

      {showCopyFromPreviousMealPlanPrompt && (
        <Alert
          title={'Kopiere den letzten Ernährungsplan'}
          isOpen={true}
          onClose={() => setShowCopyFromPreviousMealPlanPrompt(false)}
        >
          <div className="ps-4 pe-4 pb-4">
            {userData.previousMealPlanDays && userData.previousMealPlanDays.length > 0 ? (
              <>
                {userData.previousMealPlanDays.map((mealplanDay, mealplanDayIndex) => (
                  <PrevMealPlanDayTemplate
                    mealplanDay={mealplanDay}
                    key={`day-template-${mealplanDayIndex}`}
                    onSelect={(mealplanDay) => {
                      //handlePasteTemplate(currentDayIndex, mealplanDay);
                    }}
                  ></PrevMealPlanDayTemplate>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </Alert>
      )}
    </>
  );
}
