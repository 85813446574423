import React, { useState } from 'react';
import styled from 'styled-components';

const StyledActionCard = styled.div`
  min-height: 236px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 22px 0 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #fff;

  h4 {
    margin: 20px 0 !important;
    font-weight: 700;
  }
`;

export default function ActionCard({ children, title }) {
  return (
    <StyledActionCard>
      <h4>{title}</h4>
      {children}
    </StyledActionCard>
  );
}
