import React, { useState } from 'react';
import styled from 'styled-components';
import Topline from 'components/Typo/Topline';
import Headline from 'components/Typo/Headline';
import ActionCard from 'components/ActionCard';

const StyledEditMealPlanCaloriesPrompt = styled.div`
  padding: 40px;

  .input-label-wrapper {
    position: relative;

    .input {
      -webkit-appearance: none;
      width: 80%;
      border: 0;
      font-family: inherit;
      padding: 12px;
      height: 48px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #37474f;
      border-bottom: 2px solid #37474f;
      background: 0 0;
      border-radius: 0;
      line-height: 1.18;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .input-label-wrapper {
    text-align: center;
  }
`;

export default function EditMealPlanCaloriesPrompt({ onChange, currentTargetCalories }) {
  const [targetCalories, setTargetCalories] = useState(currentTargetCalories || '');

  return (
    <StyledEditMealPlanCaloriesPrompt>
      <div className="text-center">
        <Topline>Kalorienziel bearbeiten</Topline>
        <Headline>
          Gib einfach dein neues Kalorienziel ein und wir berechnen deinen Ernährungsplan neu.
        </Headline>
      </div>

      <div className="input-label-wrapper">
        <input
          type="tel"
          className="input dual-number js-input-field invalid-highlight js-max-length focused"
          placeholder={2000}
          maxLength={4}
          id="calculator-step-age"
          name="calculator-step-age"
          pattern="[0-9]*"
          inputMode="tel"
          defaultValue={targetCalories}
          onChange={(e) => {
            setTargetCalories(e.target.value);
            onChange(e.target.value);
          }}
        />
      </div>
    </StyledEditMealPlanCaloriesPrompt>
  );
}
