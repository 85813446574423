import React from 'react';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
`;

export default function Header({ children }) {
  return (
    <StyledHeader>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 pt-4 pb-2">{children}</div>
        </div>
      </div>
    </StyledHeader>
  );
}
