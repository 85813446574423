import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

const StyledBackgrounddrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: all 0.25s ease-in;
`;

const StyledOverlay = styled.div`
  background-color: #fff;
  display: block;
  top: 0;
  pointer-events: initial;
  position: absolute;
  height: auto;
  min-height: auto;
  z-index: 99999;
  border-radius: 5px;
  width: 90%;
  overflow: hidden;
  transition: all 0.25s ease-in;

  @media (min-width: 576px) {
    left: 5%;
    max-width: 1140px;
    width: 90%;
    margin-bottom: 60px;
  }

  @media (min-width: 1140px) {
    left: 50%;
    margin-left: -570px;
    max-width: 1140px;
    width: 100%;
  }

  .modal-header {
    position: relative;

    .modal-title {
      width: 80%;
      font-size: 16px;
      font-weight: 700;
    }

    .modal-close-button {
      position: absolute;
      right: 15px;
      top: 0;
      cursor: pointer;
      font-size: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .modal-body {
    padding: 0;
    overflow: hidden;
  }
`;

export default function Overlay({ children, title, isOpen, onClose }) {
  return (
    <>
      {isOpen && (
        <>
          <StyledBackgrounddrop onClick={() => onClose()}></StyledBackgrounddrop>

          <StyledOverlay className="modal-dialog modal-dialog-centered">
            <div className="modal-header">
              <h4 className="modal-title font-weight-bold">{title}</h4>
              <span
                className="modal-close-button"
                aria-hidden="true"
                onClick={() => {
                  onClose();
                }}
              >
                ×
              </span>
            </div>
            <div className="modal-body">{children}</div>
          </StyledOverlay>
        </>
      )}
    </>
  );
}
