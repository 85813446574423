import styled from 'styled-components';

export const StyledOnboarding = styled.div`
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;

  .onboarding-header {
    .text-left {
      transition: 0.4s all ease;
      cursor: pointer;
    }

    svg {
      margin-right: 2px;
      min-width: 26px;
      width: 36px;
      height: auto;
    }

    .step-counter {
      font-size: 16px;
      font-weight: 700;
      padding-top: 6px;

      .step-counter-current {
        color: rgb(3, 169, 244);
      }
    }
  }

  .onboarding-welcome {
    max-width: 300px;
    margin: 0 auto;

    video {
      max-width: 300px;
      margin: 0 auto;
    }
  }

  .onboarding-progress {
    background-color: rgb(232, 232, 232);
    border-radius: 5px;
    position: relative;
    height: 5px;
    overflow: hidden;
    width: 100%;

    .onboarding-progress-bar {
      background-color: rgb(3, 169, 244, 0.75);
      content: '';
      left: 0px;
      height: 100%;
      position: absolute;
      top: 0px;
      transition: 0.4s all ease;
    }
  }

  .onboarding-question {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin: 20px 0 0;
    text-align: center;
  }

  .onboarding-question-text {
    font-size: 17px;
    text-align: center;
  }

  .onboarding-values-input {
    position: relative;
    background-color: rgb(245, 245, 245);
    border-radius: 8px;
    padding: 28px 28px 48px;
    margin: 32px 0px 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      height: 20px;
      background: radial-gradient(
          at center center,
          rgb(255, 255, 255) 0%,
          rgb(255, 255, 255) 50%,
          transparent 50%,
          transparent 100%
        )
        center center / 20px 20px repeat-x;
    }

    label {
      color: rgb(41, 41, 41);
      display: flex;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 4px;
    }

    input {
      height: 40px;
      border: 1px solid #ced4da;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  .onboarding-answer-button {
    position: relative;
    display: flex;
    //box-shadow: 0 2px 4px rgb(0 0 0 / 11%);
    border: 2px solid #d4d5d5;
    border-radius: 8px;
    padding: 14px 0;
    margin-bottom: 20px;
    cursor: pointer;

    span {
      font-weight: 700;
      font-size: 18px;
    }

    small {
      color: rgb(0, 0, 0, 0.75);
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    svg {
      width: 46px;
    }

    img {
      width: 46px;
      min-width: 46px;
    }

    &:hover {
      border: 2px solid #292c2f;
      //margin: -1px -1px 19px;
    }

    &.is-selected {
      position: relative;
      display: flex;
      border-radius: 8px;
      padding: 14px 0;
      background: #f7f7f7;
      border: 2px solid #292c2f;
      //margin: -1px -1px 19px;
    }
  }

  .onboarding-answer-continue {
    margin-top: 40px;
  }

  .onboarding-answer-wrapper {
    select {
      border: 0;
      font-family: inherit;
      padding: 12px 0;
      height: 48px;
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      color: #37474f;
      border-bottom: 2px solid #37474f;
      background: 0 0;
      border-radius: 0;
      line-height: 1.18;
      padding: 0 10px;
      appearance: auto;
      width: 100%;
    }
  }

  .success-animation {
    margin: 150px auto;
  }

  .onboarding-icon {
    font-size: 36px;
    margin: 0 10px;

    svg {
      width: 46px;
      height: 46px;
    }
  }

  .activity-level-box {
    background-color: #fff;
    border-radius: 8px;
    margin: 0 0 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    transition: 0.2s all ease;
    padding: 0;

    h4 {
      margin: 20px 0 !important;
      font-weight: 700;
    }
  }

  .choice-btn {
    margin-top: 20px;
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 8px;
    color: #37474f;
    transition: 0.1s all ease;
    font-weight: 700;
    font-size: 16px;
    background-color: whitesmoke;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    cursor: pointer;
    width: 100%;

    &:hover {
      color: #fff;
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
    }
  }

  .checkmark-icon {
    width: 36px;
    height: 36px;
    background-color: #fff;
    border-radius: 50%;

    svg {
      width: 36px;
      height: 36px;
      fill: rgb(3, 169, 244, 0.75);
    }
  }

  .input-group-text {
    height: 100%;
  }

  .checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: rgba(3, 169, 244, 0.75);
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px rgba(3, 169, 244, 0.75);
    animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
    position: relative;
    top: 5px;
    right: 5px;
    margin: 0 auto;
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: rgba(3, 169, 244, 0.75);
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }

    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px rgba(3, 169, 244, 0.75);
    }
  }
`;
