import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAPI } from 'context/APIcontext';
import { URL_PROGRAMS_DE } from 'constants/URLS';
import SectionHeader from 'components/Typo/SectionHeader';
import Alert from 'components/Alert/Alert';
import ProgramPage from 'pages/ProgramPage/ProgramPage';
import { generateMealPlanDay, rescaleMealPlanDay } from 'helper/mealPlanHelper';
import { useTranslation } from 'react-i18next';
import { getColorByIndex } from 'helper/colorGenerator';
import LoadingState from 'components/LoadingStats';

const StyledProgramsPage = styled.div`
  margin: 20px 0;

  .text-area {
    font-size: 17px;
  }
`;

const StyledProgramCard = styled.div`
  margin-bottom: 20px;
  transition: 0.2s all;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  background-size: cover;
  background-position: 50% 50%;
  transition: 0.1s all ease-out;
  color: #fff;
  padding: 40px 20px 40px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
  border: 1px solid #eee;

  &:hover {
    //background-color: #222 !important;
    //text-decoration: underline;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    background-color: #03a9f4;
    color: #fff;

    .program-title {
    }
  }

  .program-topline {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    max-width: 100%;
  }

  .program-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
    max-width: 60%;
  }

  .program-description {
    font-size: 16px;
    max-width: 60%;
  }

  .program-image {
    position: absolute;
    width: 250px;
    height: 250px;
    top: -50px;
    right: -50px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: 0.1s all ease-out;
  }
`;

export default function ProgramsPage() {
  const { userData, setUserData, saveUserData, mixAndMatchChoices } = useAPI();
  const { t } = useTranslation('common');

  const [programsByCategory, setProgramsByCategory] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState();
  const [overWriteMealPlanConfirm, setOverWriteMealPlanConfirm] = useState();

  useEffect(() => {
    fetch(URL_PROGRAMS_DE)
      .then((response) => response.json())
      .then((data) => {
        let tmpProgramsByCategory = [];

        data.programs.forEach((program, programIndex) => {
          program.bgColor = getColorByIndex(programIndex);

          if (program.program_category) {
            const program_category = program.program_category;
            const foundIndex = tmpProgramsByCategory.findIndex(
              (category) => category.title === program_category,
            );

            if (foundIndex === -1) {
              tmpProgramsByCategory.push({
                title: program_category,
                programs: [program],
              });
            } else {
              tmpProgramsByCategory[foundIndex].programs.push(program);
            }
          }
        });

        setProgramsByCategory(tmpProgramsByCategory);
      });
  }, []);

  function handleStartProgram(program) {
    let state = { ...userData };
    let diet = state.profile.diet;
    let nutrition = state.profile.nutrition;

    // set meal plan diet_category
    if (program.diet_category) {
      state.profile.diet = program.diet_category.slug;
      diet = program.diet_category.slug;
    }

    // set meal plan nutrition_category
    if (program.nutrition_category) {
      state.profile.nutrition = program.nutrition_category.slug;
      nutrition = program.nutrition_category.slug;
    }

    let filteredMixAndMatchChoices = mixAndMatchChoices.filter((choice) => {
      return choice.possibleDiets.includes(diet) && choice.possibleNutrition.includes(nutrition);
    });

    filteredMixAndMatchChoices.forEach((choice) => {
      choice.steps.forEach((step) => {
        let filteredIngredients = step.ingredients.filter((ingredient) => {
          return (
            ingredient.detail.diet.includes(diet) && ingredient.detail.nutrition.includes(nutrition)
          );
        });

        step.ingredients = filteredIngredients;
      });
    });

    filteredMixAndMatchChoices = filteredMixAndMatchChoices.filter((choice) => {
      let isChoiceValid = true;
      choice.steps.forEach((step) => {
        step.ingredients.length === 0 && (isChoiceValid = false);
      });

      if (isChoiceValid) {
        return choice;
      }
    });

    state.mealPlan.forEach((day, dayIndex) => {
      if (dayIndex < program.duration_in_days) {
        day.meals = [];
        day.recipes = [];
      } else {
        return;
      }
    });

    for (let i = 0; i < program.duration_in_days; i++) {
      if (program.meal_slots && program.meal_slots.length > 0) {
        program.meal_slots.forEach((meal_slot) => {
          // erstelle zufälliges gericht
          let meal = generateMeal(filteredMixAndMatchChoices);

          // falls ein festes gerichte vorgeschrieben ist
          if (!meal_slot.isReplaceable) {
            meal = generateFixedMeal(
              filteredMixAndMatchChoices.find((meal) => meal.id === meal_slot.mix_and_match_id),
            );
          }

          state.mealPlan[i].meals.push({
            detail: meal,
            isReplaceable: meal_slot.isReplaceable,
            isDone: false,
            type: MEAL_TYPE_MIX_MATCH,
            slotTitle: meal_slot.slotTitle,
          });
        });
      } else {
        const mealPlanDay = generateMealPlanDay(
          mixAndMatchChoices,
          state.profile.targetCalories,
          state.profile.mealsAmount,
          state.profile.diet,
          state.profile.nutrition,
          state.profile.useHalfUnits,
        );
        state.mealPlan[i].targetCalories = state.profile.targetCalories;
        state.mealPlan[i].meals = mealPlanDay;

        state.mealPlan[i].meals = rescaleMealPlanDay(
          state.mealPlan[i].recipes,
          state.mealPlan[i].meals,
          state.mealPlan[i].targetCalories,
          state.profile.useHalfUnits,
        );
      }

      if (program.days[i]) {
        state.mealPlan[i].day_title = `${program.title}: ${t('day')} ${i + 1}`;
        state.mealPlan[i].day_text = program.days[i].day_text;
      }

      // end of meal slots
      state.mealPlan[i].meals = rescaleMealPlanDay(
        state.mealPlan[i].recipes,
        state.mealPlan[i].meals,
        state.mealPlan[i].targetCalories,
        state.profile.useHalfUnits,
      );
    }

    setUserData(state, saveUserData(state));
  }

  return (
    <StyledProgramsPage>
      {programsByCategory.length === 0 ? (
        <>
          <LoadingState title={'Einen Moment'} text={'Wir laden die Programme...'}></LoadingState>
        </>
      ) : (
        <>
          {programsByCategory.map((category, categoryIndex) => (
            <div key={`category-${categoryIndex}`}>
              <SectionHeader>{t(category.title)}</SectionHeader>
              <div className="row">
                {category.programs.map((program, programIndex) => (
                  <div className="col-12 col-md-6" key={`program-${programIndex}`}>
                    <StyledProgramCard
                      onClick={() => {
                        setSelectedProgram(program);
                      }}
                      style={{ backgroundColor: program.bgColor }}
                    >
                      <div className="program-topline">
                        {program.duration_in_days} {t('days')}
                      </div>
                      <div className="program-title">{program.title}</div>
                      {/*<div
                className="program-description"
                dangerouslySetInnerHTML={{ __html: program.description }}
              ></div>*/}
                      <div className="program-description">{program.topline}</div>
                      <div
                        className="program-image"
                        style={{ backgroundImage: `url(${program.image})` }}
                      ></div>
                    </StyledProgramCard>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}

      {selectedProgram && !overWriteMealPlanConfirm && (
        <Alert isOpen={true} title={selectedProgram.title} onClose={() => setSelectedProgram(null)}>
          <ProgramPage
            program={selectedProgram}
            onClose={() => setSelectedProgram(null)}
            startProgramm={(program) => {
              setOverWriteMealPlanConfirm(true);
            }}
          ></ProgramPage>
        </Alert>
      )}

      {overWriteMealPlanConfirm && (
        <Alert
          isOpen={true}
          title={'Ernährungsplan überschreiben?'}
          onClose={() => {
            setOverWriteMealPlanConfirm(null);
            setSelectedProgram(null);
          }}
          onCancel={() => {
            setOverWriteMealPlanConfirm(null);
            setSelectedProgram(null);
          }}
          onConfirm={() => {
            handleStartProgram(selectedProgram);
            setSelectedProgram(null);
            setOverWriteMealPlanConfirm(null);
          }}
        >
          <div className="text-area m-4">
            Bist du sicher, dass du deinen aktuellen Ernährungsplan überschreiben möchtest?
          </div>
        </Alert>
      )}
    </StyledProgramsPage>
  );
}
