import React, { useEffect, useState } from 'react';
import { useAPI } from 'context/APIcontext';
import { StyledRecipePage, StyledMealPlanCalender } from './StyledRecipePage';
import CTAGrey from 'components/buttons/CTAGrey';
import CTABlue from 'components/buttons/CTABlue';
import { displayIngredientAmount } from 'helper/displayIngredientAmount';
import { useTranslation } from 'react-i18next';
import SectionHeader from 'components/Typo/SectionHeader';
import Topline from 'components/Typo/Topline';
import Headline from 'components/Typo/Headline';
import Fuse from 'fuse.js';
import CTAGreen from 'components/buttons/CTAGreen';
import TimerIcon from 'components/SVG/TimerIcon';
import BatteryIcon from 'components/SVG/BatteryIcon';
import CTAGreySmall from 'components/buttons/CTAGreySmall';
import CTABlueSmall from 'components/buttons/CTABlueSmall';
import ServingsControl from 'components/ServingsControl';

export default function RecipePage({ recipe, onClose }) {
  const { userData, handleAddRecipeToDay, dbData } = useAPI();

  const { t } = useTranslation('common');

  const [showDates, setShowDates] = useState(false);
  const [recipeToDisplay, setRecipeToDisplay] = useState(Object.assign({}, recipe));
  const [recipeServings, setRecipeServings] = useState(recipe.servings);
  const [originalRecipe] = useState(JSON.parse(JSON.stringify(recipe)));

  const handleAddToMealPlan = (dayIndex) => {
    if (userData) {
      const newMealPlan = [...userData.mealPlan];

      if (!newMealPlan[dayIndex].recipes) {
        newMealPlan[dayIndex].recipes = [];
      }

      handleAddRecipeToDay(dayIndex, recipe);

      onClose();
    }
  };

  useEffect(() => {
    recipe.ingredients.forEach((ingredient) => {
      const fuse = new Fuse(dbData.food, {
        includeScore: false,
        shouldSort: true,
        includeMatches: false,
        minMatchCharLength: 2,
        //location: 0,
        threshold: 0.35,
        //distance: 100,
        useExtendedSearch: false,
        ignoreLocation: true,
        ignoreFieldNorm: false,
        keys: ['title'],
      });
      let food = fuse.search(ingredient.title);

      if (food.length > 0) {
        ingredient.ingredientDetail = food[0].item;
        ingredient.thumbnail = food[0].item.thumbnail;
        ingredient.image = food[0].item.image;
      } else {
        ingredient.ingredientDetail = {
          category: ['other'],
          title: ingredient.title,
        };
        ingredient.thumbnail = 'https://low-carb-kitchen.de/database/data/no-image2.png';
        ingredient.image = 'https://low-carb-kitchen.de/database/data/no-image2.png';
      }
    });

    let ingredients = recipe.ingredients;

    recipe.ingredientsUS = JSON.parse(JSON.stringify(ingredients));

    recipe.ingredientsMetric = JSON.parse(JSON.stringify(ingredients));

    // metric
    recipe.ingredientsMetric.forEach((ingredient) => {
      ingredient.checked = false;
      if (ingredient.unit === 'OZ') {
        ingredient.amount = Math.round(Number(ingredient.amount) * 25);
        ingredient.unit = 'GRAM';
        ingredient.amount = toNearest25(ingredient.amount);
      }

      if (ingredient.unit === 'LB') {
        ingredient.amount = Math.round(Number(ingredient.amount) * 450);
        ingredient.unit = 'GRAM';
        ingredient.amount = toNearest25(ingredient.amount);
      }

      if (ingredient.unit === 'CUP') {
        ingredient.amount = Math.round(Number(ingredient.amount) * 250);
        ingredient.unit = 'MILLI_LITER';
        ingredient.amount = toNearest25(ingredient.amount);
      }
    });

    // US
    recipe.ingredientsUS.forEach((ingredient) => {
      ingredient.checked = false;
      if (ingredient.unit === 'GRAM') {
        if (Number(ingredient.amount) <= 450) {
          if (ingredient.amount >= 12.5) {
            ingredient.amount = roundToNearest5(Number(ingredient.amount) / 25);
            ingredient.unit = 'OZ';
          } else {
            ingredient.amount = 0.25;
            ingredient.unit = 'OZ';
          }
        }

        if (Number(ingredient.amount) >= 450) {
          ingredient.amount = roundToNearest5(Number(ingredient.amount) / 450);
          ingredient.unit = 'LB';
        }
      }

      /*if (ingredient.unit === "MILLI_LITER") {
        ingredient.amount = roundToNearest5(
          Number(ingredient.amount) / 250
        );
        ingredient.unit = "CUP";
      }*/
    });

    setRecipeToDisplay(recipe);
  }, []);

  function decreaseServing() {
    if (recipeServings.servings > 1) {
      let newServings = recipeServings - 1;
      setRecipeServings(newServings);
      calculateNewIngradientAmount(newServings);
    }
  }

  function increaseServing() {
    let newServings = recipeServings + 1;
    setRecipeServings(newServings);
    calculateNewIngradientAmount(newServings);
  }

  function calculateNewIngradientAmount(newServings) {
    let newReipe = Object.assign({}, originalRecipe);

    newReipe.ingredientsUS.forEach((ingredient) => {
      ingredient.amount = ingredient.amount / originalRecipe.servings;
      ingredient.amount = ingredient.amount * newServings;
      ingredient.amount = toNearestQuarter(ingredient.amount);
      ingredient.amount = Math.round(Number(ingredient.amount) * 100) / 100;
    });

    newReipe.ingredientsMetric.forEach((ingredient) => {
      ingredient.amount = Number(ingredient.amount) / originalRecipe.servings;
      ingredient.amount = Number(ingredient.amount) * newServings;
      ingredient.amount = Math.round(ingredient.amount);
    });

    setRecipeToDisplay(newReipe);
  }

  function toNearestQuarter(num) {
    return Math.ceil(num / 0.25) * 0.25;
  }

  function toNearest25(num) {
    return Math.ceil(num / 25) * 25;
  }

  function roundToNearest5(number) {
    let f = Math.floor(number);
    if (number - f < 0.5) {
      return f;
    }
    return f + 0.5;
  }

  return (
    <>
      {recipeToDisplay && (
        <StyledRecipePage>
          <div className="row">
            <div
              className="recipe-image"
              style={{ backgroundImage: `url(${recipeToDisplay.image})` }}
            ></div>
          </div>

          <div className="recipe-content">
            <div className="recipe-title">
              <div dangerouslySetInnerHTML={{ __html: recipeToDisplay.title }}></div>
            </div>
            <div className="recipe-subtitle">
              <b>Pro Potion:</b> {recipeToDisplay.calories} Kalorien - {recipeToDisplay.protein}g
              Eiweiß - {recipeToDisplay.carbs}g Kohlenhydrate - {recipeToDisplay.fat}g Fett -{' '}
              {`${recipeToDisplay.time} `} Minuten
            </div>

            <div className="row mt-2 mb-4">
              <div className="col-6 col-md-6">
                <CTABlueSmall
                  text={t('recipe-page.cook-today')}
                  className="mt-0"
                  onClick={() => {
                    handleAddToMealPlan(0);
                  }}
                ></CTABlueSmall>
              </div>

              <div className="col-6 col-md-6">
                <CTAGreySmall
                  text={t('recipe-page.add-to-week')}
                  className="mt-0"
                  onClick={() => {
                    setShowDates(!showDates);
                  }}
                ></CTAGreySmall>
              </div>
            </div>

            {/*<div className="recipe-description">
          <SectionHeader>{t('recipe-page.meal-plan')}</SectionHeader>
        </div>
        
        <div className="recipe-description mt-4 mb-4 recipe-add-to-mealplan-text">
          {t('recipe-page.add-to-mealplan-text')}
        </div>
        */}

            {showDates && (
              <>
                <SectionHeader>{t('recipe-page.select-day')}</SectionHeader>
                <StyledMealPlanCalender className="mt-4 mb-4 d-flex justify-content-between align-items-center">
                  {userData.mealPlan.map((day, dayIndex) => (
                    <div
                      key={`date-${dayIndex}`}
                      className={`meal-plan-calender-item d-flex ${
                        0 === dayIndex ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        handleAddToMealPlan(dayIndex);
                      }}
                    >
                      {day.weekDay.slice(0, 2)}
                      <small>{day.dateOfMonth}</small>
                    </div>
                  ))}
                </StyledMealPlanCalender>
              </>
            )}

            {recipeToDisplay.description !== '' && (
              <>
                <div
                  className="recipe-description"
                  dangerouslySetInnerHTML={{ __html: recipeToDisplay.description }}
                ></div>
              </>
            )}
            {recipeToDisplay.tips !== '' && (
              <>
                <div className="recipe-description recipe-tip">
                  <p>
                    <b>Tipp:</b>
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: recipeToDisplay.tips }}></div>
                </div>
              </>
            )}

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="recipe-section-header mt-4 mb-4">
                  <div className="recipe-step__head--line"></div>
                  <div className="recipe-step__head__title">{t('recipe-page.ingredients')}</div>
                  <div className="recipe-step__head--line"></div>
                </div>

                <ServingsControl servings={recipeServings}></ServingsControl>

                <div className="ingredients-items">
                  {recipeToDisplay.ingredientsMetric &&
                    recipeToDisplay.ingredientsMetric.map((ingredient, ingredientIndex) => (
                      <div
                        className="ingredients-item"
                        key={`recipe-ingredient-${ingredientIndex}`}
                      >
                        <div
                          className="ingredients-item-image"
                          style={{ backgroundImage: `url(${ingredient.image})` }}
                        ></div>
                        <div className="ingredients-item-amount me-1">
                          {`${displayIngredientAmount(ingredient.amount, ingredient.unit)} ${t(
                            'unit.' + ingredient.unit,
                          )}`}
                        </div>
                        <div className="ingredients-item-title">
                          <b>{ingredient.title}</b>
                          {ingredient.note != '' && ingredient.note != null && (
                            <>{`, ${ingredient.note}`}</>
                          )}
                          {ingredient.optional && <>{`, optional`}</>}
                        </div>
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="recipe-section-header mt-4 mb-4">
                  <div className="recipe-step__head--line"></div>
                  <div className="recipe-step__head__title">{t('recipe-page.preparation')}</div>
                  <div className="recipe-step__head--line"></div>
                </div>

                <div className="step-items">
                  {recipeToDisplay.steps.map((step, stepIndex) => (
                    <div className="step-item mt-4" key={`recipe-step-${stepIndex}`}>
                      <SectionHeader>
                        {t('recipe-page.step-title')} {stepIndex + 1}
                      </SectionHeader>

                      {step.image && <img className="step-item-image mb-4" src={step.image}></img>}
                      <div
                        className="step-item-text"
                        dangerouslySetInnerHTML={{ __html: step.description }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="recipe-section-header mt-4 mb-4">
              <div className="recipe-step__head--line"></div>
              <div className="recipe-step__head__title">
                Guten Appetit {/*t('recipe-page.comments-title')*/}
              </div>
              <div className="recipe-step__head--line"></div>
            </div>
          </div>
        </StyledRecipePage>
      )}
    </>
  );
}
