import LoadingState from 'components/LoadingStats';
import React from 'react';

export default function DoneAnimation() {
  return (
    <>
      <LoadingState
        image={'waiting.gif'}
        title={'Einen Moment'}
        text={`Wir leiten dich zu deinem Ernährungsplan weiter..`}
      ></LoadingState>
    </>
  );
}
