import { URL_NUTRITASTIC_ASSETS, URL_NUTRITASTIC_DE } from 'constants/URLS';
import { USER_GENDER_FEMALE, USER_GENDER_MALE } from 'constants/userMetrics';
import React from 'react';
import styled from 'styled-components';
import MaleIcon from 'components/SVG/MaleIcon';
import FemaleIcon from 'components/SVG/FemaleIcon';

const StyledSelectGender = styled.div`
  .onboarding-icon svg {
    width: 30px !important;
    height: 30px !important;
    display: inline-block;
  }
`;

export default function selectGender({
  gender,
  setGender,
  setCurrentStep,
  scrollToWidget,
  currentStep,
}) {
  return (
    <StyledSelectGender>
      <div className="onboarding-question">Dein Geschlecht</div>

      <div className="onboarding-question-text mt-2 mb-4">
        Dein Geschlecht hat großen Einfluss auf deinen Kalorienverbrauch
      </div>

      <div className="onboarding-answer-wrapper">
        <div
          className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
            gender === USER_GENDER_MALE ? 'is-selected' : ''
          }`}
          onClick={() => {
            setGender(USER_GENDER_MALE);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="text-left">
            <span className="d-block">Mann</span>
          </div>
          <div className="text-right">
            <div className="onboarding-icon">
              <MaleIcon />
            </div>
          </div>
        </div>
        <div
          className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
            gender === USER_GENDER_FEMALE ? 'is-selected' : ''
          }`}
          onClick={() => {
            setGender(USER_GENDER_FEMALE);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="text-left">
            <span className="d-block">Frau</span>
          </div>
          <div className="text-right">
            <div className="onboarding-icon">
              <FemaleIcon />
            </div>
          </div>
        </div>
      </div>
    </StyledSelectGender>
  );
}
