import React, { useState } from 'react';
import styled from 'styled-components';
import CTABlue from 'components/buttons/CTABlue';
import { URL_NUTRITASTIC_ASSETS } from 'constants/URLS';

const StyledEnterEmail = styled.div`
  label {
    margin: 0 0 5px;
    font-size: 14px;
    padding-left: 10px;
  }

  input {
    width: 100%;
    box-shadow: 0 2px 7px -4px rgb(0 0 0 / 10%);
    border: 0px;
    font-family: inherit;
    text-align: left;
    color: #000;
    border: 1px solid whitesmoke;
    background-color: whitesmoke;
    appearance: auto;
    font-size: 16px;
    border-radius: 5px;
    line-height: 1;
    margin-right: 10px;
    padding: 10px;
    height: auto;
    margin: 0;
    text-align: left;
  }

  .checkbox-container {
    position: relative;

    .checkbox + .label,
    .checkbox + .label--secondary {
      position: relative;
      padding: 0 0 0 2rem;
      line-height: 20px;
      color: #555;
      cursor: pointer;
    }
  }

  .checkbox {
    display: none;
    visibility: hidden;
  }

  .checkbox + .label:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    width: 22px;
    height: 22px;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    background-color: #fff;
  }

  .checkbox--primary:checked + .label:before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTAwIDUwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNTAwIDUwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2ZmN2Y2NiIgc3Ryb2tlPSIjZmY3ZjY2Ij48cG9seWdvbiBwb2ludHM9IjIyMi41LDQ2OC4zIDEzLjYsMjc0LjMgNTQuNCwyMzAuMyAyMTMuNywzNzguMyA0NDEuNyw2NSA0OTAuMywxMDAuMyAJIi8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 50%;
  }

  .email-alert-bg {
    align-items: center;
    inset: 0px;
    display: flex;
    overflow: auto;
    position: fixed;
    background-color: rgba(41, 41, 41, 0.9);
    padding: 10px;
    z-index: 9;

    .email-alert {
      position: relative;
      max-width: 500px;
      border-radius: 12px;
      overflow: auto;
      background-color: #fff;
      z-index: 9999;

      .email-alert-headline {
        font-size: 22px;
        margin: 20px 0;
        text-align: center;
        font-weight: 700;
        padding: 0 20px;
      }

      .email-alert-text {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding: 0 20px 20px;
      }

      .email-alert-cancel {
        text-align: center;
        color: #8f8f8f;
        font-size: 16px;
        padding: 20px 20px 40px;
        cursor: pointer;
      }
    }
  }
`;

export default function EnterEmail({
  setCurrentStep,
  scrollToWidget,
  currentStep,
  userEmail,
  setUserEmail,
  userPassword,
  setUserPassword,
  allowMarketing,
  setAllowMarketing,
  handleSetUserData,
  showEmailAlert,
  setShowEmailAlert,
}) {
  return (
    <StyledEnterEmail>
      <div className="onboarding-question">Fast geschafft!</div>

      <div className="onboarding-question-text mt-2 mb-4">
        Damit wir dir deinen Ernährungsplan schicken können brauchen wir noch deine E-Mail Adresse
      </div>

      <div className="row mt-2 mb-4">
        <div className="col-12">
          {/*<label htmlFor="user-email">Deine E-Mail Adresse</label>*/}
          <input
            type="email"
            name="user-email"
            placeholder="E-Mail Adresse"
            onChange={(e) => setUserEmail(e.target.value)}
          />
        </div>
        {/*<div className="col-12 col-md-6">
          <label htmlFor="user-password">Dein Passwort</label>
          <input
            type="password"
            name="user-password"
            placeholder="Passwort"
            onChange={(e) => setUserPassword(e.target.value)}
          />
        </div>*/}
      </div>

      <div className="checkbox-container">
        <input
          className="checkbox checkbox--primary"
          id="newsletter"
          type="checkbox"
          name="newsletter"
          checked={allowMarketing}
          onChange={(e) => setAllowMarketing(e.target.value)}
        />
        <label className="label display--inline-block" htmlFor="newsletter">
          {/*Ja, bitte sendet mir Neuigkeiten, Tipps &amp; Angebote. Eine Abmeldung ist jederzeit
          möglich.
          <br />
          <br />*/}
          Wir respektieren deine Privatsphäre und verwenden deine E-Mail-Adresse nur, um dir das
          Programm und andere wichtige E-Mails zu senden.
        </label>
      </div>

      {showEmailAlert && (
        <>
          <div className="email-alert-bg">
            <div style={{ margin: 'auto' }}>
              <div className="email-alert">
                <img src={`${URL_NUTRITASTIC_ASSETS}onboarding/proceed_header.svg`} alt="" />
                <div className="email-alert-headline">
                  Dürfen wir dir dein persönliches Programm an deine E-Mail-Adresse schicken?
                </div>
                <div className="email-alert-text">
                  Sowie Neuigkeiten, Tipps & Angebote. Eine Abmeldung ist jederzeit möglich.
                </div>
                <div className="ms-4 me-4">
                  <CTABlue
                    text={'Ja, weiter zum Plan'}
                    onClick={() => {
                      setTimeout(() => {
                        setCurrentStep(currentStep + 1);
                        setAllowMarketing(true, handleSetUserData());
                      }, 500);
                    }}
                  ></CTABlue>
                </div>
                <div
                  className="email-alert-cancel"
                  onClick={() => {
                    setTimeout(() => {
                      setCurrentStep(currentStep + 1);
                      setAllowMarketing(false, handleSetUserData());
                    }, 500);
                  }}
                >
                  Nein, nur mein Plan senden
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mt-4">
        <CTABlue
          text={'Schick mir meinen Ernährungsplan'}
          disabled={!userEmail || !userEmail.includes('@') || !userEmail.includes('.')}
          onClick={() => {
            setShowEmailAlert(true);
          }}
        ></CTABlue>
      </div>
    </StyledEnterEmail>
  );
}
