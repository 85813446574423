import styled from 'styled-components';

export const StyledNutritionButton = styled.div`
  display: block;
  border-radius: 12px;
  margin: 0;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: relative;
  text-align: center;
  font-weight: 600;
  width: 100%;
  display: flex;
  height: 30px;
  flex-direction: column;
  flex: 33% 0 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;

  @media (min-width: 768px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: unset;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.is-big {
    height: 120px;
  }

  img {
    width: 50px;
    height: 50px;
    margin: 0 0 10px;
  }

  span {
    display: block;
  }

  &:hover {
    background-color: whitesmoke;
  }

  &.is-selected {
    color: #fff;
    background-color: #03a9f4;
  }
`;

export const StyledProfileCalculator = styled.div`
  .calculator-step {
    min-height: 236px;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 0 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    transition: 0.2s all ease;

    h4 {
      margin: 20px 0 !important;
      font-weight: 700;
    }
  }

  .line-dot {
    position: absolute;
    left: 50%;
    top: 0;

    .dot {
      font-size: 1rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      position: relative;
      top: 0;
      left: -50%;
      width: 32px;
      line-height: 31px;
      border-radius: 50%;
      text-align: center;
      background-color: #37474f;
      color: #fff;
      margin-top: -16px;
    }
  }

  .icn-btn-wrapper {
    max-width: 460px;
    margin-left: auto !important;
    margin-right: auto !important;

    .img-btn-container {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 0;
      border: none;
      padding: 0;
      background: 0 0;
      margin: 0 20px;

      .icon-svg {
        width: 72px;
        height: 72px;
      }

      &.is-selected .choice-btn {
        background-image: linear-gradient(117deg, #03a9f4, #0288d1);
        color: #fff;
      }
    }
  }

  .activity-level-box {
    cursor: pointer;

    .daily-activity-item-description {
      font-size: 16px;
    }

    &.is-selected .choice-btn {
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
      color: #fff;
    }
  }

  .input-label-wrapper {
    position: relative;

    select {
      border: 0;
      font-family: inherit;
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      color: #37474f;
      background: 0 0;
      line-height: 1;
      appearance: auto;
      font-size: 18px;
      background-color: whitesmoke;
      border-radius: 5px;
      line-height: 1;
      margin-right: 10px;
      padding: 10px;
      height: auto;
    }

    .label {
      position: absolute;
      top: 12px;
      margin-left: 7px;
    }

    .input {
      border: 0;
      font-family: inherit;
      font-size: 20px;
      font-weight: 700;
      text-align: left;
      color: #37474f;
      background: 0 0;
      line-height: 1;
      appearance: auto;
      font-size: 18px;
      background-color: whitesmoke;
      border-radius: 5px;
      line-height: 1;
      margin-right: 10px;
      padding: 10px;
      height: auto;
      width: 100px;
      text-align: center;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .choice-btn {
    margin-top: 20px;
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 8px;
    color: #37474f;
    transition: 0.1s all ease;
    font-weight: 700;
    font-size: 16px;
    background-color: whitesmoke;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

    &:hover {
      color: #fff;
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
    }
  }

  .infobox {
    margin-left: auto !important;
    margin-right: auto !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    cursor: pointer;
    padding: 20px;

    .infobox-description {
      margin-bottom: 0;
      padding: 0 15px 32px;
      font-size: 16px;
    }

    .icon-svg {
      width: 71px;
      height: 71px;
    }

    &:hover,
    &.is-selected {
      border: 1px solid #03a9f4;
    }
  }

  .font-highlight {
    font-size: 22px;
    color: #37474f;
    font-weight: 700;
  }

  .font-subtitle {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: #37474f;
    margin-top: 16px;
  }

  .calculator-daily-calories-submit {
    background: linear-gradient(130deg, #ffa634, #ff435b);
    border: none;
    box-shadow: 0 8px 40px 0 rgb(0 0 0 / 35%);
    color: #fff;
    font-size: 17px;
    border-radius: 30px;
    padding: 12px 40px;
    border: 0 none !important;

    &:focus {
      outline: none;
    }

    &.is-disabled {
      opacity: 0.4;
    }
  }
`;
