import React from 'react';
import styled from 'styled-components';

const StyledCTAGrey = styled.div`
  border: none;
  padding: 16px 0;
  text-decoration: none;
  border-radius: 8px;
  background-color: #fff;
  color: #37474f;
  transition: 0.1s all ease;
  font-weight: 700;
  font-size: 14px;
  background-image: linear-gradient(117deg, #fafafa, #eee);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  transition: 0.2s all ease;

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-image: linear-gradient(117deg, #03a9f4, #0288d1);
    color: #fff;
    opacity: 0.8;
    text-decoration: none !important;
  }

  .icon svg {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    padding: 16px 20px;
    font-size: 16px;
  }
`;

export default function CTAGrey({ text, onClick, disabled, icon, className }) {
  return (
    <StyledCTAGrey
      className={`${disabled ? 'is-disabled' : ''} ${className}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {icon && <span className="icon">{icon}</span>}
      <div className="text">{text}</div>
    </StyledCTAGrey>
  );
}
