import React from 'react';
import styled from 'styled-components';

const StyledFakeLink = styled.div`
  display: inline;
  vertical-align: middle;
  color: #03a9f4;
  font-weight: 600;
  position: relative;
  transition: 0.4s all ease;
  padding-bottom: 4px;
  cursor: pointer;
  padding: 2px 0px 4px 0px;
  border-radius: 5px;

  &:hover {
    color: #fff;
    background-color: #03a9f4;
    padding: 2px 0px 4px 6px;
  }

  svg {
    position: relative;
    top: -1px;
    margin-right: 8px;
  }
`;

export default function FakeLink({ children, onClick }) {
  return <StyledFakeLink onClick={onClick}>{children}</StyledFakeLink>;
}
