import React, { useEffect, useState } from 'react';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'context/APIcontext';
import {
  NUTRITION_ANYTHING,
  NUTRITION_PESCETARIEN,
  NUTRITION_VEGAN,
  NUTRITION_VEGETARIAN,
  USER_GENDER_FEMALE,
  USER_GENDER_MALE,
  USER_GOAL_BUILD_MUSCLE,
  USER_GOAL_LOSE_WEIGHT,
  USER_GOAL_MAINTAIN,
  DIET_ANYTHING,
  DIET_LOW_CARB,
  DIET_KETO,
  DIET_MEDITERRANEAN,
  DIET_PALEO,
  DIET_SLOW_CARB,
  DIET_HIGH_PROTEIN,
} from '../../constants/userMetrics';
import { ASSETS_URL } from 'constants/assets';
import styled from 'styled-components';
import { StyledMainContent } from 'pages/OverviewPage/OverviewPage';
import { StyledProfileCalculator, StyledNutritionButton } from './StyledSettingsPage';
import ToggleComponent from 'components/ToggleComponent';
import FakeLink from 'components/FakeLink';

export const StyledSettingsPage = styled.div``;

export default function SettingsPage({ ...props }) {
  const { userData, saveUserData, setUserData, deleteProfile } = useAPI();
  const { t } = useTranslation('common');

  const [initDone, setInitDone] = useState(false);
  const [gender, setGender] = useState(userData.profile.gender);
  const [height, setHeight] = useState(userData.profile.height);
  const [age, setAge] = useState(userData.profile.age);
  const [weight, setWeight] = useState(userData.profile.weight);
  const [activity, setActivity] = useState(userData.profile.activityLevel);
  const [goal, setGoal] = useState(userData.profile.goal);
  const [targetCalories, setTargetCalories] = useState(userData.profile.targetCalories);
  const [overwriteCalories, setOverwriteCalories] = useState(userData.profile.overwriteCalories);
  const [manualCalories, setManualCalories] = useState(userData.profile.manualCalories);
  const [selectedNutrition, setSelectedNutrition] = useState(userData.profile.nutrition);

  const [diets] = useState([
    DIET_ANYTHING,
    DIET_LOW_CARB,
    DIET_KETO,
    DIET_MEDITERRANEAN,
    //DIET_PALEO,
    DIET_SLOW_CARB,
    DIET_HIGH_PROTEIN,
  ]);
  const [selectedDiet, setSelectedDiet] = useState(userData.profile.diet);
  const [allergies, setAllergies] = useState([
    {
      slug: 'lactose',
      isSelected: false,
    },
    { slug: 'histamin', isSelected: false },
    { slug: 'fructose', isSelected: false },
    { slug: 'gluten', isSelected: false },
  ]);

  const [mealsAmount, setMealsAmount] = useState(userData.profile.mealsAmount);

  const [baseMetabolicRate, setBaseMetabolicRate] = useState();
  const [overallMetabolicRate, setOverallMetabolicRate] = useState();

  useEffect(() => {
    if (initDone) {
      if (height && age && weight && activity && goal) {
        let tmpBaseMetabolicRate = 0;
        let tmpOverallMetabolicRate = 0;
        let tmpTargetCalories = 0;

        if (gender === USER_GENDER_MALE) {
          tmpBaseMetabolicRate = Math.round(66.47 + 13.7 * weight + 5 * height - 6.8 * age);
        }
        if (gender === USER_GENDER_FEMALE) {
          tmpBaseMetabolicRate = Math.round(655.1 + 9.6 * weight + 1.8 * height - 4.7 * age);
        }

        tmpOverallMetabolicRate = Math.round(tmpBaseMetabolicRate * activity);

        if (goal === USER_GOAL_LOSE_WEIGHT) {
          tmpTargetCalories = Math.round(tmpOverallMetabolicRate - 400);
        }
        if (goal === USER_GOAL_BUILD_MUSCLE) {
          tmpTargetCalories = Math.round(tmpOverallMetabolicRate + 400);
        }
        if (goal === USER_GOAL_MAINTAIN) {
          tmpTargetCalories = tmpOverallMetabolicRate;
        }

        setBaseMetabolicRate(tmpBaseMetabolicRate);
        setOverallMetabolicRate(tmpOverallMetabolicRate);
        setTargetCalories(tmpTargetCalories);
      }

      if (
        gender &&
        height &&
        age &&
        weight &&
        activity &&
        goal &&
        mealsAmount &&
        overallMetabolicRate &&
        selectedNutrition &&
        selectedDiet
      ) {
        let newUserData = {
          ...userData,
          profile: {
            gender: gender,
            mealsAmount: Number(mealsAmount),
            goal: goal, // Maintain, Build Muscle
            preferedUnits: 'metric', //U.S. Standard
            height: Number(height),
            weight: Number(weight),
            age: age,
            bodyfat: 'low', // low, medium, high
            activityLevel: Number(activity), // light active, moderate active, very active, extra active
            activeWeightGoal: true, // true, false
            weightGoal: 80,
            weightChangeRate: 0.5,
            diet: selectedDiet, // anything, low-carb, keto, mediterranean, paleo, slow-carb, high-protein
            nutrition: selectedNutrition, // anything, pescetarien, vegan, vegetarian
            baseMetabolicRate: Number(baseMetabolicRate),
            overallMetabolicRate: Number(overallMetabolicRate),
            overwriteCalories: overwriteCalories,
            targetCalories: Number(targetCalories),
            manualCalories: Number(manualCalories),
            useHalfUnits: false,
            // A weight loss rate of 0.45 kg per week will give you a Calorie deficit of around 500 Calories per day. You may find faster weight loss harder to stick with, and therefore less effective long-term.
          },
        };

        newUserData.mealPlan.forEach((day) => {
          day.targetCalories = targetCalories;
        });

        setUserData(newUserData);
        const handler = setTimeout(() => {
          saveUserData(newUserData);
        }, 1000);

        return () => clearTimeout(handler);
      }
    }
    setInitDone(true);
  }, [
    gender,
    height,
    age,
    weight,
    activity,
    goal,
    mealsAmount,
    overallMetabolicRate,
    selectedNutrition,
    selectedDiet,
    manualCalories,
    overwriteCalories,
  ]);

  return (
    <StyledSettingsPage {...props}>
      <StyledMainContent>
        <StyledProfileCalculator className="calculators" id="calorie-calculator">
          <section>
            <div className="row">
              <div className="col-12">
                <div className="calculator-step" id="calculator-step-5">
                  <h4 className="mb-0 mb-md-32">Wie möchtest du dich ernähren?</h4>
                  <div className="ps-4 pe-4 mb-4 d-flex w-100 flex-wrap flex-wrap flex-md-nowrap">
                    {diets.map((diet, index) => (
                      <StyledNutritionButton
                        key={`diet-${index}`}
                        onClick={() => {
                          setSelectedDiet(diet);
                        }}
                        className={selectedDiet === diet ? 'is-selected is-big' : 'is-big'}
                      >
                        <img src={`${ASSETS_URL}${diet}.png`} alt="" />
                        <span>{t(diet)}</span>
                      </StyledNutritionButton>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="calculator-step" id="calculator-step-4">
                  <h4 className="mb-0 mb-md-32">Isst du Fleisch oder Fisch?</h4>
                  <div className="mt-16">
                    <div className="d-flex flex-column">
                      <div className="input-label-wrapper order-1 order-md-0">
                        <div className="">
                          <select
                            className="form-control"
                            id="num_meals_selector"
                            defaultValue={selectedNutrition}
                            onChange={(e) => {
                              setSelectedNutrition(e.target.value);
                            }}
                          >
                            <option value={NUTRITION_ANYTHING}>{t('NUTRITION_ANYTHING')}</option>
                            <option value={NUTRITION_PESCETARIEN}>
                              {t('NUTRITION_PESCETARIEN')}
                            </option>
                            <option value={NUTRITION_VEGETARIAN}>
                              {t('NUTRITION_VEGETARIAN')}
                            </option>
                            <option value={NUTRITION_VEGAN}>{t('NUTRITION_VEGAN')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="calculator-step" id="calculator-step-4">
                  <h4 className="mb-0 mb-md-32">Mahlzeiten pro Tag</h4>
                  <div className="mt-16">
                    <div className="d-flex flex-column">
                      <div className="input-label-wrapper order-1 order-md-0">
                        <select
                          className="form-control"
                          id="num_meals_selector"
                          defaultValue={mealsAmount}
                          onChange={(e) => {
                            setMealsAmount(e.target.value);
                          }}
                        >
                          <option value="1">1 Mahlzeit</option>
                          <option value="2">2 Mahlzeiten</option>
                          <option value="3">3 Mahlzeiten</option>
                          <option value="4">4 Mahlzeiten</option>
                          <option value="5">5 Mahlzeiten</option>
                          <option value="6">6 Mahlzeiten</option>
                          <option value="7">7 Mahlzeiten</option>
                          <option value="8">8 Mahlzeiten</option>
                          <option value="9">9 Mahlzeiten</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className="calculator-step p-2" id="calculator-step-5">
                  <h4 className="mb-0 mb-md-32">Was ist dein Ziel?</h4>
                  <div className="row w-100">
                    <div
                      className={`activity-level-box col-12 col-md-4 ${
                        goal === USER_GOAL_LOSE_WEIGHT ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setGoal(USER_GOAL_LOSE_WEIGHT);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-negative-dynamic.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle mb-4">Abnehmen</div>
                    </div>
                    <div
                      className={`activity-level-box col-12 col-md-4 ${
                        goal === USER_GOAL_MAINTAIN ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setGoal(USER_GOAL_MAINTAIN);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-neutral-dynamic.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle mb-4">Gewicht halten</div>
                    </div>
                    <div
                      className={`activity-level-box col-12 col-md-4 ${
                        goal === USER_GOAL_BUILD_MUSCLE ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setGoal(USER_GOAL_BUILD_MUSCLE);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-positive-dynamic.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle mb-4">Zunehmen</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="calculator-step" id="calculator-step-1">
                  <h4 className="mb-0 mb-md-32">Was ist dein Geschlecht?</h4>
                  <div className="mt-16">
                    <div className="icn-btn-wrapper" id="gender-icn-btn-wrapper">
                      <div
                        id="calculator-gender-male-input"
                        className={`img-btn-container no-hover js-input-field focused ${
                          gender === USER_GENDER_MALE ? 'is-selected' : ''
                        }`}
                        onClick={() => {
                          setGender(USER_GENDER_MALE);
                        }}
                      >
                        <img
                          className="icon-svg"
                          src={`${ASSETS_URL}icons.svg#icon-male`}
                          alt="Male"
                          type="image/svg+xml"
                        />
                        <div className={`choice-btn grey font-subtitle`}>Mann</div>
                      </div>
                      <div
                        id="calculator-gender-female-input"
                        className={`img-btn-container no-hover js-input-field focused ${
                          gender === USER_GENDER_FEMALE ? 'is-selected' : ''
                        }`}
                        onClick={() => {
                          setGender(USER_GENDER_FEMALE);
                        }}
                      >
                        <img
                          className="icon-svg"
                          src={`${ASSETS_URL}icons.svg#icon-female`}
                          alt="Female"
                          type="image/svg+xml"
                        />
                        <div className="choice-btn grey font-subtitle">Frau</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="calculator-step" id="calculator-step-2">
                  <h4 className="mb-0 mb-md-32">Wie alt bist du?</h4>
                  <div className="mt-16">
                    <div className="input-label-wrapper">
                      <div className="mt16-32">
                        <input
                          type="tel"
                          className="input dual-number js-input-field invalid-highlight js-max-length focused"
                          placeholder={21}
                          maxLength={3}
                          id="calculator-step-age"
                          name="calculator-step-age"
                          pattern="[0-9]*"
                          defaultValue={age}
                          onChange={(e) => {
                            setAge(e.target.value);
                          }}
                          inputMode="tel"
                        />
                        <label htmlFor="calculator-step-age" className="label invalid-highlight">
                          Jahre
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="calculator-step" id="calculator-step-3">
                  <h4 className="mb-0 mb-md-32">Wie groß bist du?</h4>
                  <div className="mt-16">
                    <div className="d-flex flex-column">
                      <div className="input-label-wrapper order-1 order-md-0 ">
                        <div id="calculator-step-height-cm-input-wrapper" className="">
                          <input
                            type="number"
                            id="calculator-step-height-cm"
                            name="height[cm]"
                            className="centered js-input-field input invalid-highlight js-max-length focused"
                            placeholder={170}
                            maxLength={3}
                            defaultValue={height}
                            onChange={(e) => {
                              setHeight(e.target.value);
                            }}
                            required
                            pattern="[0-9]*"
                          />
                          <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                            cm
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="calculator-step" id="calculator-step-4">
                  <h4 className="mb-0 mb-md-32">Was ist dein Gewicht?</h4>
                  <div className="mt-16">
                    <div className="d-flex flex-column">
                      <div className="input-label-wrapper order-1 order-md-0">
                        <div className="">
                          <input
                            type="number"
                            id="calculator-step-height-cm"
                            name="height[cm]"
                            className="centered js-input-field input invalid-highlight js-max-length focused"
                            placeholder={69}
                            maxLength={3}
                            defaultValue={weight}
                            onChange={(e) => {
                              setWeight(e.target.value);
                            }}
                            required
                            pattern="[0-9]*"
                          />
                          <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                            KG
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2">
                <div className="calculator-step" id="calculator-step-5">
                  <h4 className="mb-0 mb-md-32">Wie aktiv bist du im Alltag?</h4>
                  <div className="row p-4">
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.1 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.1);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-sitting-on-chair.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle mb-2">Geringfügig aktiv</div>
                      <div className="daily-activity-item-description centered">
                        Überwiegend sitzend (z. B. Büroangestellte:r)
                      </div>
                    </div>
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.3 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.3);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-stand-up.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle  mb-2">Mäßig aktiv</div>
                      <div className="daily-activity-item-description centered">
                        Meistens stehend (z. B. Lehrer:in, Kassierer:in)
                      </div>
                    </div>
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.5 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.5);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-walking.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle  mb-2">Aktiv</div>
                      <div className="daily-activity-item-description centered">
                        Meistens zu Fuß (z. B. Verkauf, Server)
                      </div>
                    </div>
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.7 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.7);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-manual-handling.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle mb-2">Sehr aktiv</div>
                      <div className="daily-activity-item-description centered">
                        Körperlich anstrengende Tätigkeit (z. B. Bauarbeiter:in)
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="calculator-step" id="calculator-step-4">
                  <h4 className="mb-0 mb-md-32">Dein Kalorienverbrauch pro Tag</h4>

                  <div className="mt-16">
                    <div className="d-flex flex-column">
                      <div className="input-label-wrapper order-1 order-md-0">
                        {overwriteCalories ? (
                          <div className="">
                            <input
                              type="number"
                              id="calculator-step-height-cm"
                              className="centered js-input-field input invalid-highlight js-max-length focused"
                              value={manualCalories}
                              onChange={(e) => {
                                setManualCalories(e.target.value);
                              }}
                              pattern="[0-9]*"
                              inputMode="number"
                            />
                            <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                              Kalorien
                            </label>
                          </div>
                        ) : (
                          <div className="">
                            <input
                              type="number"
                              id="calculator-step-height-cm"
                              className="centered js-input-field input invalid-highlight js-max-length focused"
                              value={targetCalories}
                              onChange={(e) => {
                                setTargetCalories(e.target.value);
                              }}
                              readOnly
                              pattern="[0-9]*"
                              inputMode="number"
                            />
                            <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                              Kalorien
                            </label>
                          </div>
                        )}
                      </div>

                      <div className="mt-4">
                        <ToggleComponent
                          isChecked={overwriteCalories}
                          onChange={() => setOverwriteCalories(!overwriteCalories)}
                          label="Kalorien manuell eingeben"
                        ></ToggleComponent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="d-flex justify-content-center">
            <FakeLink
              onClick={() => {
                deleteProfile();
              }}
            >
              Profil vollständig löschen
            </FakeLink>
          </div>
        </StyledProfileCalculator>
      </StyledMainContent>
    </StyledSettingsPage>
  );
}
