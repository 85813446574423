import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledIngredient = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: whitesmoke;
  }

  .styled-ingredient-image {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 1px solid #eee;
  }

  .styled-ingredient-title {
    margin-left: 10px;
  }
`;

export default function Ingredient({ ingredient, onClick }) {
  return (
    <StyledIngredient onClick={() => onClick()}>
      <div
        className="styled-ingredient-image"
        style={{ backgroundImage: `url(${ingredient.detail.image})` }}
      ></div>
      <div className="styled-ingredient-title">{ingredient.detail.title}</div>
    </StyledIngredient>
  );
}
