import React, { useState, useEffect } from 'react';
import { StyledOnboarding } from './StyledOnboarding';
import { useAPI } from 'context/APIcontext';
import {
  NUTRITION_ANYTHING,
  NUTRITION_PESCETARIEN,
  NUTRITION_VEGAN,
  NUTRITION_VEGETARIAN,
  USER_GENDER_FEMALE,
  USER_GENDER_MALE,
  USER_GOAL_BUILD_MUSCLE,
  USER_GOAL_LOSE_WEIGHT,
  USER_GOAL_MAINTAIN,
  DIET_ANYTHING,
  DIET_LOW_CARB,
  DIET_KETO,
  DIET_MEDITERRANEAN,
  DIET_PALEO,
  DIET_SLOW_CARB,
  DIET_HIGH_PROTEIN,
} from 'constants/userMetrics';
import { ASSETS_URL } from 'constants/assets';
import BlockButton from 'components/buttons/BlockButton';
import { useTranslation } from 'react-i18next';
import ArrowLeftShort from 'components/SVG/ArrowLeftShort';
import CheckIcon from 'components/SVG/CheckIcon';
import MaleIcon from 'components/SVG/MaleIcon';
import FemaleIcon from 'components/SVG/FemaleIcon';

export default function OnboardingPage() {
  const { t } = useTranslation('common');
  const { updateUserData } = useAPI();

  const [stepsNumber] = useState(8);
  const [currentStep, setCurrentStep] = useState(1);
  const [gender, setGender] = useState();
  const [height, setHeight] = useState();
  const [age, setAge] = useState();
  const [weight, setWeight] = useState();
  const [activity, setActivity] = useState();
  const [goal, setGoal] = useState();
  const [nutrition, setNutrition] = useState(NUTRITION_ANYTHING);

  const [diets] = useState([
    DIET_ANYTHING,
    DIET_LOW_CARB,
    DIET_KETO,
    DIET_MEDITERRANEAN,
    //DIET_PALEO,
    DIET_SLOW_CARB,
    DIET_HIGH_PROTEIN,
  ]);
  const [selectedDiet, setSelectedDiet] = useState(diets[0]);
  const [allergies, setAllergies] = useState([
    {
      slug: 'lactose',
      isSelected: false,
    },
    { slug: 'histamin', isSelected: false },
    { slug: 'fructose', isSelected: false },
    { slug: 'gluten', isSelected: false },
  ]);

  const [mealsAmount, setMealsAmount] = useState(4);

  const [baseMetabolicRate, setBaseMetabolicRate] = useState();
  const [overallMetabolicRate, setOverallMetabolicRate] = useState();
  const [targetCalories, setTargetCalories] = useState();

  const [stepPercentage, setStepPercentage] = useState(0);

  useEffect(() => {
    setStepPercentage(Math.round((currentStep * 100) / stepsNumber));
  }, [currentStep]);

  const handleSetUserData = () => {
    let userData = {
      profile: {
        gender: gender,
        mealsAmount: Number(mealsAmount),
        goal: goal, // Maintain, Build Muscle
        preferedUnits: 'metric', //U.S. Standard
        height: Number(height),
        weight: Number(weight),
        age: Number(age),
        activityLevel: Number(activity), // light active, moderate active, very active, extra active
        activeWeightGoal: true, // true, false
        weightGoal: 80,
        weightChangeRate: 0.5,
        diet: selectedDiet, // anything, low-carb, keto, mediterranean, paleo, slow-carb, high-protein
        nutrition: nutrition, // anything, pescetarien, vegan, vegetarian
        baseMetabolicRate: Number(baseMetabolicRate),
        overallMetabolicRate: Number(overallMetabolicRate),
        overwriteCalories: false,
        targetCalories: Number(targetCalories),
        manualCalories: Number(targetCalories),
        useHalfUnits: false,
      },
      mealPlan: [],
      shoppingList: [],
      saveMealPlans: [],
    };

    updateUserData(userData);
    //saveUserData(userData);
  };

  useEffect(() => {
    //console.log('g1-m', 66.47 + 13.7 * weight + 5 * height - 6.8 * age);
    //console.log('g1-f', 655.1 + 9.6 * weight + 1.8 * height - 4.7 * age);
    if (height && age && weight && activity && goal) {
      let tmpBaseMetabolicRate = 0;
      let tmpOverallMetabolicRate = 0;
      let tmpTargetCalories = 0;

      if (gender === USER_GENDER_MALE) {
        tmpBaseMetabolicRate = Math.round(66.47 + 13.7 * weight + 5 * height - 6.8 * age);
      }
      if (gender === USER_GENDER_FEMALE) {
        tmpBaseMetabolicRate = Math.round(655.1 + 9.6 * weight + 1.8 * height - 4.7 * age);
      }

      tmpOverallMetabolicRate = Math.round(tmpBaseMetabolicRate * activity);

      if (goal === USER_GOAL_LOSE_WEIGHT) {
        tmpTargetCalories = Math.round(tmpOverallMetabolicRate - 400);
      }
      if (goal === USER_GOAL_BUILD_MUSCLE) {
        tmpTargetCalories = Math.round(tmpOverallMetabolicRate + 400);
      }
      if (goal === USER_GOAL_MAINTAIN) {
        tmpTargetCalories = tmpOverallMetabolicRate;
      }

      setBaseMetabolicRate(tmpBaseMetabolicRate);
      setOverallMetabolicRate(tmpOverallMetabolicRate);
      setTargetCalories(tmpTargetCalories);
    }
  }, [gender, height, age, weight, activity, goal]);

  function scrollToWidget() {
    window.location.href = `${window.location.origin + window.location.pathname}#onboarding-widget`;
  }

  return (
    <>
      <div className="container-fullwidth" id="onboarding-widget">
        <StyledOnboarding>
          <section className="container calculator-result-wrapper">
            <div className="row d-block">
              <div className="onboarding-header w-100">
                <div className="d-flex justify-content-between pt-2 pb-2">
                  <div
                    className="text-left"
                    style={{ opacity: currentStep > 1 ? '1' : '0' }}
                    onClick={() => {
                      if (currentStep > 1) {
                        setCurrentStep(currentStep - 1);
                      }
                    }}
                  >
                    <ArrowLeftShort />
                    Zurück
                  </div>
                  <div className="text-right">
                    Schritt {currentStep}/{stepsNumber}
                  </div>
                </div>

                <div className="onboarding-progress">
                  <div
                    className="onboarding-progress-bar"
                    style={{ width: `${stepPercentage}%` }}
                  ></div>
                </div>
              </div>
              {currentStep === 1 && selectGoal()}
              {currentStep === 2 && selectGender()}
              {currentStep === 3 && selectNutrition()}
              {currentStep === 4 && selectDiet()}
              {currentStep === 5 && selectActivity()}
              {currentStep === 6 && selectMealsPerDay()}
              {currentStep === 7 && userInformations()}
              {currentStep === 8 && finishOnboarding()}
            </div>
          </section>
        </StyledOnboarding>
      </div>
    </>
  );

  function finishOnboarding() {
    return (
      <>
        <div className="onboarding-question">Du bist startklar</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Großartig! Dein Ernährungsplan steht bereit.
        </div>

        <div className="success-animation">
          <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </div>

        <div className="mt-5">
          <BlockButton
            text={'Weiter'}
            onClick={() => {
              handleSetUserData();
            }}
          ></BlockButton>
        </div>
      </>
    );
  }

  function userInformations() {
    return (
      <>
        <div className="onboarding-question">Über dich</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Damit wir dir helfen können, deine Ziele zu erreichen, benötigen wir einige Informationen
          über dich.
        </div>

        <div className="onboarding-values-input">
          <label htmlFor="basic-url">Dein Alter</label>
          <div className="input-group mb-3">
            <input
              pattern="\d*"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              onChange={(e) => setAge(e.target.value)}
              value={age}
              placeholder="0"
              type="number"
              required
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon3">
                Jahre
              </span>
            </div>
          </div>

          <label htmlFor="basic-url">Deine Körpergröße</label>
          <div className="input-group mb-3">
            <input
              pattern="\d*"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              onChange={(e) => setHeight(e.target.value)}
              value={height}
              placeholder="0"
              type="number"
              required
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon3">
                cm
              </span>
            </div>
          </div>

          <label htmlFor="basic-url">Dein aktuelles Gewicht</label>
          <div className="input-group mb-3">
            <input
              pattern="\d*"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              onChange={(e) => setWeight(e.target.value)}
              value={weight}
              placeholder="0"
              type="number"
              required
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon3">
                kg
              </span>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <BlockButton
            text={'Weiter'}
            disabled={!height || !age || !weight}
            onClick={() => {
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          ></BlockButton>
        </div>
      </>
    );
  }

  function selectActivity() {
    return (
      <>
        <div className="onboarding-question">Wie aktiv bist du im Alltag?</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Wir nutzen deine Essgewohnheiten um deinen Plan bestmöglich an deinen Alltag anzupassen
          und dir Aufwand zu sparen. Du kannst das später jederzeit ändern.
        </div>

        <div className="row p-4">
          <div
            className={`activity-level-box text-center ${activity === 1.1 ? 'is-selected' : ''}`}
            onClick={() => {
              setActivity(1.1);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-sitting-on-chair.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle mb-2">Geringfügig aktiv</div>
            <div className="daily-activity-item-description centered">
              Überwiegend sitzend (z. B. Büroangestellte:r)
            </div>
          </div>
          <div
            className={`activity-level-box text-center ${activity === 1.3 ? 'is-selected' : ''}`}
            onClick={() => {
              setActivity(1.3);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-stand-up.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle  mb-2">Mäßig aktiv</div>
            <div className="daily-activity-item-description centered">
              Meistens stehend (z. B. Lehrer:in, Kassierer:in)
            </div>
          </div>
          <div
            className={`activity-level-box text-center ${activity === 1.5 ? 'is-selected' : ''}`}
            onClick={() => {
              setActivity(1.5);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-walking.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle  mb-2">Aktiv</div>
            <div className="daily-activity-item-description centered">
              Meistens zu Fuß (z. B. Verkauf, Server)
            </div>
          </div>
          <div
            className={`activity-level-box text-center ${activity === 1.7 ? 'is-selected' : ''}`}
            onClick={() => {
              setActivity(1.7);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-manual-handling.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle mb-2">Sehr aktiv</div>
            <div className="daily-activity-item-description centered">
              Körperlich anstrengende Tätigkeit (z. B. Bauarbeiter:in)
            </div>
          </div>
        </div>
      </>
    );
  }

  function selectMealsPerDay() {
    return (
      <>
        <div className="onboarding-question">Anzahl der Mahlzeiten</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Wir nutzen deine Essgewohnheiten um deinen Plan bestmöglich an deinen Alltag anzupassen
          und dir Aufwand zu sparen. Du kannst das später jederzeit ändern.
        </div>

        <div className="form-group onboarding-answer-wrapper">
          <select
            className="form-control"
            id="num_meals_selector"
            defaultValue={mealsAmount}
            onChange={(e) => {
              setMealsAmount(e.target.value);
            }}
          >
            <option value="1">1 Mahlzeit</option>
            <option value="2">2 Mahlzeiten</option>
            <option value="3">3 Mahlzeiten</option>
            <option value="4">4 Mahlzeiten</option>
            <option value="5">5 Mahlzeiten</option>
            <option value="6">6 Mahlzeiten</option>
            <option value="7">7 Mahlzeiten</option>
            <option value="8">8 Mahlzeiten</option>
            <option value="9">9 Mahlzeiten</option>
          </select>
        </div>

        <div className="mt-5">
          <BlockButton
            text={'Weiter'}
            onClick={() => {
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          ></BlockButton>
        </div>
      </>
    );
  }

  function selectDiet() {
    return (
      <>
        <div className="onboarding-question">Wie möchtest du dich ernähren?</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Möchtest du eine bestimmte Diät befolgen? Du kannst diese Einstellung jederzeit wieder
          ändern.
        </div>

        <div className="onboarding-answer-wrapper">
          {diets.map((diet, index) => (
            <div
              key={`diets-${index}`}
              className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
                selectedDiet === diet ? 'is-selected' : ''
              }`}
              onClick={() => {
                setSelectedDiet(diet);
                setCurrentStep(currentStep + 1);
                scrollToWidget();
              }}
            >
              <div className="d-flex justify-content-end align-items-center">
                <div className="me-3">
                  <img src={`${ASSETS_URL}${diet}.png`}></img>
                </div>
                <div className="text-left">
                  <span className="d-block">{t(`diet-${diet}-title`)}</span>
                  <small>{t(`diet-${diet}-text`)}</small>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }

  function selectAllergies() {
    return (
      <>
        <div className="onboarding-question">Intoleranzen & Allergien</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Mehrfachauswahl möglich. Du kannst deine Angaben später jederzeit selbst anpassen.
        </div>

        <div className="onboarding-answer-wrapper">
          {allergies.map((allergie, index) => (
            <div
              key={`allergies-${index}`}
              className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
                allergie.isSelected ? 'is-selected' : ''
              }`}
              onClick={() => {
                setAllergies(
                  [...allergies],
                  (allergies[index].isSelected = !allergies[index].isSelected),
                );
              }}
            >
              <div className="text-left col-8">
                <span className="d-block">{t(`allergies-${allergie.slug}`)}</span>
                <small>{t(`allergies-${allergie.slug}-text`)}</small>
              </div>
              <div className="text-right col-4">
                <div className={`checkmark-icon ${allergie.isSelected ? 'visible' : 'invisible'}`}>
                  <CheckIcon />
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-5">
          <BlockButton
            text={'Weiter'}
            onClick={() => {
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          ></BlockButton>
        </div>
      </>
    );
  }

  function selectNutrition() {
    return (
      <>
        <div className="onboarding-question">Deine Ernährungsweise</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Keine Sorge, du kannst alle Angaben später jederzeit selbst anpassen.
        </div>

        <div className="onboarding-answer-wrapper">
          <div
            className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
              nutrition === NUTRITION_ANYTHING ? 'is-selected' : ''
            }`}
            onClick={() => {
              setNutrition(NUTRITION_ANYTHING);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="text-left ms-4 me-4">
              <div className="onboarding-icon">🍗</div>
            </div>
            <div className="text-left w-100">
              <span className="d-block">Mischkost</span>
              <small>Ausgewogene Ernährung, mehrmals pro Woche Gemüse, Fisch und Fleisch</small>
            </div>
          </div>
          <div
            className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
              nutrition === NUTRITION_PESCETARIEN ? 'is-selected' : ''
            }`}
            onClick={() => {
              setNutrition(NUTRITION_PESCETARIEN);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="text-left ms-4 me-4">
              <div className="onboarding-icon">🐟</div>
            </div>
            <div className="text-left w-100">
              <span className="d-block">Pescetarisch</span>
              <small>Pescetarische Ernährung mit viel Gemüse und manchmal Fisch</small>
            </div>
          </div>
          <div
            className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
              nutrition === NUTRITION_VEGETARIAN ? 'is-selected' : ''
            }`}
            onClick={() => {
              setNutrition(NUTRITION_VEGETARIAN);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="text-left  ms-4 me-4">
              <div className="onboarding-icon">🧀</div>
            </div>
            <div className="text-left w-100">
              <span className="d-block">Vegetarisch</span>
              <small>Kein Fisch und Fleisch</small>
            </div>
          </div>
          <div
            className={`onboarding-answer-button d-flex justify-content-between align-items-center ${
              nutrition === NUTRITION_VEGAN ? 'is-selected' : ''
            }`}
            onClick={() => {
              setNutrition(NUTRITION_VEGAN);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="text-left  ms-4 me-4">
              <div className="onboarding-icon">🥑</div>
            </div>
            <div className="text-left w-100">
              <span className="d-block">Vegan</span>
              <small>Keine tierischen Produkte</small>
            </div>
            <div className="text-right col-2 d-flex justify-content-end align-items-end">
              <div
                className={`checkmark-icon ${
                  nutrition === NUTRITION_VEGAN ? 'visible' : 'invisible'
                }`}
              >
                <CheckIcon />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function selectGender() {
    return (
      <>
        <div className="onboarding-question">Dein Geschlecht</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Dein Geschlecht hat großen Einfluss auf deinen Kalorienverbrauch
        </div>

        <div className="onboarding-answer-wrapper">
          <div
            className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
              gender === USER_GENDER_MALE ? 'is-selected' : ''
            }`}
            onClick={() => {
              setGender(USER_GENDER_MALE);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="text-left">
              <span className="d-block">Mann</span>
            </div>
            <div className="text-right">
              <div className="onboarding-icon">
                <MaleIcon />
              </div>
            </div>
          </div>
          <div
            className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
              gender === USER_GENDER_FEMALE ? 'is-selected' : ''
            }`}
            onClick={() => {
              setGender(USER_GENDER_FEMALE);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="text-left">
              <span className="d-block">Frau</span>
            </div>
            <div className="text-right">
              <div className="onboarding-icon">
                <FemaleIcon />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function selectGoal() {
    return (
      <>
        <div className="onboarding-question">Was ist dein Ziel?</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Wir nutzen dein Ziel um dein Ernährungsplan optimal auf dich anzupassen.
        </div>

        <div className="onboarding-answer-wrapper">
          <div
            className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
              goal === USER_GOAL_LOSE_WEIGHT ? 'is-selected' : ''
            }`}
            onClick={() => {
              setGoal(USER_GOAL_LOSE_WEIGHT);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="d-flex justify-content-end align-items-center">
              <div className="me-3">
                <img src={`${ASSETS_URL}lose-weight.svg`} className="w-100"></img>
              </div>
              <div className="text-left">
                <span className="d-block">Abnehmen</span>
                <small>Fett verbrennen & schlank werden</small>
              </div>
            </div>
          </div>
        </div>

        <div className="onboarding-answer-wrapper">
          <div
            className={`p-4 onboarding-answer-button  d-flex justify-content-between align-items-center ${
              goal === USER_GOAL_MAINTAIN ? 'is-selected' : ''
            }`}
            onClick={() => {
              setGoal(USER_GOAL_MAINTAIN);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="d-flex justify-content-end align-items-center">
              <div className="me-3">
                <img src={`${ASSETS_URL}get-fit.svg`} className="w-100"></img>
              </div>
              <div className="text-left">
                <span className="d-block">Fitter werden</span>
                <small>Definieren, Straffen & gesund fühlen</small>
              </div>
            </div>
          </div>
        </div>

        <div className="onboarding-answer-wrapper">
          <div
            className={`p-4 onboarding-answer-button  d-flex justify-content-between align-items-center ${
              goal === USER_GOAL_BUILD_MUSCLE ? 'is-selected' : ''
            }`}
            onClick={() => {
              setGoal(USER_GOAL_BUILD_MUSCLE);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
            }}
          >
            <div className="d-flex justify-content-end align-items-center">
              <div className="me-3">
                <img src={`${ASSETS_URL}build-muscles.svg`} className="w-100"></img>
              </div>
              <div className="text-left">
                <span className="d-block">Muskelmasse aufbauen</span>
                <small>Masse & Kraft aufbauen</small>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
