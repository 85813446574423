import React from 'react';
import styled from 'styled-components';

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-right: 14px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #ccc;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .toggle-text {
text-align: left;
  }
`;

export default function ToggleComponent({ isChecked, onChange, label }) {
  return (
    <StyledToggle>
      <label className="switch">
        <input type="checkbox" checked={isChecked} onChange={(e) => onChange(e)} />
        <span className="slider round"></span>
      </label>
      <div className="toggle-text">{label}</div>
    </StyledToggle>
  );
}
