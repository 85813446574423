import React, { useEffect, useState } from 'react';
import { useAPI } from 'context/APIcontext';
import styled from 'styled-components';
import PenIcon from 'components/SVG/PenIcon';
import Alert from 'components/Alert/Alert';
import EditMealPlanCaloriesPrompt from './EditMealPlanCaloriesPrompt';
import ToggleComponent from 'components/ToggleComponent';
import SmallButton from 'components/buttons/SmallButton';
import SectionHeader from 'components/Typo/SectionHeader';

const StyledCalorieOverview = styled.div`
  padding: 20px 0;
  margin: 20px 0;
  justify-content: space-between;
  border-radius: 5px;
  //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
  padding: 20px;
  background-color: #fff;

  .fake-link {
    color: #03a9f4;
    font-weight: 600;
    border-bottom: 1px solid #f8f8f8;
    transition: 0.4s all ease;
    padding-bottom: 4px;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid #03a9f4;
    }

    svg {
      margin-right: 8px;
    }
  }

  .metric {
  }

  .metric-icon {
    border: 1px solid whitesmoke;
    width: 50px;
    height: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 20px;
  }

  .metric-text {
    display: flex;
    //align-items: center;
    justify-content: center;
    flex-direction: column;

    .metric-number {
      font-size: 18px;
      font-weight: 700;
      line-height: 1;
      display: flex;
      align-items: center;
    }

    .metric-number-split {
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }

    .metric-text {
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
    }
  }

  .metric-edit {
    cursor: pointer;
    background-color: whitesmoke;
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    margin: 20px auto 0;

    &:hover {
      background-color: #03a9f4;
      color: #fff;
    }

    svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
`;

export default function DayCalorieOverview({ day, currentDayIndex }) {
  const { userData, changeDayCalories, handleToggleHalfUnits } = useAPI();

  const [calories, setCalories] = useState(day.targetCalories);
  const [showEditCaloriesPrompt, setShowEditCaloriesPrompt] = useState(false);
  const [calorieOverview, setCalorieOverview] = useState({
    calories: 0,
    protein: 0,
    carbs: 0,
    sugar: 0,
    fat: 0,
    fiber: 0,
    meals: {
      calories: 0,
      protein: 0,
      carbs: 0,
      sugar: 0,
      fat: 0,
      fiber: 0,
    },
    recipes: {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
    },
  });

  useEffect(() => {
    let tmpState = {
      calories: 0,
      protein: 0,
      carbs: 0,
      sugar: 0,
      fat: 0,
      fiber: 0,
      meals: {
        calories: 0,
        protein: 0,
        carbs: 0,
        sugar: 0,
        fat: 0,
        fiber: 0,
      },
      recipes: {
        calories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      },
    };

    if (day.meals && day.meals.length > 0) {
      day.meals.forEach((meal) => {
        tmpState.meals.calories += meal.detail.ratio * meal.detail.calories || 0;
        tmpState.meals.protein += meal.detail.ratio * meal.detail.protein || 0;
        tmpState.meals.carbs += meal.detail.ratio * meal.detail.carbs || 0;
        tmpState.meals.sugar += meal.detail.ratio * meal.detail.sugar || 0;
        tmpState.meals.fat += meal.detail.ratio * meal.detail.fat || 0;
        tmpState.meals.fiber += meal.detail.ratio * meal.detail.fiber || 0;
      });
    }

    if (day.recipes && day.recipes.length > 0) {
      day.recipes.forEach((recipes) => {
        tmpState.recipes.calories += recipes.selectedServing * recipes.calories || 0;
        tmpState.recipes.protein += recipes.selectedServing * recipes.protein || 0;
        tmpState.recipes.carbs += recipes.selectedServing * recipes.carbs || 0;
        tmpState.recipes.fat += recipes.selectedServing * recipes.fat || 0;
      });
    }

    tmpState.calories = Math.round(tmpState.meals.calories + tmpState.recipes.calories);
    tmpState.protein = Math.round(tmpState.meals.protein + tmpState.recipes.protein);
    tmpState.carbs = Math.round(tmpState.meals.carbs + tmpState.recipes.carbs);
    tmpState.fat = Math.round(tmpState.meals.fat + tmpState.recipes.fat);

    setCalorieOverview(tmpState);
  }, [day, userData]);

  return (
    <>
      <StyledCalorieOverview>
        <SectionHeader>
          <div className="text-center">Dein Ernährungsplan auf einen Blick</div>
        </SectionHeader>

        <div className="row" style={{ margin: '0' }}>
          <div className="metric col-6 col-md-3 d-flex mb-md-0 mb-3 p-0">
            <div className="metric-icon">🔥</div>
            <div className="metric-text">
              <div className="metric-number">{calorieOverview.calories}</div>

              <div className="metric-text">Kalorien</div>
            </div>
          </div>
          <div className="metric col-6 col-md-3 d-flex mb-md-0 mb-3 p-0">
            <div className="metric-icon">💪</div>
            <div className="metric-text">
              <div className="metric-number">{calorieOverview.protein}g</div>
              <div className="metric-text">Eiweiß</div>
            </div>
          </div>
          <div className="metric col-6 col-md-3 d-flex p-0">
            <div className="metric-icon">🌾</div>
            <div className="metric-text">
              <div className="metric-number">{calorieOverview.carbs}g</div>
              <div className="metric-text">Kohlenhydrate</div>
            </div>
          </div>
          <div className="metric col-6 col-md-3 d-flex p-0">
            <div className="metric-icon">🥑</div>
            <div className="metric-text">
              <div className="metric-number">{calorieOverview.fat}g</div>
              <div className="metric-text">Fett</div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center">
            {/*<ToggleComponent
              isChecked={userData.profile.useHalfUnits}
              onChange={(e) => {
                handleToggleHalfUnits(e.target.checked);
              }}
              label="Halbe Einheiten - Erhöht die Genauigkeit"
            ></ToggleComponent>*/}
            <div className="mt-2">
              <span className="fake-link " onClick={() => setShowEditCaloriesPrompt(true)}>
                Kalorienziel bearbeiten
              </span>
            </div>
          </div>
        </div>

        {/*
      {calorieOverview.calories}kcal - Protein: {calorieOverview.protein} - Carbs:{' '}
      {calorieOverview.carbs} - Fat:
  {calorieOverview.fat}*/}
      </StyledCalorieOverview>

      {showEditCaloriesPrompt && (
        <Alert
          isOpen={true}
          onClose={() => setShowEditCaloriesPrompt(false)}
          onCancel={() => setShowEditCaloriesPrompt(false)}
          onConfirm={() => {
            changeDayCalories(currentDayIndex, calories);
            setShowEditCaloriesPrompt(false);
          }}
        >
          <EditMealPlanCaloriesPrompt
            onChange={(targetCalories) => {
              setCalories(targetCalories);
            }}
            currentTargetCalories={day.targetCalories}
          ></EditMealPlanCaloriesPrompt>
        </Alert>
      )}
    </>
  );
}
