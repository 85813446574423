import React from 'react';
import styled from 'styled-components';

export default function PageContainer({ children }) {
  return (
    <StyledPageContainer>
      <div className="container-fluid" style={{ maxWidth: '1220px' }}>
        {children}
      </div>
    </StyledPageContainer>
  );
}

export const StyledPageContainer = styled.div`
  margin: 0 auto;
  min-height: 100vh;

  @media print {
    * {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      background-color: #fff;
      color: #000;
    }

    .meal-cta,
    .sidenav,
    .tab,
    .meal-ingredient-image,
    .small-button {
      display: none;
    }

    .meal-top {
    }

    .meal-slotname,
    .meal-title,
    .meal-title h4,
    .meal-facts {
      color: #000;
    }
  }
`;
