import React, { useState } from 'react';
import { useEffect } from 'react';

import {
  USER_GENDER_FEMALE,
  USER_GENDER_MALE,
  USER_GOAL_BUILD_MUSCLE,
  USER_GOAL_LOSE_WEIGHT,
  USER_GOAL_MAINTAIN,
} from '../constants/userMetrics';
import InlineButton from './buttons/InlineButton';
import styled from 'styled-components';
import { ASSETS_URL, URL_NUTRITASTIC_DE } from '../constants/assets';
import RedButton from './buttons/RedButton';
import { sendTrackingPixel } from '../helper/tracking';
import CTABlue from './buttons/CTABlue';

const StyledCalorieCalculator = styled.div`
  .calculator-step {
    min-height: 236px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 22px 0 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: #fff;

    h4 {
      margin: 20px 0 !important;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .line-dot {
    position: absolute;
    left: 50%;
    top: 0;

    .dot {
      font-size: 1rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      position: relative;
      top: 0;
      left: -50%;
      width: 32px;
      line-height: 31px;
      border-radius: 50%;
      text-align: center;
      background-color: #37474f;
      color: #fff;
      margin-top: -16px;
    }
  }

  .icn-btn-wrapper {
    max-width: 460px;
    margin-left: auto !important;
    margin-right: auto !important;

    .img-btn-container {
      cursor: pointer;
      display: inline-block;
      margin-bottom: 0;
      border: none;
      padding: 0;
      background: 0 0;
      margin: 0 20px;

      .icon-svg {
        width: 72px;
        height: 72px;
      }

      &.is-selected .choice-btn {
        background-image: linear-gradient(117deg, #03a9f4, #0288d1);
        color: #fff;
      }
    }
  }

  .activity-level-box {
    cursor: pointer;

    .daily-activity-item-description {
      font-size: 16px;
    }

    &.is-selected .choice-btn {
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
      color: #fff;
    }
  }

  .input-label-wrapper {
    position: relative;

    .label {
      position: absolute;
      top: 16px;
      margin-left: 7px;
    }

    .input {
      -webkit-appearance: none;
      width: 94px;
      border: 0;
      font-family: inherit;
      padding: 12px 0;
      height: 48px;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      color: #37474f;
      border-bottom: 2px solid #37474f;
      background: 0 0;
      border-radius: 0;
      line-height: 1.18;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .choice-btn {
    margin-top: 20px;
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    background-image: linear-gradient(104deg, #fafafa, #eee);
    color: #37474f;
    transition: 0.1s all ease;
    font-weight: 700;
    font-size: 16px;
  }

  .infobox {
    margin-left: auto !important;
    margin-right: auto !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    border-radius: 12px;
    cursor: pointer;
    padding: 20px;

    .infobox-description {
      margin-bottom: 0;
      padding: 0 15px 32px;
      font-size: 16px;
    }

    .icon-svg {
      width: 71px;
      height: 71px;
    }

    &:hover,
    &.is-selected {
      border: 1px solid #03a9f4;
    }
  }

  .font-highlight {
    font-size: 22px;
    color: #37474f;
    font-weight: 700;
  }

  .font-subtitle {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    color: #37474f;
    margin-top: 16px;
  }

  .calculator-result-wrapper {
    position: relative;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin: 20px 0;
    padding: 20px;
  }

  .calculator-daily-calories-submit {
    background: linear-gradient(130deg, #ffa634, #ff435b);
    border: none;
    box-shadow: 0 8px 40px 0 rgb(0 0 0 / 35%);
    color: #fff;
    font-size: 17px;
    border-radius: 30px;
    padding: 12px 40px;
    border: 0 none !important;

    &:focus {
      outline: none;
    }

    &.is-disabled {
      opacity: 0.4;
    }
  }
`;

export default function CalorieCalculator({ handleSelectCalories }) {
  const [gender, setGender] = useState(USER_GENDER_FEMALE);
  const [height, setHeight] = useState();
  const [age, setAge] = useState();
  const [weight, setWeight] = useState();
  const [activity, setActivity] = useState(1.8);
  const [showResults, setShowResults] = useState(false);
  const [goal, setGoal] = useState();

  const [basalMetabolicRate, setBasalMetabolicRate] = useState();
  const [overallMetabolicRate, setOverallMetabolicRate] = useState();

  function handleShowResults() {
    if (basalMetabolicRate && overallMetabolicRate) {
      setShowResults(true);
    }
  }

  useEffect(() => {
    //console.log('g1-m', 66.47 + 13.7 * weight + 5 * height - 6.8 * age);
    //console.log('g1-f', 655.1 + 9.6 * weight + 1.8 * height - 4.7 * age);
    if (height && age && weight && activity) {
      if (gender === USER_GENDER_MALE) {
        setBasalMetabolicRate(Math.round(66.47 + 13.7 * weight + 5 * height - 6.8 * age));
      }
      if (gender === USER_GENDER_FEMALE) {
        setBasalMetabolicRate(Math.round(655.1 + 9.6 * weight + 1.8 * height - 4.7 * age));
      }

      setOverallMetabolicRate(Math.round(basalMetabolicRate * activity));
    } else {
      return;
    }
  }, [gender, height, age, weight, activity]);

  return (
    <StyledCalorieCalculator className="calculators" id="calorie-calculator">
      {!showResults && (
        <section className="container-fluid">
          {/*<div className="row">
          <div className="col-12 text-center">
            <h1 className="margin-bottom-16 font-weight-bold">Kalorienrechner</h1>
            <p className="font-subtitle light mt-0 mb-4">
              Für eine genaue Berechnung benötigen wir einige grundlegende Informationen über dich
            </p>
          </div>
        </div>*/}
          <div className="row mt-0">
            <div className="col-12 col-md-6">
              <div className="calculator-step" id="calculator-step-1">
                <div className="line-dot">
                  <div className="dot">1</div>
                </div>
                <h4 className="mb-0 mb-md-32">Was ist dein Geschlecht?</h4>
                <div className="mt-16">
                  <div className="icn-btn-wrapper" id="gender-icn-btn-wrapper">
                    <div
                      id="calculator-gender-male-input"
                      className={`img-btn-container no-hover js-input-field focused ${
                        gender === USER_GENDER_MALE ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setGender(USER_GENDER_MALE);
                      }}
                    >
                      <img
                        className="icon-svg"
                        src={`${ASSETS_URL}icons.svg#icon-male`}
                        alt="Male"
                        type="image/svg+xml"
                      />
                      <div className={`choice-btn grey font-subtitle`}>Mann</div>
                    </div>
                    <div
                      id="calculator-gender-female-input"
                      className={`img-btn-container no-hover js-input-field focused ${
                        gender === USER_GENDER_FEMALE ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setGender(USER_GENDER_FEMALE);
                      }}
                    >
                      <img
                        className="icon-svg"
                        src={`${ASSETS_URL}icons.svg#icon-female`}
                        alt="Female"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle">Frau</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="calculator-step" id="calculator-step-2">
                <div className="line-dot">
                  <div className="dot">2</div>
                </div>
                <h4 className="mb-0 mb-md-32">Wie alt bist du?</h4>
                <div className="mt-16">
                  <div className="input-label-wrapper">
                    <div className="mt16-32">
                      <input
                        type="tel"
                        className="input dual-number js-input-field invalid-highlight js-max-length focused"
                        placeholder={21}
                        maxLength={3}
                        id="calculator-step-age"
                        name="calculator-step-age"
                        pattern="[0-9]*"
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value);
                        }}
                        inputMode="tel"
                      />
                      <label htmlFor="calculator-step-age" className="label invalid-highlight">
                        Jahre
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-0 mt-md-32">
              <div className="calculator-step" id="calculator-step-3">
                <div className="line-dot">
                  <div className="dot">3</div>
                </div>
                <h4 className="mb-0 mb-md-32">Wie groß bist du?</h4>
                <div className="mt-16">
                  <div className="d-flex flex-column">
                    <div className="input-label-wrapper order-1 order-md-0 ">
                      <div id="calculator-step-height-cm-input-wrapper" className="">
                        <input
                          type="tel"
                          id="calculator-step-height-cm"
                          name="height[cm]"
                          className="centered js-input-field input invalid-highlight js-max-length focused"
                          placeholder={170}
                          maxLength={3}
                          value={height}
                          onChange={(e) => {
                            setHeight(e.target.value);
                          }}
                          required
                          pattern="[0-9]*"
                          inputMode="tel"
                        />
                        <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                          cm
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mt-0 mt-md-32">
              <div className="calculator-step" id="calculator-step-4">
                <div className="line-dot">
                  <div className="dot">4</div>
                </div>
                <h4 className="mb-0 mb-md-32">Was ist dein Gewicht?</h4>
                <div className="mt-16">
                  <div className="d-flex flex-column">
                    <div className="input-label-wrapper order-1 order-md-0">
                      <div className="kg-input-wrapper">
                        <input
                          type="tel"
                          id="calculator-step-height-cm"
                          name="height[cm]"
                          className="centered js-input-field input invalid-highlight js-max-length focused"
                          placeholder={69}
                          maxLength={3}
                          value={weight}
                          onChange={(e) => {
                            setWeight(e.target.value);
                          }}
                          required
                          pattern="[0-9]*"
                          inputMode="tel"
                        />
                        <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                          KG
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-2 mt-md-32">
              <div className="calculator-step" id="calculator-step-5">
                <div className="line-dot">
                  <div className="dot">5</div>
                </div>
                <h4 className="mb-0 mb-md-32">Wie aktiv bist du im Alltag?</h4>
                <div className="row p-4">
                  <div
                    className={`activity-level-box col-6 col-md-3 ${
                      activity === 1.1 ? 'is-selected' : ''
                    }`}
                    onClick={() => {
                      setActivity(1.1);
                    }}
                  >
                    <img
                      className="icon-svg small"
                      src={`${ASSETS_URL}icon-sitting-on-chair.svg`}
                      alt="Lightly active"
                      type="image/svg+xml"
                    />
                    <div className="choice-btn grey font-subtitle mb-2">Geringfügig aktiv</div>
                    <div className="daily-activity-item-description centered">
                      Überwiegend sitzend (z. B. Büroangestellte:r)
                    </div>
                  </div>
                  <div
                    className={`activity-level-box col-6 col-md-3 ${
                      activity === 1.3 ? 'is-selected' : ''
                    }`}
                    onClick={() => {
                      setActivity(1.3);
                    }}
                  >
                    <img
                      className="icon-svg small"
                      src={`${ASSETS_URL}icon-stand-up.svg`}
                      alt="Lightly active"
                      type="image/svg+xml"
                    />
                    <div className="choice-btn grey font-subtitle  mb-2">Mäßig aktiv</div>
                    <div className="daily-activity-item-description centered">
                      Meistens stehend (z. B. Lehrer:in, Kassierer:in)
                    </div>
                  </div>
                  <div
                    className={`activity-level-box col-6 col-md-3 ${
                      activity === 1.5 ? 'is-selected' : ''
                    }`}
                    onClick={() => {
                      setActivity(1.5);
                    }}
                  >
                    <img
                      className="icon-svg small"
                      src={`${ASSETS_URL}icon-walking.svg`}
                      alt="Lightly active"
                      type="image/svg+xml"
                    />
                    <div className="choice-btn grey font-subtitle  mb-2">Aktiv</div>
                    <div className="daily-activity-item-description centered">
                      Meistens zu Fuß (z. B. Verkauf, Server)
                    </div>
                  </div>
                  <div
                    className={`activity-level-box col-6 col-md-3 ${
                      activity === 1.7 ? 'is-selected' : ''
                    }`}
                    onClick={() => {
                      setActivity(1.7);
                    }}
                  >
                    <img
                      className="icon-svg small"
                      src={`${ASSETS_URL}icon-manual-handling.svg`}
                      alt="Lightly active"
                      type="image/svg+xml"
                    />
                    <div className="choice-btn grey font-subtitle mb-2">Sehr aktiv</div>
                    <div className="daily-activity-item-description centered">
                      Körperlich anstrengende Tätigkeit (z. B. Bauarbeiter:in)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center mt-4 mb-4">
              <CTABlue
                disabled={!basalMetabolicRate || !overallMetabolicRate}
                id="calculator-daily-calories-submit"
                text={'Kalorienbedarf berechnen'}
                onClick={() => {
                  const violation = document.getElementById('calorie-calculator');
                  window.scrollTo({
                    top: violation.offsetTop,
                    behavior: 'smooth',
                  });
                  handleShowResults();
                  sendTrackingPixel('calorie_calculator_calculate_calories');
                }}
              ></CTABlue>
            </div>
          </div>
        </section>
      )}

      {showResults && (
        <section className="container-fluid">
          <div className="row">
            {!handleSelectCalories && (
              <div className="col-12">
                <h2 className="h1 mb-2 text-center font-weight-bold">Dein persönliches Ergebnis</h2>
              </div>
            )}

            <div className="col-12 col-md-4 mt-4 text-center infobox-wrapper">
              <div
                id="daily-calories-result-box-loose"
                className={`infobox ${goal === USER_GOAL_LOSE_WEIGHT ? 'is-selected' : ''}`}
                onClick={() => {
                  setGoal(USER_GOAL_LOSE_WEIGHT);
                  handleSelectCalories(overallMetabolicRate);
                }}
              >
                <div className="h4 font-weight-bold mb-3">Abnehmen</div>
                <img
                  className="icon-svg"
                  src={`${ASSETS_URL}icon-negative-dynamic.svg`}
                  alt="Lose weight"
                  type="image/svg+xml"
                />
                <div className="text-center">
                  <p className="font-subtitle mb-0 mt-2">Kalorienverbrauch pro Tag</p>
                  <div className="font-highlight mt-0 mb-2 ps-3 pe-3">
                    {overallMetabolicRate - 300} bis {overallMetabolicRate - 100} Kalorien
                  </div>
                  <p className="infobox-description">
                    Mit dieser täglichen Kalorienmenge kannst du auf gesunde und nachhaltige Weise 1
                    bis 2 kg pro Woche abnehmen.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-4 text-center infobox-wrapper">
              <div
                id="daily-calories-result-box-maintain"
                className={`infobox ${goal === USER_GOAL_MAINTAIN ? 'is-selected' : ''}`}
                onClick={() => {
                  setGoal(USER_GOAL_MAINTAIN);
                  handleSelectCalories(overallMetabolicRate);
                }}
              >
                <div className="h4 font-weight-bold mb-3">Gewicht halten</div>
                <img
                  className="icon-svg"
                  src={`${ASSETS_URL}icon-neutral-dynamic.svg`}
                  alt="Maintain weight"
                  type="image/svg+xml"
                />
                <div className="text-center">
                  <p className="font-subtitle mb-0 mt-2">Kalorienverbrauch pro Tag</p>
                  <div className="font-highlight mt-0 mb-2 ps-3 pe-3">
                    {overallMetabolicRate - 100} bis {overallMetabolicRate + 100} Kalorien
                  </div>
                  <p className="infobox-description">
                    Mit dieser täglichen Kalorienmenge kannst du dein aktuelles Gewicht halten.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mt-4 text-center infobox-wrapper">
              <div
                id="daily-calories-result-box-gain"
                className={`infobox ${goal === USER_GOAL_BUILD_MUSCLE ? 'is-selected' : ''}`}
                onClick={() => {
                  setGoal(USER_GOAL_BUILD_MUSCLE);
                  handleSelectCalories(overallMetabolicRate);
                }}
              >
                <div className="h4 font-weight-bold mb-3">Zunehmen</div>
                <img
                  className="icon-svg"
                  src={`${ASSETS_URL}icon-positive-dynamic.svg`}
                  alt="Gain weight"
                  type="image/svg+xml"
                />
                <div className="text-center">
                  <p className="font-subtitle mb-0 mt-2">Kalorienverbrauch pro Tag</p>
                  <div className="font-highlight mt-0 mb-2 ps-3 pe-3">
                    {overallMetabolicRate + 100} bis {overallMetabolicRate + 300} Kalorien
                  </div>
                  <p className="infobox-description">
                    Mit dieser täglichen Kalorienmenge kannst du 1 - 2 kg pro Woche zulegen.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {!handleSelectCalories && (
            <>
              <div className="offset-sm-2 col-12 col-sm-8 mt-5 top_spacer bottom_spacer text-center">
                <div className="mt-4">
                  <RedButton
                    text={'Starte jetzt deinen Ernährungsplan'}
                    onClick={() => {
                      sendTrackingPixel('calorie_calculator_meal_plan');
                      window.location.href = URL_NUTRITASTIC_DE;
                    }}
                  ></RedButton>
                </div>
                <div className="mt-4">
                  <InlineButton
                    onClick={() => {
                      sendTrackingPixel('calorie_calculator_again');
                      const violation = document.getElementById('calorie-calculator');
                      window.scrollTo({
                        top: violation.offsetTop,
                        behavior: 'smooth',
                      });
                      setShowResults(false);
                    }}
                    text={'Neu berechnen'}
                  ></InlineButton>
                </div>
              </div>
            </>
          )}
        </section>
      )}
    </StyledCalorieCalculator>
  );
}
