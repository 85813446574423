import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { ASSETS_URL } from 'constants/assets';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'context/APIcontext';
import Meal from 'pages/MealPlanPage/Components/Meal';
import {
  DIET_ANYTHING,
  DIET_LOW_CARB,
  DIET_KETO,
  DIET_MEDITERRANEAN,
  DIET_SLOW_CARB,
  DIET_HIGH_PROTEIN,
  NUTRITION_ANYTHING,
  NUTRITION_PESCETARIEN,
  NUTRITION_VEGETARIAN,
  NUTRITION_VEGAN,
  USER_GOAL_BUILD_MUSCLE,
  USER_GOAL_LOSE_WEIGHT,
  USER_GOAL_MAINTAIN,
  USER_GENDER_FEMALE,
  USER_GENDER_MALE,
} from 'constants/userMetrics';
import CalorieCalculator from 'components/CalorieCalculator';
import CTABlue from 'components/buttons/CTABlue';
import Overlay from 'components/Overlay';
import { useEffect } from 'react';
import Alert from 'components/Alert/Alert';
import SectionHeader from 'components/Typo/SectionHeader';
import DayCalorieOverview from 'pages/MealPlanPage/Components/DayCalorieOverview';
import DemoMeal from 'components/DemoMeal';
import RedButton from 'components/buttons/RedButton';
import InlineButton from 'components/buttons/InlineButton';
import { URL_NUTRITASTIC_DE } from 'constants/URLS';
import { sendTrackingPixel } from 'helper/tracking';
import LoadingState from 'components/LoadingStats';

const StyledLandingPage = styled.div`
  .landing-loading-wrapper {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
  }

  .calculator-step {
    min-height: 236px;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    border-radius: 12px;
    margin: 22px 0 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    padding: 20px 0;

    h4 {
      margin: 20px 0 !important;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .landing-label {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
  }

  select,
  input {
    border: 0;
    font-family: inherit;
    height: 48px;
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    color: #37474f;
    background: 0 0;
    line-height: 1;
    appearance: auto;
    font-size: 16px;
    background-color: whitesmoke;
    border-radius: 5px;
    line-height: 1;
    margin-right: 10px;
    padding: 10px;
    height: auto;

    &.has-error {
      border: 1px solid red;
    }
  }

  @media (min-width: 768px) {
    .landing-label {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
    }

    select,
    input {
    }
  }

  .calorie-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    input {
      width: 100%;
    }
  }

  .line-dot {
    position: absolute;
    left: 50%;
    top: 0;

    .dot {
      font-size: 1rem;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      position: relative;
      top: 0;
      left: -50%;
      width: 32px;
      line-height: 31px;
      border-radius: 50%;
      text-align: center;
      background-color: #37474f;
      color: #fff;
      margin-top: -16px;
      box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    }
  }

  .form-control {
    height: 100%;
  }

  .calculate-calorie-intake {
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    color: #ff8456;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }

    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }

  button {
    background-color: #007bff;
    color: #ffffff;
    border-radius: 8px;
    padding: 10px 20px;
  }

  .day-calorie-overview {
    position: relative;
    border-radius: 5px;
    padding: 0 20px;
  }

  .next-meals-wrapper {
    position: relative;
    border-radius: 5px;
    padding: 0 20px;

    &.is-visible {
      padding: 20px 20px 50px;
    }

    .next-meals {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      //background: linear-gradient(rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 1) 100%);
      background-color: rgba(255, 255, 255, 0.75);
      backdrop-filter: blur(10px);

      .next-meals-title {
        padding: 60px 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h4 {
          font-weight: 700;
          font-size: 24px;
          text-align: center;
          margin: 0 auto 20px;
          line-height: 1.4;
        }

        span {
          font-size: 18px;
          text-align: center;
          margin: 0 auto 20px;
        }

        ul {
          max-width: 600px;
          display: block;
          font-size: 18px;
          text-align: center;
          margin: 0 auto 20px;
          list-style: square;
          text-align: left;
          color: #fff;

          li {
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  .choice-btn {
    margin-top: 20px;
    border: none;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 8px;
    color: #37474f;
    transition: 0.1s all ease;
    font-weight: 700;
    font-size: 16px;
    background-color: whitesmoke;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
    cursor: pointer;

    &:hover {
      color: #fff;
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
    }
  }

  .activity-level-box {
    &.is-selected .choice-btn {
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
      color: #fff;
    }
  }

  .form-error {
    background: linear-gradient(130deg, #ffa634, #ff435b);
    padding: 20px 10px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 15px;
    border-radius: 5px;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }
`;

const StyledNutritionButton = styled.div`
  display: block;
  border-radius: 12px;
  margin: 0;
  cursor: pointer;
  transition: border-color 0.3s ease;
  position: relative;
  text-align: center;
  font-weight: 600;
  width: 100%;
  display: flex;
  height: 30px;
  flex-direction: column;
  flex: 33% 0 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;

  @media (min-width: 768px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: unset;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.is-big {
    height: 120px;
  }

  img {
    width: 50px;
    height: 50px;
    margin: 0 0 10px;
  }

  span {
    display: block;
  }

  &:hover {
    background-color: whitesmoke;
  }

  &.is-selected {
    color: #fff;
    background-color: #03a9f4;
  }
`;

const StyledMealPlanDay = styled.div``;

export default function LandingPage({ showCheckoutButton }) {
  const {
    setUserData,
    userData,
    changeDayCalories,
    changeDayMealsAmont,
    handleGenerateMealPlan,
    handleUserSetDiet,
    handleUserSetNutrition,
  } = useAPI();
  const { t } = useTranslation('common');

  const myRef = useRef(null);

  const [goal, setGoal] = useState(USER_GOAL_BUILD_MUSCLE);
  const [nutrition] = useState(['anything', 'pescetarien', 'vegan', 'vegetarian']);
  const [dayIndex] = useState(0);
  const [showCalorieCalculator, setShowCalorieCalculator] = useState(false);
  const [selectedNutrition, setSelectedNutrition] = useState(nutrition[0]);
  const [diets] = useState([
    DIET_ANYTHING,
    DIET_HIGH_PROTEIN,
    DIET_LOW_CARB,
    DIET_SLOW_CARB,
    DIET_KETO,
    DIET_MEDITERRANEAN,
    //DIET_PALEO,
  ]);
  const [selectedDiet, setSelectedDiet] = useState(diets[0]);
  const [targetCalories, setTargetCalories] = useState(2000);
  const [baseMetabolicRate, setBaseMetabolicRate] = useState();
  const [overallMetabolicRate, setOverallMetabolicRate] = useState();
  const [mealsAmount, setMealsAmount] = useState(4);

  const [isMealPlanCreated, setIsMealPlanCreated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [gender, setGender] = useState(USER_GENDER_FEMALE);
  const [height, setHeight] = useState();
  const [age, setAge] = useState();
  const [weight, setWeight] = useState();
  const [activity, setActivity] = useState(1.1);
  const [goalWeight, setGoalWeight] = useState();

  const [isError, setIsError] = useState(false);
  const [isErrorAge, setIsErrorAge] = useState(false);
  const [isErrorHeight, setIsErrorHeight] = useState(false);
  const [isErrorWeight, setIsErrorWeight] = useState(false);
  const [isErrorGoalWeight, setIsErrorGoalWeight] = useState(false);

  useEffect(() => {
    setIsError(false);

    if (age) {
      setIsErrorAge(false);
    }
    if (height) {
      setIsErrorHeight(false);
    }
    if (weight) {
      setIsErrorWeight(false);
    }
    if (weight) {
      setIsErrorWeight(false);
    }
    if (goalWeight) {
      setIsErrorGoalWeight(false);
    }

    if (height && age && weight && activity && goal) {
      let tmpBaseMetabolicRate = 0;
      let tmpOverallMetabolicRate = 0;
      let tmpTargetCalories = 0;

      if (gender === USER_GENDER_MALE) {
        tmpBaseMetabolicRate = Math.round(66.47 + 13.7 * weight + 5 * height - 6.8 * age);
      }
      if (gender === USER_GENDER_FEMALE) {
        tmpBaseMetabolicRate = Math.round(655.1 + 9.6 * weight + 1.8 * height - 4.7 * age);
      }

      tmpOverallMetabolicRate = Math.round(tmpBaseMetabolicRate * activity);

      if (goal === USER_GOAL_LOSE_WEIGHT) {
        tmpTargetCalories = Math.round(tmpOverallMetabolicRate - 400);
      }
      if (goal === USER_GOAL_BUILD_MUSCLE) {
        tmpTargetCalories = Math.round(tmpOverallMetabolicRate + 400);
      }
      if (goal === USER_GOAL_MAINTAIN) {
        tmpTargetCalories = tmpOverallMetabolicRate;
      }

      setBaseMetabolicRate(tmpBaseMetabolicRate);
      setOverallMetabolicRate(tmpOverallMetabolicRate);
      setTargetCalories(tmpTargetCalories);
    }
  }, [gender, height, age, weight, activity, goal, goalWeight]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      sendTrackingPixel('view-mealplan-page-home');
    } else {
      sendTrackingPixel('view-mealplan-page-article');
    }
  }, []);

  const handleSetUserData = () => {
    sendTrackingPixel('goal-' + goal);
    sendTrackingPixel('gender-' + gender);
    sendTrackingPixel('nutrition-' + selectedNutrition);
    sendTrackingPixel('diet-' + selectedDiet);

    if (window.location.pathname === '/') {
      sendTrackingPixel('generate-mealplan-page-home');
    } else {
      sendTrackingPixel('generate-mealplan-page-article');
    }

    let goalTitleString = 'Fit werden';
    if (goal == USER_GOAL_BUILD_MUSCLE) {
      goalTitleString = 'Muskelaufbau';
    }
    if (goal == USER_GOAL_LOSE_WEIGHT) {
      goalTitleString = 'Abnehmen';
    }

    let nutritionTitleString = 'Mischkost';
    if (nutrition == NUTRITION_PESCETARIEN) {
      nutritionTitleString = 'Pescetarisch';
    }
    if (nutrition == NUTRITION_VEGETARIAN) {
      nutritionTitleString = 'Vegetarisch';
    }
    if (nutrition == NUTRITION_VEGETARIAN) {
      nutritionTitleString = 'Vegan';
    }

    let dietTitleString;
    if (selectedDiet == DIET_HIGH_PROTEIN) {
      dietTitleString = 'High Protein';
    }
    if (selectedDiet == DIET_LOW_CARB) {
      dietTitleString = 'Low Carb';
    }
    if (selectedDiet == DIET_SLOW_CARB) {
      dietTitleString = 'Slow Carb';
    }
    if (selectedDiet == DIET_KETO) {
      dietTitleString = 'Keto';
    }
    if (selectedDiet == DIET_MEDITERRANEAN) {
      dietTitleString = 'Mediterranes';
    }

    /*window.location.href = 'https://nutritastic.de/checkout/';*/
    window.location.href = `${URL_NUTRITASTIC_DE}checkout-mealplan.php?title=${goalTitleString}&age=${Number(
      age,
    )}&weight=${Number(weight)}&goalWeight=${Number(goalWeight)}&calories=${Number(
      targetCalories,
    )}&height=${Number(height)}&diet=${nutritionTitleString}&gender=${gender}`;
  };

  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  return (
    <StyledLandingPage ref={myRef}>
      <div id="landing-page-container">
        {isError && (
          <div className="container-fluid" id="mealplanconfig-error">
            <div className="form-error">
              Bitte korrigiere die roten Felder, damit wir deinen Ernährungsplan berechnen können.
            </div>
          </div>
        )}

        {!isMealPlanCreated && !isLoading && (
          <section className="container-fluid" id="mealplanconfig">
            <div className="row mt-0">
              <div className="col-12">
                <div className="calculator-step">
                  <div className="line-dot">
                    <div className="dot">1</div>
                  </div>
                  <h4 className="mb-0 mb-md-32">Wie möchtest du dich ernähren?</h4>
                  <div className="mt-16 d-flex w-100 p-4 flex-wrap flex-wrap flex-md-nowrap">
                    {diets.map((diet, index) => (
                      <StyledNutritionButton
                        key={`diet-${index}`}
                        onClick={() => {
                          setSelectedDiet(diet);
                          handleUserSetDiet(diet);
                        }}
                        className={selectedDiet === diet ? 'is-selected is-big' : 'is-big'}
                      >
                        <img src={`${ASSETS_URL}${diet}.png`} alt="" />
                        <span>{t(diet)}</span>
                      </StyledNutritionButton>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-0">
              <div className="col-12">
                <div className="calculator-step p-4">
                  <div className="line-dot">
                    <div className="dot">2</div>
                  </div>
                  <h4>Dein Profil</h4>
                  <div className="w-100">
                    <div className="row mb-4">
                      <label
                        className="landing-label col-12 col-md-5 col-form-label"
                        htmlFor="nutrition_selector"
                      >
                        Ernährungsform
                      </label>
                      <div className="col-12 col-md-3">
                        <select
                          className="form-control"
                          id="nutrition_selector"
                          defaultValue={selectedNutrition}
                          onChange={(e) => {
                            setSelectedNutrition(e.target.value);
                          }}
                        >
                          <option value={NUTRITION_ANYTHING}>{t('NUTRITION_ANYTHING')}</option>
                          <option value={NUTRITION_PESCETARIEN}>
                            {t('NUTRITION_PESCETARIEN')}
                          </option>
                          <option value={NUTRITION_VEGETARIAN}>{t('NUTRITION_VEGETARIAN')}</option>
                          <option value={NUTRITION_VEGAN}>{t('NUTRITION_VEGAN')}</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        className="landing-label col-12 col-md-5 col-form-label"
                        htmlFor="nutrition_selector"
                      >
                        Geschlecht
                      </label>
                      <div className="col-12 col-md-3">
                        <select
                          className="form-control"
                          id="nutrition_selector"
                          defaultValue={USER_GENDER_FEMALE}
                          onChange={(e) => {
                            setGender(e.target.value);
                          }}
                        >
                          <option value={USER_GENDER_FEMALE}>Frau</option>
                          <option value={USER_GENDER_MALE}>Mann</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        className="landing-label col-12 col-md-5 col-form-label"
                        htmlFor="cal_input"
                      >
                        Alter
                      </label>
                      <div className="col-12 col-md-3 calorie-input">
                        <input
                          type="number"
                          id="calculator-step-height-cm"
                          value={age}
                          onChange={(e) => {
                            setAge(e.target.value);
                          }}
                          pattern="[0-9]*"
                          inputMode="number"
                          placeholder="34"
                          min="14"
                          className={`${isErrorAge ? 'has-error' : ''}`}
                        />
                        <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                          Jahre
                        </label>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        className="landing-label col-12 col-md-5 col-form-label"
                        htmlFor="cal_input"
                      >
                        Körpergröße
                      </label>
                      <div className="col-12 col-md-3 calorie-input">
                        <input
                          type="number"
                          id="calculator-step-height-cm"
                          value={height}
                          onChange={(e) => {
                            setHeight(e.target.value);
                          }}
                          pattern="[0-9]*"
                          inputMode="number"
                          placeholder="180"
                          min="120"
                          max="280"
                          className={`${isErrorHeight ? 'has-error' : ''}`}
                        />
                        <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                          cm
                        </label>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        className="landing-label col-12 col-md-5 col-form-label"
                        htmlFor="cal_input"
                      >
                        Gewicht
                      </label>
                      <div className="col-12 col-md-3 calorie-input">
                        <input
                          type="number"
                          id="calculator-step-height-cm"
                          value={weight}
                          onChange={(e) => {
                            setWeight(e.target.value);
                          }}
                          pattern="[0-9]*"
                          inputMode="number"
                          placeholder="70"
                          min="40"
                          max="180"
                          className={`${isErrorWeight ? 'has-error' : ''}`}
                        />
                        <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                          kg
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="calculator-step" id="calculator-step-5">
                  <div className="line-dot">
                    <div className="dot">3</div>
                  </div>
                  <h4 className="mb-0 mb-md-32">Wie aktiv bist du im Alltag?</h4>
                  <div className="row p-4">
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.1 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.1);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-sitting-on-chair.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle mb-2">Geringfügig</div>
                      <div className="daily-activity-item-description centered">
                        Überwiegend sitzend (z. B. Büroangestellte:r)
                      </div>
                    </div>
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.3 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.3);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-stand-up.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle  mb-2">Mäßig</div>
                      <div className="daily-activity-item-description centered">
                        Meistens stehend (z. B. Lehrer:in, Kassierer:in)
                      </div>
                    </div>
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.5 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.5);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-walking.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle  mb-2">Aktiv</div>
                      <div className="daily-activity-item-description centered">
                        Meistens zu Fuß (z. B. Verkauf, Kellner:in)
                      </div>
                    </div>
                    <div
                      className={`activity-level-box col-6 col-md-3 ${
                        activity === 1.7 ? 'is-selected' : ''
                      }`}
                      onClick={() => {
                        setActivity(1.7);
                      }}
                    >
                      <img
                        className="icon-svg small"
                        src={`${ASSETS_URL}icon-manual-handling.svg`}
                        alt="Lightly active"
                        type="image/svg+xml"
                      />
                      <div className="choice-btn grey font-subtitle mb-2">Sehr aktiv</div>
                      <div className="daily-activity-item-description centered">
                        Körperlich anstrengende Tätigkeit (z. B. Bauarbeiter:in)
                      </div>
                    </div>
                  </div>
                </div>

                <div className="calculator-step p-4">
                  <div className="line-dot">
                    <div className="dot">4</div>
                  </div>
                  <h4>Deine Ziele</h4>
                  <div className="w-100">
                    <div className="row mb-4">
                      <label
                        className="landing-label col-12 col-md-5 col-form-label"
                        htmlFor="nutrition_selector"
                      >
                        Ziel
                      </label>
                      <div className="col-12 col-md-3">
                        <select
                          className="form-control"
                          id="nutrition_selector"
                          defaultValue={selectedNutrition}
                          onChange={(e) => {
                            setGoal(e.target.value);
                          }}
                        >
                          <option value={USER_GOAL_BUILD_MUSCLE}>Muskelaufbau</option>
                          <option value={USER_GOAL_LOSE_WEIGHT}>Abnehmen</option>
                          <option value={USER_GOAL_MAINTAIN}>Fit werden</option>
                        </select>
                      </div>
                    </div>

                    <div className="row mb-4">
                      <label
                        className="landing-label col-12 col-md-5 col-form-label"
                        htmlFor="cal_input"
                      >
                        Wunschgewicht
                      </label>
                      <div className="col-12 col-md-3 calorie-input">
                        <input
                          type="number"
                          id="calculator-step-height-cm"
                          value={goalWeight}
                          onChange={(e) => {
                            setGoalWeight(e.target.value);
                          }}
                          pattern="[0-9]*"
                          inputMode="number"
                          placeholder="65"
                          min="40"
                          max="180"
                          className={`${isErrorGoalWeight ? 'has-error' : ''}`}
                        />
                        <label className="label invalid-highlight" htmlFor="step4[height_cm]">
                          kg
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 form-group mb-4">
              <div className="text-center">
                <RedButton
                  text={'Ernährungsplan berechnen'}
                  disabled={
                    !height ||
                    !age ||
                    !weight ||
                    !goalWeight ||
                    Number(goalWeight) == Number(weight)
                  }
                  disabledClick={() => {
                    setIsError(true);
                    if (!age) {
                      setIsErrorAge(true);
                    }
                    if (!height) {
                      setIsErrorHeight(true);
                    }
                    if (!weight) {
                      setIsErrorWeight(true);
                    }
                    if (!weight) {
                      setIsErrorWeight(true);
                    }
                    if (!goalWeight) {
                      setIsErrorGoalWeight(true);
                    }

                    setTimeout(() => {
                      executeScroll();
                    }, 100);
                  }}
                  onClick={() => {
                    setIsLoading(true);
                    setTimeout(() => {
                      executeScroll();
                    }, 100);

                    setTimeout(() => {
                      handleSetUserData();
                    }, 3000);
                  }}
                ></RedButton>
              </div>
            </div>
          </section>
        )}

        {isLoading && (
          <div className="container-fluid w-100">
            <div className="row">
              <div className="col-12">
                <LoadingState
                  title={'Einen Moment'}
                  text={'Wir berechnen deinen Ernährungsplan'}
                ></LoadingState>
              </div>
            </div>
          </div>
        )}

        {isMealPlanCreated && !isLoading && (
          <div className="landingpage-meals" id="mealplan">
            {userData && userData.mealPlan && userData.mealPlan[0].meals.length > 0 && (
              <>
                <div className="day-calorie-overview">
                  <DayCalorieOverview
                    day={userData.mealPlan[0]}
                    currentDayIndex={0}
                  ></DayCalorieOverview>
                </div>

                <StyledMealPlanDay key={`dayindex-${dayIndex}`}>
                  {userData.mealPlan[0].meals.slice(0, 2).map((meal, mealIndex) => (
                    <div
                      className={`next-meals-wrapper ${mealIndex > 0 ? 'is-visible' : ''}`}
                      key={`dayindex-${dayIndex}-mealindex=${mealIndex}`}
                    >
                      <DemoMeal
                        dayIndex={0}
                        mealIndex={mealIndex}
                        meal={meal}
                        showFull={mealIndex === 0 ? true : false}
                      ></DemoMeal>

                      {mealIndex > 0 && (
                        <div className="next-meals">
                          <div className="next-meals-title">
                            <h4>
                              Starte dein Ernährungsplan ohne Kochen.
                              <br /> 5 Zutaten & 15 Minuten pro Mahlzeit. Garantiert!
                            </h4>
                            {/*<ul>
                            <li>Nur 5 Zutaten & 5 Minuten Arbeitsaufwand: spart Zeit und Geld</li>
                            <li>Ohne Kochen & Mit einfachen Mengenangaben, z.B. Esslöffel</li>
                            <li>
                              Unterstützt beim Muskelaufbau & Abnehmen, egal ob Low Carb, Keto, High
                              Protein, Vegan oder anderen Ernährungsformen
                            </li>
                          </ul>*/}

                            <div
                              className="text-center"
                              style={{ maxWidth: '400px', margin: '0 auto' }}
                            >
                              <RedButton
                                text={'Jetzt 7 Tage kostenlos testen'}
                                onClick={() => {
                                  if (showCheckoutButton) {
                                    sendTrackingPixel('landing-page-meal-plan-try-now');
                                    window.location.href = URL_NUTRITASTIC_DE + 'checkout/';
                                  } else {
                                    sendTrackingPixel('checkout-page-meal-plan-try-now');
                                    window.location.href = `${
                                      window.location.origin + window.location.pathname
                                    }#checkout-page-container`;
                                  }
                                }}
                              ></RedButton>
                            </div>

                            <div className="mt-4">
                              <InlineButton
                                text={'Nochmal einmal Berechnen'}
                                onClick={() => {
                                  setIsMealPlanCreated(false);
                                  sendTrackingPixel('landing-page-meal-plan-again');
                                  setTimeout(() => {
                                    window.location.href = `${
                                      window.location.origin + window.location.pathname
                                    }#landing-page-container`;
                                  }, 100);
                                }}
                              ></InlineButton>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </StyledMealPlanDay>
              </>
            )}
          </div>
        )}

        <Alert
          isOpen={showCalorieCalculator}
          title="Kalorienbedarf berechnen"
          onClose={() => setShowCalorieCalculator(false)}
        >
          <div className="m-2">
            <CalorieCalculator
              handleSelectCalories={(calories) => {
                setTargetCalories(calories);
                setShowCalorieCalculator(false);
              }}
            ></CalorieCalculator>
          </div>
        </Alert>
      </div>
    </StyledLandingPage>
  );
}
