import { APIContextProvider } from './context/APIcontext';
import React from 'react';

import LandingPage from './pages/LandingPage/LandingPage';
import OverviewPage from './pages/OverviewPage/OverviewPage';
import CalorieCalculator from './components/CalorieCalculator';
import FakeOnboardingPage from 'pages/OnboardingPage/FakeOnboarding';

function App({ data }) {
  return (
    <>
      {data.module === 'landing-page' && (
        <APIContextProvider>
          <LandingPage showCheckoutButton={true}></LandingPage>
        </APIContextProvider>
      )}
      {data.module === 'checkout-page' && (
        <APIContextProvider>
          <LandingPage showCheckoutButton={false}></LandingPage>
        </APIContextProvider>
      )}
      {data.module === 'calorie-calculator' && <CalorieCalculator />}
      {data.module === 'onboarding' && <FakeOnboardingPage />}
      {data.module === 'meal-plan' && data.userId && data.userDisplayName && (
        <APIContextProvider userID={data.userId} userDisplayName={data.userDisplayName}>
          <OverviewPage></OverviewPage>
        </APIContextProvider>
      )}
    </>
  );
}

export default App;
