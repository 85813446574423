import React, { useState } from 'react';
import styled from 'styled-components';
import Topline from 'components/Typo/Topline';
import Headline from 'components/Typo/Headline';
import ActionCard from 'components/ActionCard';

const StyledSaveMealPlanTemplate = styled.div`
  padding: 40px;

  .input-label-wrapper {
    position: relative;

    .input {
      -webkit-appearance: none;
      width: 80%;
      border: 0;
      font-family: inherit;
      padding: 12px;
      height: 48px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #37474f;
      border-bottom: 2px solid #37474f;
      background: 0 0;
      border-radius: 0;
      line-height: 1.18;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .input-label-wrapper {
    text-align: center;
  }
`;

export default function SaveMealPlanTemplate({ onChange, currentTitle }) {
  const [mealplanName, setMealplanName] = useState(currentTitle || '');

  return (
    <StyledSaveMealPlanTemplate>
      <div className="text-center">
        <Topline>Ernährungsplan speichern</Topline>
        <Headline>Gib deinem Ernährungsplan einen Namen</Headline>
      </div>

      <div className="input-label-wrapper">
        <input
          type="text"
          className="input dual-number js-input-field invalid-highlight js-max-length focused"
          placeholder={'Name des Ernährungsplans'}
          id="setmealplanname"
          name="setmealplanname"
          defaultValue={mealplanName}
          onChange={(e) => {
            setMealplanName(e.target.value);
            onChange(e.target.value);
          }}
        />
      </div>
    </StyledSaveMealPlanTemplate>
  );
}
