import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import SectionHeader from 'components/Typo/SectionHeader';
import { useAPI } from 'context/APIcontext';
import { useTranslation } from 'react-i18next';
import StyledSlider from 'components/StyledSlider';
import RecipeCard from 'components/RecipeCard';
import { useWindowSize } from 'hooks/useWindowsResize';
import Alert from 'components/Alert/Alert';
import RecipePage from 'pages/RecipePage/RecipePage';

const StyledCookBookRecommandationsPage = styled.div``;

export default function CookBookRecommandationsPage() {
  const { dbData } = useAPI();
  const { t } = useTranslation('common');
  const size = useWindowSize();

  const { recipes } = dbData;

  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [breakfastRecipes, setBreakfastRecipes] = useState([]);
  const [lunchRecipes, setLunchRecipes] = useState([]);
  const [dinnerRecipes, setDinnerRecipes] = useState([]);
  const [snackRecipes, setSnackRecipest] = useState([]);
  const [dessertRecipes, setDessertRecipes] = useState([]);
  const [shakeRecipes, setShakeRecipes] = useState([]);
  const [breadRecipes, setBreadRecipes] = useState([]);
  const [quickRecipes, setQQuickRecipes] = useState([]);
  const [littleIngredients, setLittleIngredients] = useState([]);
  const [recommendedRecipes, setRecommendedRecipes] = useState([]);

  const [forJob, setForJob] = useState([]);

  const [slideSettings, setSlideSettings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth > 800 ? 2.5 : 1.2,
    slidesToScroll: 1,
    arrows: false,
  });

  const currentHour = new Date().getHours();

  useEffect(() => {
    let newSliderSettings = { ...slideSettings };
    newSliderSettings.slidesToShow = window.innerWidth > 800 ? 3 : 1.4;
    setSlideSettings(newSliderSettings);
  }, [size]);

  useEffect(() => {
    setBreakfastRecipes(
      recipes
        .filter(
          (recipe) => recipe.category.includes('breakfast') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setLunchRecipes(
      recipes
        .filter(
          (recipe) => recipe.category.includes('lunch') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setDinnerRecipes(
      recipes
        .filter(
          (recipe) => recipe.category.includes('dinner') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setSnackRecipest(
      recipes
        .filter(
          (recipe) => recipe.category.includes('snacks') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setDessertRecipes(
      recipes
        .filter(
          (recipe) => recipe.category.includes('desserts') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setShakeRecipes(
      recipes
        .filter(
          (recipe) => recipe.category.includes('shake') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setBreadRecipes(
      shuffleArray(recipes.filter((recipe) => recipe.category.includes('selfmade-bread'))),
    );

    setQQuickRecipes(
      recipes
        .filter(
          (recipe) =>
            recipe.category.includes('quickly-prepared') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setLittleIngredients(
      recipes
        .filter(
          (recipe) =>
            recipe.category.includes('5-ingredients') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    setForJob(
      recipes
        .filter(
          (recipe) =>
            recipe.category.includes('for-your-job') && Number(recipe.favorite_count) > 9000,
        )
        .slice(0, 10),
    );

    let searchTerm = 'snacks';
    if (Number(currentHour) <= 4) {
      searchTerm = 'snacks';
    }
    if (Number(currentHour) >= 5 && Number(currentHour) <= 11) {
      searchTerm = 'breakfast';
    }
    if (Number(currentHour) >= 12 && Number(currentHour) <= 15) {
      searchTerm = 'lunch';
    }
    if (Number(currentHour) >= 15 && Number(currentHour) <= 17) {
      searchTerm = 'snacks';
    }
    if (Number(currentHour) >= 18) {
      searchTerm = 'dinner';
    }

    setRecommendedRecipes(
      recipes
        .filter((recipe) => {
          return recipe.category.includes(searchTerm) && Number(recipe.favorite_count) > 9000;
        })
        .slice(0, 10),
    );
  }, []);

  function shuffleArray(arr) {
    return arr
      .map((a) => [Math.random(), a])
      .sort((a, b) => a[0] - b[0])
      .map((a) => a[1]);
  }

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  return (
    <StyledCookBookRecommandationsPage>
      <SectionHeader className="mt-4">
        {currentHour <= 4 && t('cookbook.snack-recommendation')}
        {currentHour >= 5 && currentHour <= 11 ? t('cookbook.breakfast-recommendation') : null}
        {currentHour >= 12 && currentHour <= 15 ? t('cookbook.lunch-recommendation') : null}
        {currentHour >= 15 && currentHour <= 17 ? t('cookbook.snack-recommendation') : null}
        {currentHour >= 18 && t('cookbook.dinner-recommendation')}
      </SectionHeader>

      <StyledSlider {...slideSettings}>
        {recommendedRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`recommendedRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Frühstück</SectionHeader>

      <StyledSlider {...slideSettings}>
        {breakfastRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`breakfastRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Mittagessen</SectionHeader>

      <StyledSlider {...slideSettings}>
        {lunchRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`lunchRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Snacks</SectionHeader>

      <StyledSlider {...slideSettings}>
        {snackRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`snackRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Abendessen</SectionHeader>

      <StyledSlider {...slideSettings}>
        {dinnerRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`dinnerRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Selbstgemachtes Brot</SectionHeader>

      <StyledSlider {...slideSettings}>
        {breadRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`breadRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>5 Zutaten oder weniger</SectionHeader>

      <StyledSlider {...slideSettings}>
        {littleIngredients.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`littleIngredients-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Fürs Büro</SectionHeader>

      <StyledSlider {...slideSettings}>
        {forJob.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`forJob-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Shakes</SectionHeader>

      <StyledSlider {...slideSettings}>
        {shakeRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`shakeRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Schnelle Rezepte</SectionHeader>

      <StyledSlider {...slideSettings}>
        {quickRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`quickRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      <SectionHeader>Gesunde Desserts</SectionHeader>

      <StyledSlider {...slideSettings}>
        {dessertRecipes.map((recipe, recipeIndex) => (
          <div className="pe-3" key={`dessertRecipes-${recipeIndex}`}>
            <RecipeCard
              recipe={recipe}
              index={recipeIndex}
              onClick={() => {
                handleRecipeClick(recipe);
              }}
            ></RecipeCard>
          </div>
        ))}
      </StyledSlider>

      {selectedRecipe && (
        <Alert isOpen={true} title={selectedRecipe.title} onClose={() => setSelectedRecipe(null)}>
          <RecipePage recipe={selectedRecipe} onClose={() => setSelectedRecipe(null)}></RecipePage>
        </Alert>
      )}
    </StyledCookBookRecommandationsPage>
  );
}
