import {
  MEAL_PLAN_DATE_FORMAT,
  MEAL_PLAN_DAYS,
  MEAL_TYPE_MIX_MATCH,
} from 'constants/mealPlanConstants';
import { DIET_ANYTHING, DIET_SLOW_CARB, NUTRITION_ANYTHING } from 'constants/userMetrics';
import dayjs from 'dayjs';
import { enhanceMixAndMatchItems } from 'helper/ingredientDetailHelper';
import React, { useContext, useState, useEffect, createContext, useRef } from 'react';
import {
  addIngredient,
  createMealSlot,
  fillMealPlanDays,
  generateMeal,
  generateFixedMeal,
  generateMealPlanDay,
  replaceIngredient,
  replaceMeal,
  rescaleMealPlanDay,
} from '../helper/mealPlanHelper';

const APIContext = createContext();

export function APIContextProvider({ children, userID, userDisplayName }) {
  const firstRenderRef = useRef(true);
  const [userDataLoaded, setUserDataLoaded] = useState(false);
  const [mixMatchDataLoaded, setMixMatchDataLoaded] = useState(false);
  const [fullyInitialized, setFullyInitialized] = useState(false);
  const [dbData, setDBData] = useState(null);

  const [mixAndMatchChoices, setMixAndMatchChoices] = useState([]);
  const [userData, setUserData] = useState();

  // https://nutritastic.de/api-mix-and-match/
  // https://low-carb-kitchen.de/database/api-de-mix-and-match/
  // http://localhost:3000/api.json
  useEffect(() => {
    const fetchData = () => {
      if (userID && userDisplayName) {
        fetch(`https://nutritastic.de/api-user/?user_id=${userID}&display_name=${userDisplayName}`)
          .then((response) => response.json())
          .then((userData) => {
            updateUserData(userData);
          });
      } else {
        setUserData({
          profile: {
            gender: '',
            mealsAmount: Number(3),
            goal: '', // Maintain, Build Muscle
            preferedUnits: '', //U.S. Standard
            height: Number(0),
            weight: Number(0),
            age: Number(0),
            activityLevel: Number(0), // light active, moderate active, very active, extra active
            activeWeightGoal: true, // true, false
            diet: DIET_ANYTHING, // anything, low-carb, keto, mediterranean, paleo, slow-carb, high-protein
            nutrition: NUTRITION_ANYTHING, // anything, pescetarien, vegan, vegetarian
            baseMetabolicRate: Number(0),
            overallMetabolicRate: Number(0),
            overwriteCalories: false,
            targetCalories: Number(0),
            manualCalories: Number(0),
            useHalfUnits: false,
          },
          mealPlan: [{ meals: [], targetCalories: 0 }],
        });
      }

      if (userID && userDisplayName) {
        fetch(`https://low-carb-kitchen.de/database/data/de_app_data.json`)
          .then((response) => response.json())
          .then((apiData) => {
            fetch('https://low-carb-kitchen.de/database/data/de_mix_and_match_static.json') //http://localhost:3000/api.json
              .then((response) => response.json())
              .then((data) => {
                setDBData(enhanceRecipesData(apiData));
                enhanceMixAndMatchItems(data).then((enhancedMixAndMatchData) => {
                  setMixAndMatchChoices(enhancedMixAndMatchData, setMixMatchDataLoaded(true));
                });
              })
              .catch((error) => {
                console.log('An error occured', error);
              });
          });
      } else {
        fetch('https://low-carb-kitchen.de/database/data/de_mix_and_match_static.json') //http://localhost:3000/api.json
          .then((response) => response.json())
          .then((data) => {
            enhanceMixAndMatchItems(data).then((enhancedMixAndMatchData) => {
              setMixAndMatchChoices(enhancedMixAndMatchData, setMixMatchDataLoaded(true));
            });
          })
          .catch((error) => {
            console.log('An error occured', error);
          });
      }
    };
    /*
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }*/
    fetchData();
  }, []);

  const enhanceRecipesData = (data) => {
    data.recipes.forEach((recipe) => {
      data.recipes = data.recipes.filter((r) => {
        return Number(r.carbs) <= 50;
      });

      // append categorys
      if (recipe.carbs <= 15) {
        recipe.category.push('keto');
      }

      if (recipe.carbs > 30) {
        recipe.category.push('slow-carbs');
      }

      if (recipe.carbs <= 30) {
        recipe.category.push('low-carb');
      }

      if (recipe.protein >= 15) {
        recipe.category.push('high-protein');
      }

      if (recipe.ingredients.length < 7) {
        recipe.category.push('5-ingredients');
      }

      recipe.ingredients.forEach((ingredient) => {
        ingredient.title = ingredient.ingredient;
      });

      recipe.ingredients.forEach((ingredient) => {
        ingredient.title = ingredient.ingredient;
      });
    });

    return data;
  };

  const updateUserData = (userData) => {
    if (!fullyInitialized) {
      // Update meal plan

      let newUserData = { ...userData };
      if (newUserData && newUserData.mealPlan) {
        //newUserData.mealPlan = [];
        //saveUserData(newUserData);

        let { mealPlan } = newUserData;

        let today = dayjs().format(MEAL_PLAN_DATE_FORMAT);

        let calculatedDayIndex = mealPlan.findIndex((day) => {
          return day.date === today;
        });

        if (calculatedDayIndex > 0) {
          //console.log('ToDo: Move to old meal plan items', calculatedDayIndex);
          let oldMealPlan = mealPlan.slice(0, calculatedDayIndex + 1);

          if (!newUserData.previousMealPlanDays) {
            newUserData.previousMealPlanDays = [];
          }

          newUserData.previousMealPlanDays = [...newUserData.previousMealPlanDays, ...oldMealPlan]
            .reverse()
            .slice(0, 20);

          mealPlan.splice(0, calculatedDayIndex);
        }

        if (calculatedDayIndex === -1) {
          mealPlan = [];
        }

        if (mealPlan.length <= MEAL_PLAN_DAYS) {
          newUserData.mealPlan = fillMealPlanDays(mealPlan, userData.targetCalories);
          saveUserData(newUserData);
        } else {
        }
      }

      setUserData(newUserData, setUserDataLoaded(true));
    }
  };

  const removeMealPlanDay = (dayIndex) => {
    let state = { ...userData };
    state.mealPlan.splice(dayIndex, 1);
    saveUserData(state);
    setUserData(state);
  };

  const deleteAllMealsFromDay = (dayIndex) => {
    let state = { ...userData };
    state.mealPlan[dayIndex].meals = [];
    state.mealPlan[dayIndex].targetCalories = userData.profile.targetCalories;
    saveUserData(state);
    setUserData(state);
  };

  const handleReplaceIngredient = (
    dayIndex,
    mealIndex,
    stepIndex,
    ingredientIndex,
    newIngredientIndex,
  ) => {
    let state = { ...userData };

    state.mealPlan[dayIndex].meals[mealIndex].detail.steps.map((step) => {
      step.selectedIngredient[0].amount = step.selectedIngredient[0].defaultAmount;
    });

    state.mealPlan[dayIndex].meals[mealIndex].detail = replaceIngredient(
      state.mealPlan[dayIndex].meals[mealIndex].detail,
      stepIndex,
      ingredientIndex,
      newIngredientIndex,
      userData.profile.useHalfUnits,
    );

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const handleAddIngredient = (dayIndex, mealIndex, stepIndex) => {
    let state = { ...userData };
    let meal = state.mealPlan[dayIndex].meals[mealIndex].detail;

    state.mealPlan[dayIndex].meals[mealIndex].detail.steps.map((step) => {
      step.selectedIngredient[0].amount = step.selectedIngredient[0].defaultAmount;
    });

    const newMeal = addIngredient(meal, stepIndex, state.profile.useHalfUnits);
    state.mealPlan[dayIndex].meals[mealIndex].detail = newMeal;

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const handleRemoveIngredient = (dayIndex, mealIndex, stepIndex, ingredientIndex) => {
    let state = { ...userData };
    let meal = state.mealPlan[dayIndex].meals[mealIndex].detail;

    meal.steps.map((step) => {
      step.selectedIngredient[0].amount = step.selectedIngredient[0].defaultAmount;
    });

    meal.steps[stepIndex].selectedIngredient.splice(ingredientIndex, 1);

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const handleReplaceMeal = (dayIndex, mealIndex) => {
    let state = { ...userData };

    const currentID = state.mealPlan[dayIndex].meals[mealIndex].detail.id;
    let meal;

    do {
      meal = replaceMeal(mixAndMatchChoices, userData.profile.diet, userData.profile.nutrition);
    } while (meal.id === currentID);

    state.mealPlan[dayIndex].meals[mealIndex].detail = meal;

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const handleAddMealToDay = (dayIndex) => {
    let state = { ...userData };

    const mealSlot = createMealSlot(
      mixAndMatchChoices,
      state.profile.diet,
      state.profile.nutrition,
      state.mealPlan[dayIndex].targetCalories,
      state.mealPlan[dayIndex].meals.length,
      state.profile.useHalfUnits,
    );

    state.mealPlan[dayIndex].meals.push(mealSlot);

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const markMealAsDone = (dayIndex, mealIndex) => {
    let state = { ...userData };
    state.mealPlan[dayIndex].meals[mealIndex].isDone = true;
    saveUserData(state);
    setUserData(state);
  };

  const removeMealFromDay = (dayIndex, mealIndex) => {
    let state = { ...userData };
    state.mealPlan[dayIndex].meals.splice(mealIndex, 1);

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const duplicateMealFromDay = (dayIndex, mealIndex) => {
    let state = { ...userData };

    let meal = Object.assign({}, state.mealPlan[dayIndex].meals[mealIndex]);
    state.mealPlan[dayIndex].meals.splice(mealIndex, 0, meal);

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const changeDayCalories = (dayIndex, calories) => {
    let state = { ...userData };

    state.mealPlan[dayIndex].targetCalories = calories;

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const changeDayMealsAmont = (dayIndex, amountOfMeals) => {
    setUserData({ ...userData }, (userData.mealPlan[dayIndex].mealsAmount = amountOfMeals));
  };

  const handleGenerateDay = (dayIndex, calories, meals) => {
    let state = { ...userData };

    const mealPlan = generateMealPlanDay(
      mixAndMatchChoices,
      calories,
      meals,
      userData.profile.diet,
      userData.profile.nutrition,
      userData.profile.useHalfUnits,
    );
    state.mealPlan[dayIndex].targetCalories = calories;
    state.mealPlan[dayIndex].meals = mealPlan;

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const handleGenerateMealPlan = (dayIndex, calories, mealsAmount) => {
    let state = { ...userData };
    const mealPlan = generateMealPlanDay(
      mixAndMatchChoices,
      calories,
      mealsAmount,
      userData.profile.diet,
      userData.profile.nutrition,
      userData.profile.useHalfUnits,
    );

    state.mealPlan[dayIndex].meals = mealPlan;
    state.mealPlan[dayIndex].targetCalories = calories;

    setUserData(state);
  };

  const handleUserSetDiet = (diet) => {
    setUserData({ ...userData }, (userData.profile.diet = diet));
  };

  const handleUserSetNutrition = (nutrition) => {
    setUserData({ ...userData }, (userData.profile.nutrition = nutrition));
  };

  const saveUserData = (userData) => {
    if (userID && userDisplayName) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      };

      fetch(
        `https://nutritastic.de/api-user/?user_id=${userID}&display_name=${userDisplayName}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('data saved: ', data);
          }
        });
    }
  };

  const handleAddToTemplates = (mealPlan, mealPlanTitle) => {
    let state = { ...userData };
    if (!state.templates) {
      state.templates = [];
    }

    mealPlan.title = mealPlanTitle;
    state.templates.push(mealPlan);
    setUserData(state);
    saveUserData(state);
  };

  const handleDeleteTemplate = (templateIndex) => {
    let state = { ...userData };
    state.templates.splice(templateIndex, 1);
    setUserData(state);
    saveUserData(state);
  };

  const handleRenameTemplate = (templateIndex, title) => {
    let state = { ...userData };
    state.templates[templateIndex].title = title;
    setUserData(state);
    saveUserData(state);
  };

  const handlePasteTemplate = (dayIndex, mealPlan) => {
    let state = { ...userData };
    state.mealPlan[dayIndex].meals = mealPlan.meals;
    state.mealPlan[dayIndex].recipes = mealPlan.recipes;
    state.mealPlan[dayIndex].targetCalories = mealPlan.recipes;
    setUserData(state);
    saveUserData(state);
  };

  const handleAddRecipeToDay = (dayIndex, recipe) => {
    let state = { ...userData };

    recipe.selectedServing = 1;

    state.mealPlan[dayIndex].recipes.push(recipe);

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const handleRemoveRecipeFromDay = (dayIndex, recipeIndex) => {
    let state = { ...userData };
    let recipe = state.mealPlan[dayIndex].recipes[recipeIndex];

    state.mealPlan[dayIndex].recipes.splice(recipeIndex, 1);

    state.mealPlan[dayIndex].meals = rescaleMealPlanDay(
      state.mealPlan[dayIndex].recipes,
      state.mealPlan[dayIndex].meals,
      state.mealPlan[dayIndex].targetCalories,
      state.profile.useHalfUnits,
    );

    setUserData(state, saveUserData(state));
  };

  const endProgram = () => {};

  const setMealSlotTitle = (dayIndex, mealIndex, event) => {
    let state = { ...userData };
    state.mealPlan[dayIndex].meals[mealIndex].slotTitle = event.target.value;
    setUserData(state, saveUserData(state));
  };

  const handleToggleHalfUnits = (checked) => {
    let state = { ...userData };
    state.profile.useHalfUnits = checked;

    state.mealPlan.forEach((day) => {
      day.meals = rescaleMealPlanDay(day.recipes, day.meals, day.targetCalories, checked);
    });

    setUserData(state, saveUserData(state));
  };

  const handleRecipeServingsDecrease = (dayIndex, recipeIndex) => {
    let state = { ...userData };
    state.mealPlan[dayIndex].recipes[recipeIndex].selectedServing -= 1;

    state.mealPlan.forEach((day) => {
      day.meals = rescaleMealPlanDay(
        day.recipes,
        day.meals,
        day.targetCalories,
        state.profile.useHalfUnits,
      );
    });

    setUserData(state, saveUserData(state));
  };

  const handleRecipeServingsIncrease = (dayIndex, recipeIndex) => {
    let state = { ...userData };
    state.mealPlan[dayIndex].recipes[recipeIndex].selectedServing += 1;

    state.mealPlan.forEach((day) => {
      day.meals = rescaleMealPlanDay(
        day.recipes,
        day.meals,
        day.targetCalories,
        state.profile.useHalfUnits,
      );
    });

    setUserData(state, saveUserData(state));
  };

  const cancelProgram = () => {
    let state = { ...userData };
    state.mealPlan.forEach((day) => {
      day.day_title = null;
      day.day_text = null;
    });

    setUserData(state, saveUserData(state));
  };

  const deleteProfile = () => {
    setUserData('', saveUserData(''));
  };

  return (
    <APIContext.Provider
      value={{
        userData,
        mixAndMatchChoices,
        dbData,
        updateUserData,
        handleReplaceIngredient,
        changeDayCalories,
        changeDayMealsAmont,
        handleReplaceMeal,
        markMealAsDone,
        handleGenerateMealPlan,
        handleGenerateDay,
        setUserData,
        handleUserSetDiet,
        handleUserSetNutrition,
        saveUserData,
        duplicateMealFromDay,
        removeMealPlanDay,
        removeMealFromDay,
        deleteAllMealsFromDay,
        handleAddMealToDay,
        handleAddToTemplates,
        handleDeleteTemplate,
        handlePasteTemplate,
        handleRenameTemplate,
        handleAddIngredient,
        handleRemoveIngredient,
        handleAddRecipeToDay,
        handleRemoveRecipeFromDay,
        endProgram,
        setMealSlotTitle,
        handleToggleHalfUnits,
        handleRecipeServingsDecrease,
        handleRecipeServingsIncrease,
        cancelProgram,
        deleteProfile,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
