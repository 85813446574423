import React, { useEffect, useState } from 'react';
import { useAPI } from 'context/APIcontext';
import MealPlanTemplate from './Components/MealPlanTemplate';
import EmptyState from 'components/EmptyState';
import EmptyIcon from 'components/SVG/EmptyIcon';
import SectionHeader from 'components/Typo/SectionHeader';

export default function TemplatesPage() {
  const { userData } = useAPI();

  return (
    <>
      {!userData.templates || userData.templates.length === 0 ? (
        <EmptyState
          title={'Keine Vorlagen'}
          text={'Du hast noch keine Ernährungspläne als Vorlage gespeichert'}
          icon={<EmptyIcon></EmptyIcon>}
        ></EmptyState>
      ) : (
        <>
          <SectionHeader className="mt-4">Vorlagen</SectionHeader>

          {userData.templates.map((mealplan, mealplanIndex) => (
            <MealPlanTemplate
              mealplan={mealplan}
              mealplanIndex={mealplanIndex}
              key={`template-${mealplanIndex}`}
              isRenameActive={true}
            ></MealPlanTemplate>
          ))}
        </>
      )}
    </>
  );
}
