import React from 'react';
import styled from 'styled-components';

const StyledCTATransparent = styled.div`
  border: none;
  padding: 10px 0;
  text-decoration: none;
  border-radius: 8px;
  color: #000;
  transition: 0.1s all ease;
  font-weight: 700;
  font-size: 14px;
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
  //box-shadow: 0 0 5px rgb(0 0 0 / 20%);

  &.is-disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #03a9f4;
    color: #fff;
    text-decoration: none !important;
  }

  .icon {
    svg {
      width: 22px;
      height: 22px;
      margin: 0 auto;
    }
  }

  .text {
    margin-left: 8px;
  }

  @media (min-width: 768px) {
    padding: 12px 20px;
    font-size: 16px;
  }
`;

export default function CTATransparent({ text, onClick, disabled, icon, className }) {
  return (
    <StyledCTATransparent
      className={`${disabled ? 'is-disabled' : ''} ${className}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {icon && <span className="icon">{icon}</span>}
      {text && <div className="text">{text}</div>}
    </StyledCTATransparent>
  );
}
