export const USER_GOAL_LOSE_WEIGHT = 'LOSE_WEIGHT';
export const USER_GOAL_MAINTAIN = 'MAINTAIN';
export const USER_GOAL_BUILD_MUSCLE = 'BUILD_MUSCLE';
export const USER_GOAL_DEFINE = 'DEFINE';

export const USER_GENDER_MALE = 'MALE';
export const USER_GENDER_FEMALE = 'FEMALE';

export const USER_ACTIVITY_SEDENTARY = 'MALE';
export const USER_ACTIVITY_LIGHTLY_ACTIVE = 'FEMALE';
export const USER_ACTIVITY_MODERATELY_ACTIVE = 'MALE';
export const USER_ACTIVITY_VERY_ACTIVE = 'FEMALE';
export const USER_ACTIVITY_EXTREMELY_ACTIVE = 'MALE';

export const NUTRITION_ANYTHING = 'anything';
export const NUTRITION_PESCETARIEN = 'pescetarien';
export const NUTRITION_VEGAN = 'vegan';
export const NUTRITION_VEGETARIAN = 'vegetarian';

export const DIET_ANYTHING = 'anything';
export const DIET_KETO = 'keto';
export const DIET_LOW_CARB = 'low-carb';
export const DIET_MEDITERRANEAN = 'mediterranean';
export const DIET_PALEO = 'paleo';
export const DIET_SLOW_CARB = 'slow-carb';
export const DIET_HIGH_PROTEIN = 'high-protein';

export const ALLERGIES_NONE = 'none';
export const ALLERGIES_GLUTEN = 'gluten';
export const ALLERGIES_LACTOSE = 'lactose';
export const ALLERGIES_NUTS = 'nuts';
export const ALLERGIES_EGGS = 'eggs';
export const ALLERGIES_SHELLFISH = 'shellfish';
export const ALLERGIES_SOY = 'soy';
export const ALLERGIES_PEANUTS = 'peanuts';
export const ALLERGIES_FISH = 'fish';

export const USER_PREFERED_MEASUREMENT_SYSTEM_METRIC = 'metric';
export const USER_PREFERED_MEASUREMENT_SYSTEM_IMPERIAL = 'imperial';
