import React, { useEffect, useState } from 'react';

import DayCalorieOverview from './DayCalorieOverview';
import Meal from './Meal';
import styled from 'styled-components';
import { useAPI } from 'context/APIcontext';
import { ASSETS_URL } from 'constants/assets';
import NoMealsCard from './NoMealsCard';
import Topline from 'components/Typo/Topline';
import Headline from 'components/Typo/Headline';
import StyledCTAGrey from 'components/buttons/CTAGrey';
import MarkIcon from 'components/SVG/MarkIcon';
import NewIcon from 'components/SVG/NewIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import RecipeCard from 'components/RecipeCard';
import SectionHeader from 'components/Typo/SectionHeader';
import StyledSlider from 'components/StyledSlider';
import { useWindowSize } from 'hooks/useWindowsResize';
import Alert from 'components/Alert/Alert';
import SaveMealPlanTemplate from './SaveMealPlanTemplate';
import RecipePage from 'pages/RecipePage/RecipePage';
import { useTranslation } from 'react-i18next';
import ServingsControl from 'components/ServingsControl';
import MealPlanTable from './MealPlanTable';
import ToggleComponent from 'components/ToggleComponent';
import FakeLink from 'components/FakeLink';
import Joyride, { STATUS } from 'react-joyride';

const MEAL_STEPS = [
  {
    target: '.tour-overview',
    title: 'Übersicht',
    content: 'Hier kannst du die Makronährstoff-Verteilung deines Tagesplans einsehen.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-ingredient-replace',
    title: 'Zutaten tauschen',
    content: 'Gefällt dir eine Zutat nicht? Tausche sie einfach aus',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-ingredient-add',
    title: 'Zutaten hinzufügen',
    content:
      'Du kannst auch weitere Zutaten & Toppings hinzufügen um dein Rezept zu personalisieren.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-recipe-replace',
    title: 'Rezept tauschen',
    content: 'Falls dir das Rezept nicht gefällt kannst du es auch komplett austauschen.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-add-recipe',
    title: 'Weitere Mahlzeiten hinzufügen',
    content:
      'Falls du weitere Mahlzeiten hinzufügen möchtest, werden die Nährwerte pro Mahlzeiten neu berechnet.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-save-template',
    title: 'Als Vorlage speichern',
    content: 'Du kannst deinen Ernährungsplan auch als Vorlage speichern.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
];

const StyledMealPlanDay = styled.div`
  .guide-box {
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    //border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
    //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

    .text-area {
      font-size: 17px;
      margin-bottom: 20px;

      ul {
        list-style: disc;

        li {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

const StyledMealPlanDayDetails = styled.div``;

const StyledMealPlanCalender = styled.div`
  overflow: scroll;
  margin-bottom: 20px;
  position: relative;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 4px 0 10px 4px;

  @media (min-width: 768px) {
    overflow: hidden;
  }

  /*&:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 100px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    pointer-events: none;
  }*/

  &::-webkit-scrollbar {
    display: none;
  }

  .meal-plan-calender-item {
    background-color: whitesmoke;
    width: 40px;
    height: 40px;
    padding: 30px 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s all;
    margin-right: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

    small {
      font-size: 14px;
      font-weight: 400;
    }

    &:hover {
      background-color: #03a9f4;
      color: #fff;
    }

    &.is-selected {
      background-color: #03a9f4;
      color: #fff;
    }
  }
`;

const StyledMealPlanDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;

  .prev-day img,
  .next-day img {
    width: 30px;
    height: 30px;
    cursor: pointer;
    opacity: 0.2;

    &:hover {
      opacity: 0.4;
    }

    svg {
      fill: red;
    }
  }

  .date-title {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.2;

    small {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
  }
`;

export default function MealPlanDays() {
  const {
    userData,
    handleRecipeServingsDecrease,
    handleRecipeServingsIncrease,
    deleteAllMealsFromDay,
    handleAddMealToDay,
    handleRemoveRecipeFromDay,
    handleAddToTemplates,
    cancelProgram,
  } = useAPI();
  const size = useWindowSize();
  const { t } = useTranslation('common');

  const [mealPlanDaysTourCompleted] = useState(
    localStorage.getItem('mealPlanDaysTourCompleted') === 'true',
  );

  const [isPrintView, setIsPrintView] = useState(false);

  const [showSaveMealplanSavePrompt, setShowSaveMealplanSavePrompt] = useState(false);

  const [saveMealPlanTitle, setSaveMealPlanTitle] = useState('');

  const [currentDayIndex, setCurrentDayIndex] = useState(0);

  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [slideSettings, setSlideSettings] = useState({
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth > 1000 ? 2.5 : 1.2,
    slidesToScroll: 1,
    arrows: false,
  });

  useEffect(() => {
    let newSliderSettings = { ...slideSettings };
    newSliderSettings.slidesToShow = window.innerWidth > 1000 ? 3 : 1.2;
    setSlideSettings(newSliderSettings);
  }, [size]);

  useEffect(() => {
    if (userData && userData.mealPlan) {
      setCurrentDay(userData.mealPlan[currentDayIndex]);
    }
  }, [currentDayIndex, userData.mealPlan]);

  const handleNextDayClick = () => {
    if (currentDayIndex < userData.mealPlan.length + 1) {
      setCurrentDayIndex(currentDayIndex + 1);
    }
  };

  const handlePrevDayClick = () => {
    if (currentDayIndex > 0) {
      setCurrentDayIndex(currentDayIndex - 1);
    }
  };

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  return (
    <StyledMealPlanDay>
      <div className="d-flex justify-content-end mt-4">
        <ToggleComponent
          isChecked={isPrintView}
          onChange={() => setIsPrintView(!isPrintView)}
          label="Druckansicht"
        ></ToggleComponent>
      </div>

      {isPrintView ? (
        <MealPlanTable />
      ) : (
        <>
          {currentDay && (
            <div className="row gx-0">
              <div className="col-12">
                <StyledMealPlanDate>
                  <div className="date-title w-100">
                    {currentDay.weekDay}
                    <br />
                    <small>
                      {currentDay.dateOfMonth} {currentDay.month}
                    </small>
                  </div>
                  <div className="prev-day me-2" onClick={() => handlePrevDayClick()}>
                    <img src={`${ASSETS_URL}arrow-left-circle-fill.svg`} alt="" />
                  </div>

                  <div className="next-day" onClick={() => handleNextDayClick()}>
                    <img src={`${ASSETS_URL}arrow-right-circle-fill.svg`} alt="" />
                  </div>
                </StyledMealPlanDate>

                <StyledMealPlanDay>
                  <StyledMealPlanCalender className="d-flex justify-content-between align-items-center">
                    {userData.mealPlan.map((day, dayIndex) => (
                      <div
                        className={`meal-plan-calender-item d-flex ${
                          currentDayIndex === dayIndex ? 'is-selected' : ''
                        }`}
                        key={`date-${dayIndex}`}
                        onClick={() => setCurrentDayIndex(dayIndex)}
                      >
                        {day.weekDay.slice(0, 2)}
                        <small>{day.dateOfMonth}</small>
                      </div>
                    ))}
                  </StyledMealPlanCalender>

                  {currentDay.meals.length > 0 && (
                    <div className="tour-overview">
                      <DayCalorieOverview
                        day={currentDay}
                        currentDayIndex={currentDayIndex}
                      ></DayCalorieOverview>
                    </div>
                  )}

                  {currentDay.recipes && currentDay.recipes.length > 0 ? (
                    <>
                      <SectionHeader>Heute Kochen</SectionHeader>

                      <StyledSlider {...slideSettings} key={Date.now()}>
                        {currentDay.recipes &&
                          currentDay.recipes.map((recipe, recipeIndex) => (
                            <div className="pe-3" key={`recipe-${recipeIndex}`}>
                              <ServingsControl
                                servings={recipe.selectedServing}
                                onDeacrease={() => {
                                  handleRecipeServingsDecrease(currentDayIndex, recipeIndex);
                                }}
                                onIncrease={() => {
                                  handleRecipeServingsIncrease(currentDayIndex, recipeIndex);
                                }}
                                maxServings={recipe.servings}
                              ></ServingsControl>

                              <RecipeCard
                                recipe={recipe}
                                index={recipeIndex}
                                onClick={() => handleRecipeClick(recipe)}
                                onDelete={() =>
                                  handleRemoveRecipeFromDay(currentDayIndex, recipeIndex)
                                }
                              ></RecipeCard>
                            </div>
                          ))}
                      </StyledSlider>
                    </>
                  ) : null}

                  {currentDay.meals.length == 0 && (
                    <>
                      <NoMealsCard currentDayIndex={currentDayIndex}></NoMealsCard>
                    </>
                  )}

                  {currentDay.meals.length > 0 && (
                    <>
                      {currentDay.day_text && (
                        <div className="guide-box mt-4">
                          <SectionHeader>
                            <div className="d-flex justify-content-between  align-items-center">
                              <div>{currentDay.day_title}</div>
                              <div style={{ fontSize: '12px' }}>
                                <FakeLink
                                  onClick={() => {
                                    cancelProgram();
                                  }}
                                >
                                  Programm abbrechen
                                </FakeLink>
                              </div>
                            </div>
                          </SectionHeader>

                          <div
                            className="text-area"
                            dangerouslySetInnerHTML={{ __html: currentDay.day_text }}
                          ></div>
                        </div>
                      )}

                      {!mealPlanDaysTourCompleted && (
                        <Joyride
                          steps={MEAL_STEPS}
                          continuous
                          run
                          callback={({ status }) => {
                            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                              window.localStorage.setItem('mealPlanDaysTourCompleted', 'true');
                            }
                          }}
                          styles={{
                            buttonNext: {
                              boxShadow: 'none',
                              height: 'auto',
                              backgroundColor: '#03a9f4',
                            },
                            buttonBack: {
                              boxShadow: 'none',
                              height: 'auto',
                              color: '#111',
                            },
                            buttonClose: { boxShadow: 'none', height: 'auto' },
                            tooltipTitle: { fontWeight: '700', marginTop: '40px' },
                          }}
                        />
                      )}

                      <StyledMealPlanDayDetails>
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12">
                              {currentDay.meals.map((meal, mealIndex) => (
                                <div key={`dayindex-${currentDayIndex}-mealindex=${mealIndex}`}>
                                  {/*<MealDayTitle onChange={() => {}} value={`Mahlzeit ${mealIndex + 1}`} />*/}
                                  <Meal
                                    dayIndex={currentDayIndex}
                                    mealIndex={mealIndex}
                                    meal={meal}
                                  ></Meal>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </StyledMealPlanDayDetails>

                      <Topline>Optionen</Topline>
                      <Headline>Hier findest du weitere Optionen</Headline>

                      <StyledCTAGrey
                        text={'Ernährungsplan neu erstellen'}
                        icon={<MarkIcon></MarkIcon>}
                        onClick={() => {
                          deleteAllMealsFromDay(currentDayIndex);
                        }}
                      ></StyledCTAGrey>

                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <StyledCTAGrey
                            text={'Weitere Mahlzeit hinzufügen'}
                            className="tour-add-recipe"
                            icon={<NewIcon></NewIcon>}
                            onClick={() => {
                              handleAddMealToDay(currentDayIndex);
                            }}
                          ></StyledCTAGrey>
                        </div>
                        <div className="col-md-6 mt-3">
                          <StyledCTAGrey
                            className="tour-save-template"
                            text={'Als Vorlage speichern'}
                            icon={<DownloadIcon></DownloadIcon>}
                            onClick={() => {
                              setShowSaveMealplanSavePrompt(true);
                            }}
                          ></StyledCTAGrey>
                        </div>
                      </div>
                    </>
                  )}
                </StyledMealPlanDay>
              </div>
            </div>
          )}
        </>
      )}

      {selectedRecipe && (
        <Alert isOpen={true} title={selectedRecipe.title} onClose={() => setSelectedRecipe(null)}>
          <RecipePage recipe={selectedRecipe} onClose={() => setSelectedRecipe(null)}></RecipePage>
        </Alert>
      )}
      {showSaveMealplanSavePrompt && (
        <Alert
          isOpen={true}
          onClose={() => setShowSaveMealplanSavePrompt(false)}
          onConfirm={() => {
            handleAddToTemplates(currentDay, saveMealPlanTitle);
            setShowSaveMealplanSavePrompt(false);
          }}
          onCancel={() => {
            setShowSaveMealplanSavePrompt(false);
          }}
        >
          <SaveMealPlanTemplate
            onChange={(title) => {
              setSaveMealPlanTitle(title);
            }}
          ></SaveMealPlanTemplate>
        </Alert>
      )}
    </StyledMealPlanDay>
  );
}
