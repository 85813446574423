import React, { useState, useEffect, useRef } from 'react';
import MealPlanDays from './Components/MealPlanDays';
import styled from 'styled-components';
import TemplatesPage from './TemplatesPage';
import ProgramsPage from './ProgramsPage';
import { StyledTabs } from 'components/StyledTabs';

const TAB_PLAN = 'TAB_PLAN';
const TAB_TEMPLATES = 'TAB_TEMPLATES';
const TAB_PROGRAMS = 'TAB_PROGRAMS';

export const StyledMealPlanPage = styled.div``;

export default function MealPlanPage({ ...props }) {
  const [activeTab, setActiveTab] = useState(TAB_PLAN);

  return (
    <StyledMealPlanPage {...props}>
      <StyledTabs>
        <div
          className={`tab ${activeTab === TAB_PLAN ? 'is-active' : ''}`}
          onClick={() => setActiveTab(TAB_PLAN)}
        >
          Plan
        </div>
        <div
          className={`tab ${activeTab === TAB_TEMPLATES ? 'is-active' : ''}`}
          onClick={() => setActiveTab(TAB_TEMPLATES)}
        >
          Vorlagen
        </div>
        <div
          className={`tab ${activeTab === TAB_PROGRAMS ? 'is-active' : ''}`}
          onClick={() => setActiveTab(TAB_PROGRAMS)}
        >
          Programme
        </div>
      </StyledTabs>

      <>
        {activeTab === TAB_PLAN && <MealPlanDays></MealPlanDays>}
        {activeTab === TAB_TEMPLATES && <TemplatesPage></TemplatesPage>}
        {activeTab === TAB_PROGRAMS && <ProgramsPage></ProgramsPage>}
      </>
    </StyledMealPlanPage>
  );
}
