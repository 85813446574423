import React from 'react';
import {
  NUTRITION_ANYTHING,
  NUTRITION_PESCETARIEN,
  NUTRITION_VEGAN,
  NUTRITION_VEGETARIAN,
  USER_GENDER_FEMALE,
  USER_GENDER_MALE,
  USER_GOAL_BUILD_MUSCLE,
  USER_GOAL_LOSE_WEIGHT,
  USER_GOAL_MAINTAIN,
  DIET_ANYTHING,
  DIET_LOW_CARB,
  DIET_KETO,
  DIET_MEDITERRANEAN,
  DIET_PALEO,
  DIET_SLOW_CARB,
  DIET_HIGH_PROTEIN,
  USER_GOAL_DEFINE,
} from 'constants/userMetrics';
import { ASSETS_URL, URL_NUTRITASTIC_DE } from 'constants/assets';
import RedButton from 'components/buttons/RedButton';
import CTABlue from 'components/buttons/CTABlue';

export default function selectGoal({ setGoal, setCurrentStep, currentStep, goal, scrollToWidget }) {
  return (
    <>
      <div className="onboarding-question">Was ist dein Ziel?</div>

      <div className="onboarding-question-text mt-2 mb-4">
        Wir nutzen dein Ziel um dein Ernährungsplan optimal auf dich anzupassen.
      </div>

      <div className="goal-wrapper row m-0">
        <div className="col-12 col-md-6">
          <div
            className={`goal ${goal === USER_GOAL_BUILD_MUSCLE ? 'is-selected' : ''}`}
            onClick={() => {
              setGoal(USER_GOAL_BUILD_MUSCLE);
              scrollToWidget();
              setCurrentStep(currentStep + 1);
            }}
          >
            <img src={`${ASSETS_URL}onboarding/build-muscle.png`} className="goal-image"></img>
            <div className="goal-text">Muskelaufbau</div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div
            className={`goal ${goal === USER_GOAL_LOSE_WEIGHT ? 'is-selected' : ''}`}
            onClick={() => {
              setGoal(USER_GOAL_LOSE_WEIGHT);
              scrollToWidget();
              setCurrentStep(currentStep + 1);
            }}
          >
            <img src={`${ASSETS_URL}onboarding/lose-weight.png`} className="goal-image"></img>
            <div className="goal-text">Abnehmen</div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div
            className={`goal ${goal === USER_GOAL_MAINTAIN ? 'is-selected' : ''}`}
            onClick={() => {
              setGoal(USER_GOAL_MAINTAIN);
              scrollToWidget();
              setCurrentStep(currentStep + 1);
            }}
          >
            <img src={`${ASSETS_URL}onboarding/eat-healthy.png`} className="goal-image"></img>
            <div className="goal-text">Gesund essen</div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div
            className={`goal ${goal === USER_GOAL_DEFINE ? 'is-selected' : ''}`}
            onClick={() => {
              setGoal(USER_GOAL_DEFINE);
              scrollToWidget();
              setCurrentStep(currentStep + 1);
            }}
          >
            <img src={`${ASSETS_URL}onboarding/define-body.png`} className="goal-image"></img>
            <div className="goal-text">Definieren</div>
          </div>
        </div>
      </div>

      {/*
      <div className="mt-4">
        <CTABlue
          text={'Weiter'}
          onClick={() => {
            setCurrentStep(currentStep + 1);
          }}
        ></CTABlue>
        </div>*/}

      {/*
      <div className="onboarding-answer-wrapper">
        <div
          className={`onboarding-answer-button d-flex justify-content-between align-items-center`}
          onClick={() => {
            setGoal(USER_GOAL_LOSE_WEIGHT);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="text-left">
            <div className="onboarding-image">
              <img src={`${ASSETS_URL}lose-weight.svg`} className="ps-4 pe-4 w-100"></img>
            </div>
          </div>
          <div className="text-left w-100">
            <span className="d-block">Abnehmen</span>
            <small>Fett verbrennen & schlank werden</small>
          </div>
        </div>
      </div>

      <div className="onboarding-answer-wrapper">
        <div
          className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
            goal === USER_GOAL_LOSE_WEIGHT ? 'is-selected' : ''
          }`}
          onClick={() => {
            setGoal(USER_GOAL_LOSE_WEIGHT);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="d-flex justify-content-end align-items-center">
            <div className="me-3">
              <img src={`${ASSETS_URL}lose-weight.svg`} className="w-100"></img>
            </div>
            <div className="text-left">
              <span className="d-block">Abnehmen</span>
              <small>Fett verbrennen & schlank werden</small>
            </div>
          </div>
        </div>
      </div>

      <div className="onboarding-answer-wrapper">
        <div
          className={`p-4 onboarding-answer-button  d-flex justify-content-between align-items-center ${
            goal === USER_GOAL_MAINTAIN ? 'is-selected' : ''
          }`}
          onClick={() => {
            setGoal(USER_GOAL_MAINTAIN);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="d-flex justify-content-end align-items-center">
            <div className="me-3">
              <img src={`${ASSETS_URL}get-fit.svg`} className="w-100"></img>
            </div>
            <div className="text-left">
              <span className="d-block">Fitter werden</span>
              <small>Definieren, Straffen & gesund fühlen</small>
            </div>
          </div>
        </div>
      </div>

      <div className="onboarding-answer-wrapper">
        <div
          className={`p-4 onboarding-answer-button  d-flex justify-content-between align-items-center ${
            goal === USER_GOAL_BUILD_MUSCLE ? 'is-selected' : ''
          }`}
          onClick={() => {
            setGoal(USER_GOAL_BUILD_MUSCLE);
            setCurrentStep(currentStep + 1);
            scrollToWidget();
          }}
        >
          <div className="d-flex justify-content-end align-items-center">
            <div className="me-3">
              <img src={`${ASSETS_URL}build-muscles.svg`} className="w-100"></img>
            </div>
            <div className="text-left">
              <span className="d-block">Muskelmasse aufbauen</span>
              <small>Masse & Kraft aufbauen</small>
            </div>
          </div>
        </div>
      </div>*/}
    </>
  );
}
