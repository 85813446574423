import React, { useEffect, useState } from 'react';
import { useAPI } from 'context/APIcontext';
import styled from 'styled-components';
import { StyledMainContent } from 'pages/OverviewPage/OverviewPage';
import RecipeCard from 'components/RecipeCard';
import SectionHeader from 'components/Typo/SectionHeader';
import { useTranslation } from 'react-i18next';
import RecipePage from 'pages/RecipePage/RecipePage';
import Alert from 'components/Alert/Alert';
import CTABlue from 'components/buttons/CTABlue';

const StyledCookBook = styled.div``;

const StyledRecipeCategorys = styled.div`
  margin-bottom: 20px;

  .recipe-category {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4;
    display: inline-block;
    padding: 8px 16px;
    text-align: center;
    background-image: linear-gradient(117deg, #fafafa, #eee);
    border-radius: 8px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

    &:hover {
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
      color: #fff;
    }

    &.is-selected {
      background-image: linear-gradient(117deg, #03a9f4, #0288d1);
      color: #fff;
    }
  }
`;

export default function CookBookListPage({ ...props }) {
  const { dbData } = useAPI();
  const { t } = useTranslation('common');
  const [recipeCategorys, setRecipeCategorys] = useState([]);
  const [recipeList, setRecipeList] = useState([]);
  const [filteredRecipeList, setFilteredRecipeList] = useState([]);
  const [recipeListSliceIndex, setRecipeListSliceIndex] = useState(9);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  useEffect(() => {
    if (dbData && dbData.recipes) {
      setRecipeList(dbData.recipes);
      setFilteredRecipeList(dbData.recipes);

      let categorys = [];
      let tmpRecipeCategorys = [];
      if (dbData) {
        dbData.recipes.forEach((recipe) => {
          recipe.category.forEach((recipeCategory) => {
            if (!categorys.includes(recipeCategory)) {
              categorys.push(recipeCategory);
            }
          });
        });
        categorys.forEach((category) => {
          tmpRecipeCategorys.push({
            title: category,
            isSelected: false,
          });
        });
        setRecipeCategorys(tmpRecipeCategorys);
      }
    }
  }, [dbData]);

  const handleSelectCategory = (index) => {
    let tmpRecipeCategorys = [...recipeCategorys];
    tmpRecipeCategorys[index].isSelected = !tmpRecipeCategorys[index].isSelected;
    setRecipeCategorys(tmpRecipeCategorys);

    let selectedCategorys = [];
    tmpRecipeCategorys.forEach((category) => {
      if (category.isSelected) {
        selectedCategorys.push(category.title);
      }
    });

    setFilteredRecipeList(recipeList);

    let tmpFilteredRecipeList = recipeList.filter((recipe) => {
      if (selectedCategorys.length > 0) {
        return selectedCategorys.every((r) => recipe.category.includes(r));
      } else {
        return recipeList;
      }
    });

    setFilteredRecipeList(tmpFilteredRecipeList);
  };

  const handleRecipeClick = (recipe) => {
    setSelectedRecipe(recipe);
  };

  return (
    <StyledMainContent {...props}>
      <SectionHeader className="mt-4">Suche</SectionHeader>
      <StyledRecipeCategorys>
        {recipeCategorys.map((category, index) => (
          <div
            key={`category-${index}`}
            className={`recipe-category ${category.isSelected ? 'is-selected' : ''}`}
            onClick={() => {
              handleSelectCategory(index);
            }}
          >
            {t(category.title)}
          </div>
        ))}
      </StyledRecipeCategorys>
      <SectionHeader>Rezepte</SectionHeader>
      <StyledCookBook>
        <div className="row">
          {filteredRecipeList.slice(0, recipeListSliceIndex).map((recipe, recipeIndex) => (
            <div className="col-12 col-sm-6 col-lg-4 mb-3" key={`recipe-card-${recipeIndex}`}>
              <RecipeCard
                recipe={recipe}
                index={recipeIndex}
                onClick={() => handleRecipeClick(recipe)}
              ></RecipeCard>
            </div>
          ))}
        </div>
      </StyledCookBook>
      <div className="text-center">
        <CTABlue
          onClick={() => {
            setRecipeListSliceIndex(recipeListSliceIndex + 9);
          }}
          text={t('show-more')}
        ></CTABlue>
      </div>
      {selectedRecipe && (
        <Alert isOpen={true} title={selectedRecipe.title} onClose={() => setSelectedRecipe(null)}>
          <RecipePage recipe={selectedRecipe} onClose={() => setSelectedRecipe(null)}></RecipePage>
        </Alert>
      )}
    </StyledMainContent>
  );
}
