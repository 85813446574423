import React from 'react';
import styled from 'styled-components';

const StyledHeadline = styled.div`
  color: #222;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

export default function Headline({ children, ...props }) {
  return <StyledHeadline {...props}>{children}</StyledHeadline>;
}
