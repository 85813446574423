import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { StyledTabs } from 'components/StyledTabs';
import CookBookRecommandationsPage from './CookBookRecommandationsPage';
import CookBookListPage from './CookBookListPage';

export const StyledCookBookOverviewPage = styled.div``;

const TAB_COOKBOOK_RECOMMENDATIONS = 'TAB_RECOMMENDATIONS';
const TAB_COOKBOOK_LIST = 'TAB_RECIPE_LIST';

export default function CookBookOverviewPage() {
  const [activeTab, setActiveTab] = useState(TAB_COOKBOOK_RECOMMENDATIONS);

  return (
    <StyledCookBookOverviewPage>
      <CookBookListPage></CookBookListPage>
    </StyledCookBookOverviewPage>
  );
}
