import React, { useEffect, useState } from 'react';
import { useAPI } from 'context/APIcontext';
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage';
import MealPlanPage from 'pages/MealPlanPage/MealPlanPage';
import DesktopNavigation from 'components/DesktopNavigation/DesktopNavigation';
import PageContainer from 'components/PageContainer';
import styled from 'styled-components';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import ShoppingListPage from 'pages/ShoppingListPage/ShoppingListPage';
import CookBookOverviewPage from 'pages/CookBookPage/CookBookOverviewPage';
import Joyride, { STATUS } from 'react-joyride';

const OVERVIEW_STEPS = [
  {
    target: '.tour-welcome',
    placement: 'center',
    title: 'Schön, dass du da bist!',
    content: 'Bevor es losgeht führen wir dich durch alle Funktionen des Ernährungsplans.',
    disableBeacon: true,
    locale: { next: 'Los gehts!', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-mealplan',
    placement: window.innerWidth > 768 ? 'auto' : 'top-start',
    title: 'Ernährungsplan',
    content: 'Hier findest du deinen Ernährungsplan, deine Vorlagen und Ernährungsprogramme.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-recipes-page',
    placement: window.innerWidth > 768 ? 'auto' : 'top-start',
    title: 'Rezepte',
    content:
      'Falls du doch mal etwas Kochen möchtest findest du hier eine große Auswahl an leckeren Rezepten.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-shopping-list',
    title: 'Einkaufsliste',
    placement: window.innerWidth > 768 ? 'auto' : 'top-start',
    content: 'Hier findest du deine Einkaufsliste.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-profil',
    title: 'Dein Profil',
    placement: window.innerWidth > 768 ? 'auto' : 'top-start',
    content:
      'Hier kannst du diverse Einstellungen vornehmen und deine Ernährung auf deine Vorlieben anpassen.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-create-mealplan-start',
    title: 'Lass uns starten!',
    content: 'Lass uns deinen ersten Ernährungsplan erstellen.',
    disableBeacon: true,
    locale: { next: 'Los gehts!', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-calories',
    title: 'Tagesbedarf anpassen',
    content:
      'Dein Kalorienbedarf wird automatisch berechnet, du kannst ihn aber auch manuell anpassen.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-meals',
    title: 'Mahlzeiten anpassen',
    content: 'Du kannst die Anzahl der Mahlzeiten jeden Tag individuell anpassen.',
    disableBeacon: true,
    locale: { next: 'Weiter', back: 'Zurück', last: 'Alles klar!' },
  },
  {
    target: '.tour-create-mealplan',
    title: 'Ernährungsplan erstellen',
    content: 'Mit einem Klick erstellst du deinen individuellen Ernährungsplan.',
    disableBeacon: true,
    locale: { next: 'Alles klar!', back: 'Zurück', last: 'Ok, verstanden.' },
  },
];

export const StyledMainContent = styled.div``;

export default function OverviewPage() {
  const { userData } = useAPI();

  const [currentTab, setCurrentTab] = useState('mealplan');

  const [overViewTourCompleted] = useState(
    localStorage.getItem('mealPlanDaysTourCompleted') === 'true',
  );

  return (
    <div>
      {userData || userData == '' ? (
        <>
          {!userData.profile && <OnboardingPage></OnboardingPage>}

          {userData.profile && (
            <PageContainer>
              {!overViewTourCompleted && (
                <Joyride
                  steps={OVERVIEW_STEPS}
                  continuous
                  run
                  callback={({ status }) => {
                    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
                      window.localStorage.setItem('overViewTourCompleted', 'true');
                    }
                  }}
                  styles={{
                    buttonNext: {
                      boxShadow: 'none',
                      height: 'auto',
                      backgroundColor: '#03a9f4',
                    },
                    buttonBack: {
                      boxShadow: 'none',
                      height: 'auto',
                      color: '#111',
                    },
                    buttonClose: { boxShadow: 'none', height: 'auto' },
                    tooltipTitle: { fontWeight: '700', marginTop: '40px' },
                    options: {
                      zIndex: 9999,
                    },
                  }}
                />
              )}

              <div className="row">
                <div className="col-12 tour-welcome">
                  <DesktopNavigation
                    currentTab={currentTab}
                    handleTabChange={(tab) => {
                      setCurrentTab(tab);
                    }}
                  ></DesktopNavigation>
                </div>
                <div className="col-12">
                  {currentTab === 'mealplan' && (
                    <>
                      <MealPlanPage></MealPlanPage>
                    </>
                  )}
                  {currentTab === 'recipes' && (
                    <>
                      <CookBookOverviewPage></CookBookOverviewPage>
                    </>
                  )}
                  {currentTab === 'shopping' && (
                    <>
                      <ShoppingListPage></ShoppingListPage>
                    </>
                  )}
                  {currentTab === 'settings' && (
                    <>
                      <SettingsPage></SettingsPage>
                    </>
                  )}
                </div>
              </div>
            </PageContainer>
          )}
        </>
      ) : (
        <>Lädt</>
      )}
    </div>
  );
}
