import React from 'react';
import { StyledProgramPage } from './StyledProgramPage';
import CTABlue from 'components/buttons/CTABlue';
import { useTranslation } from 'react-i18next';
import Topline from 'components/Typo/Topline';
import Headline from 'components/Typo/Headline';
import CTAGreySmall from 'components/buttons/CTAGreySmall';
import TimerIcon from 'components/SVG/TimerIcon';

export default function ProgramPage({ program, startProgramm }) {
  const { t } = useTranslation('common');

  return (
    <StyledProgramPage>
      <div className="row">
        <div className="program-image" style={{ backgroundImage: `url(${program.image})` }}></div>
      </div>

      <div className="program-content">
        <div className="program-stats">
          <div className="program-time w-100 text-center">
            <TimerIcon></TimerIcon>
            {program.duration_in_days} Tage Program
          </div>
        </div>

        <div className="program-headline">
          <div dangerouslySetInnerHTML={{ __html: program.title }}></div>
        </div>
        {/*<div className="program-topline">{program.topline}</div>*/}

        {program.description !== '' && (
          <>
            <div
              className="program-description mb-4"
              dangerouslySetInnerHTML={{ __html: program.description }}
            ></div>
          </>
        )}

        <div className="program-description mt-4 mb-4 pb-4">
          <div className="mt-4" style={{ maxWidth: '300px', margin: '0 auto' }}>
            <CTABlue
              text={'Programm starten'}
              className="mt-0"
              onClick={() => {
                startProgramm(program);
              }}
            ></CTABlue>
          </div>
        </div>
      </div>
    </StyledProgramPage>
  );
}
