import React from 'react';
import styled from 'styled-components';
import Headline from './Typo/Headline';
import Topline from './Typo/Topline';

const StyledEmptyState = styled.div`
  text-align: center;
  margin: 10% auto;

  .empty-state-icon {
    margin-bottom: 20px;

    svg {
      width: 100px;
      height: 100px;
    }
  }
`;

export default function EmptyState({ title, text, icon }) {
  return (
    <StyledEmptyState>
      <div className="empty-state-icon">{icon}</div>
      <Headline>{title}</Headline>
      <Topline>{text}</Topline>
    </StyledEmptyState>
  );
}
