import React from 'react';
import styled from 'styled-components';

const StyledInlineButton = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  background-color: #fff;
  color: #000;
  text-decoration: none !important;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  transition: 0.2s all ease;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    color: #fff;
    text-decoration: none !important;
    background-color: #17a0ff;
  }
`;

export default function InlineButton({ text, onClick, disabled }) {
  function handleOnClick() {
    if (disabled) return;
    onClick();
  }

  return (
    <StyledInlineButton
      className={`${disabled ? 'is-disabled' : ''}`}
      onClick={() => {
        handleOnClick();
      }}
    >
      {text}
    </StyledInlineButton>
  );
}
