import React from 'react';
import styled from 'styled-components';

const StyledSmallButton = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 12px;
  background-color: whitesmoke;
  color: #222;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 8px;
  transition: 0.2s all ease;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

  .icon {
    svg {
      width: 16px;
      height: 16px;
      margin: 0 auto;
    }
  }

  .text {
    margin-left: 8px;
  }

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    color: #fff;
    background-color: #03a9f4;
    text-decoration: none !important;
  }
`;

export default function SmallButton({ text, onClick, disabled, icon, className }) {
  function handleOnClick() {
    if (disabled) return;
    onClick();
  }

  return (
    <StyledSmallButton
      className={`${disabled ? 'small-button is-disabled' : 'small-button'} ${className}`}
      onClick={() => {
        handleOnClick();
      }}
    >
      {icon && <span className="icon">{icon}</span>}
      {text && <div className="text">{text}</div>}
    </StyledSmallButton>
  );
}
