import LoadingState from 'components/LoadingStats';
import React from 'react';

export default function calculateMealPlan() {
  return (
    <>
      <LoadingState
        title={'Einen Moment'}
        text={`Wir berechnen deinen Ernährungsplan`}
      ></LoadingState>
    </>
  );
}
