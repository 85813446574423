export function displayIngredientAmount(amount, unit) {
  if (amount == 0) return ``;
  if (unit == `TABLESPOON`) return `${amount}`; // , je ${amountInGrams}g
  if (unit == `HANDFUL`) return `${amount}`; // , je ${amountInGrams}g
  if (unit == `TEASPOON`) return `${amount}`; // , je ${amountInGrams}g
  if (unit == `GRAM`) return `${amount}`;
  if (unit == `g`) return `${amount}`;
  if (unit == `MILLI_LITER`) return `${amount}`;
  if (unit == `WHOLE`) return `${amount}`;
  if (unit == `SLICE`) return `${amount}`; // , je ${amountInGrams}g
  if (unit == `SOME`) return ``;
  if (unit == `BLANK`) return `${amount}`;
  if (unit == `SPOON`) return `${amount}`;
  if (unit == `TASTE`) return ``;
  if (unit == `LITER`) return `${amount}`;
  if (unit == `BRANCH`) return `${amount}`;
  if (unit == `ANY`) return ``;
  if (unit == `CAN`) return `${amount}`;
  if (unit == `BUNCH`) return `${amount}`;
  if (unit == `SOMEWHAT`) return ``;
  if (unit == `STICK`) return ``;
  if (unit == `PINCH`) return ``;
  if (unit == `PIECE`) return `${amount} `;
  if (unit == `LEAF`) return `${amount} `;
  if (unit == `PACKAGE`) return `${amount}`;
  if (unit == `FEW`) return `${amount}`;
  if (unit == `SPLASH`) return `${amount}`;
  if (unit == `PACK`) return `${amount}`;
  else return amount;
}
