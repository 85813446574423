import React, { useState, useEffect, useRef } from 'react';
import {
  NUTRITION_ANYTHING,
  NUTRITION_PESCETARIEN,
  NUTRITION_VEGAN,
  NUTRITION_VEGETARIAN,
  USER_GENDER_FEMALE,
  USER_GENDER_MALE,
  USER_GOAL_BUILD_MUSCLE,
  USER_GOAL_LOSE_WEIGHT,
  USER_GOAL_MAINTAIN,
  DIET_ANYTHING,
  DIET_LOW_CARB,
  DIET_KETO,
  DIET_MEDITERRANEAN,
  DIET_PALEO,
  DIET_SLOW_CARB,
  DIET_HIGH_PROTEIN,
  USER_GOAL_DEFINE,
} from 'constants/userMetrics';
import { ASSETS_URL, URL_NUTRITASTIC_DE } from 'constants/assets';
import { sendTrackingPixel } from 'helper/tracking';
import BlockButton from 'components/buttons/BlockButton';
import { useTranslation } from 'react-i18next';
import ArrowLeftShort from 'components/SVG/ArrowLeftShort';
import finishOnboarding from './steps/finishOnboarding';
import calculateMealPlan from './steps/calculateMealPlan';
import selectGoal from './steps/selectGoal';
import selectGender from './steps/selectGender';
import { StyledFakeOnboarding } from './StyledFakeOnboarding';
import RedButton from 'components/buttons/RedButton';
import SelectNutrition from './steps/SelectNutrition';
import ShowSuccessGraph from './steps/ShowSuccessGraph';
import EnterEmail from './steps/EnterEmail';
import DoneAnimation from './steps/DoneAnimation';

export default function FakeOnboardingPage() {
  const { t } = useTranslation('common');

  const myRef = useRef(null);

  const [stepsNumber] = useState(11);
  const [currentStep, setCurrentStep] = useState(1);
  const [gender, setGender] = useState();
  const [height, setHeight] = useState();
  const [age, setAge] = useState();
  const [weight, setWeight] = useState();
  const [goalWeight, setGoalWeight] = useState();
  const [activity, setActivity] = useState();
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [allowMarketing, setAllowMarketing] = useState(true);
  const [goal, setGoal] = useState();

  const [nutrition, setNutrition] = useState();

  const [diets] = useState([
    DIET_ANYTHING,
    DIET_HIGH_PROTEIN,
    DIET_LOW_CARB,
    DIET_SLOW_CARB,
    DIET_KETO,
    DIET_MEDITERRANEAN,
    //DIET_PALEO,
  ]);
  const [selectedDiet, setSelectedDiet] = useState();
  const [allergies, setAllergies] = useState([
    {
      slug: 'lactose',
      isSelected: false,
    },
    { slug: 'histamin', isSelected: false },
    { slug: 'fructose', isSelected: false },
    { slug: 'gluten', isSelected: false },
  ]);

  const [mealsAmount, setMealsAmount] = useState(4);

  const [baseMetabolicRate, setBaseMetabolicRate] = useState();
  const [overallMetabolicRate, setOverallMetabolicRate] = useState();
  const [targetCalories, setTargetCalories] = useState();

  const [stepPercentage, setStepPercentage] = useState(0);
  const [showEmailAlert, setShowEmailAlert] = useState(false);

  useEffect(() => {
    setStepPercentage(Math.round((currentStep * 100) / stepsNumber));
  }, [currentStep]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      sendTrackingPixel('view-onboarding-page-home');
    } else {
      sendTrackingPixel('view-onboarding-page-article');
    }
  }, []);

  useEffect(() => {
    if (height && age && weight && activity && goal) {
      let tmpBaseMetabolicRate = 0;
      let tmpOverallMetabolicRate = 0;
      let tmpTargetCalories = 0;

      if (gender === USER_GENDER_MALE) {
        tmpBaseMetabolicRate = Math.round(66.47 + 13.7 * weight + 5 * height - 6.8 * age);
      }
      if (gender === USER_GENDER_FEMALE) {
        tmpBaseMetabolicRate = Math.round(655.1 + 9.6 * weight + 1.8 * height - 4.7 * age);
      }

      tmpOverallMetabolicRate = Math.round(tmpBaseMetabolicRate * activity);

      if (goal === USER_GOAL_LOSE_WEIGHT) {
        tmpTargetCalories = Math.round(tmpOverallMetabolicRate - 400);
      }
      if (goal === USER_GOAL_BUILD_MUSCLE) {
        tmpTargetCalories = Math.round(tmpOverallMetabolicRate + 400);
      }
      if (goal === USER_GOAL_MAINTAIN) {
        tmpTargetCalories = tmpOverallMetabolicRate;
      }

      setBaseMetabolicRate(tmpBaseMetabolicRate);
      setOverallMetabolicRate(tmpOverallMetabolicRate);
      setTargetCalories(tmpTargetCalories);
    }
  }, [gender, height, age, weight, activity, goal]);

  async function handleSetUserData() {
    sendTrackingPixel('goal-' + goal);
    sendTrackingPixel('gender-' + gender);
    sendTrackingPixel('nutrition-' + nutrition);
    sendTrackingPixel('diet-' + selectedDiet);

    /*try {
      const data = {
        username: userEmail,
        email: userEmail,
        password: userPassword,
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      try {
        const response = await fetch('https://nutritastic.de/wp-json/wp/v2/users', requestOptions);
        const json = await response.json();
        console.log(json);
      } catch (error) {
        setSubmitError(error);
      }
    } catch (error) {
      //console.error(error);
    }*/

    if (window.location.pathname === '/') {
      sendTrackingPixel('generate-mealplan-page-home');
    } else {
      sendTrackingPixel('generate-mealplan-page-article');
    }

    let goalTitleString = 'Fit werden';
    if (goal == USER_GOAL_BUILD_MUSCLE) {
      goalTitleString = 'Muskelaufbau';
    }
    if (goal == USER_GOAL_LOSE_WEIGHT) {
      goalTitleString = 'Abnehmen';
    }
    if (goal == USER_GOAL_DEFINE) {
      goalTitleString = 'Definieren';
    }

    let nutritionTitleString = 'Mischkost';
    if (nutrition == NUTRITION_PESCETARIEN) {
      nutritionTitleString = 'Pescetarisch';
    }
    if (nutrition == NUTRITION_VEGETARIAN) {
      nutritionTitleString = 'Vegetarisch';
    }
    if (nutrition == NUTRITION_VEGETARIAN) {
      nutritionTitleString = 'Vegan';
    }

    let dietTitleString;
    if (selectedDiet == DIET_HIGH_PROTEIN) {
      dietTitleString = 'High Protein';
    }
    if (selectedDiet == DIET_LOW_CARB) {
      dietTitleString = 'Low Carb';
    }
    if (selectedDiet == DIET_SLOW_CARB) {
      dietTitleString = 'Slow Carb';
    }
    if (selectedDiet == DIET_KETO) {
      dietTitleString = 'Keto';
    }
    if (selectedDiet == DIET_MEDITERRANEAN) {
      dietTitleString = 'Mediterranes';
    }

    let params =
      'title=' +
      goalTitleString +
      '&calories=' +
      Number(targetCalories) +
      '&diet=' +
      nutritionTitleString +
      '&gender=' +
      gender +
      '&age=' +
      Number(age) +
      '&weight=' +
      Number(weight) +
      '&height=' +
      Number(height) +
      '&goalWeight=' +
      Number(goalWeight);

    let url = `${URL_NUTRITASTIC_DE}checkout-mealplan.php?${params}`;

    const payload = {
      email: userEmail,
      listIds: [9],
      attributes: {
        PURCHASE_URL: url,
        PURCHASED: false,
        ALLOW_MARKETING: allowMarketing,
        GOAL: goalTitleString,
        CALORIES: Number(targetCalories),
        DIET: nutritionTitleString,
        GENDER: gender,
        WEIGHT: Number(weight),
        GOAL_WEIGHT: Number(goalWeight),
        HEIGHT: Number(height),
        AGE: Number(age),
      },
    };
    const headers = {
      'Content-Type': 'application/json',
      'api-key':
        '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', // Hier den API-Schlüssel von Sendinblue angeben.
    };
    try {
      const response = await fetch('https://api.sendinblue.com/v3/contacts', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      //console.error(error);
    }

    window.location.href = url;
  }

  function scrollToWidget() {
    myRef.current.scrollIntoView();
  }

  return (
    <>
      <div className="container-fullwidth" id="onboarding-widget">
        <StyledFakeOnboarding ref={myRef}>
          <div className="form-title">
            <div className="form-title-text">Ernährungsplan erstellen</div>
          </div>
          <section className="container calculator-result-wrapper">
            <div className="row d-block">
              {currentStep > 1 && (
                <div className="onboarding-header w-100 mt-4">
                  <div className="onboarding-progress">
                    <div
                      className="onboarding-progress-bar"
                      style={{ width: `${stepPercentage}%` }}
                    ></div>
                  </div>

                  <div className="d-flex justify-content-between pt-2 pb-2">
                    <div
                      className="text-left"
                      style={{ opacity: currentStep > 1 ? '1' : '0' }}
                      onClick={() => {
                        if (currentStep > 1) {
                          setCurrentStep(currentStep - 1);
                        }
                      }}
                    >
                      <ArrowLeftShort />
                    </div>
                    <div className="text-right step-counter">
                      Schritt {currentStep} von {stepsNumber}
                    </div>
                  </div>
                </div>
              )}

              {currentStep === 1 &&
                selectGoal({ setGoal, setCurrentStep, currentStep, goal, scrollToWidget })}
              {currentStep === 2 &&
                selectGender({ gender, setGender, setCurrentStep, scrollToWidget, currentStep })}
              {currentStep === 3 && userInformations()}
              {currentStep === 4 && askForGoalWeight()}
              {currentStep === 5 &&
                ShowSuccessGraph({
                  weight,
                  goalWeight,
                  setCurrentStep,
                  scrollToWidget,
                  currentStep,
                })}

              {currentStep === 6 &&
                SelectNutrition({
                  nutrition,
                  setNutrition,
                  setCurrentStep,
                  scrollToWidget,
                  currentStep,
                })}
              {currentStep === 7 && selectDiet()}
              {currentStep === 8 && selectActivity()}
              {currentStep === 9 && calculateMealPlan()}
              {currentStep === 10 &&
                EnterEmail({
                  setCurrentStep,
                  scrollToWidget,
                  currentStep,
                  userEmail,
                  setUserEmail,
                  userPassword,
                  setUserPassword,
                  allowMarketing,
                  setAllowMarketing,
                  handleSetUserData,
                  showEmailAlert,
                  setShowEmailAlert,
                })}
              {currentStep === 11 && DoneAnimation()}
              {currentStep === 13 && finishOnboarding({ handleSetUserData, fullTitle })}
            </div>
          </section>
        </StyledFakeOnboarding>
      </div>
    </>
  );

  function userInformations() {
    return (
      <>
        <div className="onboarding-question">Über dich</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Damit wir dir helfen können, deine Ziele zu erreichen, benötigen wir einige Informationen
          über dich.
        </div>

        <div className="onboarding-values-input">
          <label htmlFor="basic-url">Dein Alter</label>
          <div className="input-group mb-3">
            <input
              pattern="\d*"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              onChange={(e) => setAge(e.target.value)}
              value={age}
              placeholder="0"
              type="number"
              required
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon3">
                Jahre
              </span>
            </div>
          </div>

          <label htmlFor="basic-url">Deine Körpergröße</label>
          <div className="input-group mb-3">
            <input
              pattern="\d*"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              onChange={(e) => setHeight(e.target.value)}
              value={height}
              placeholder="0"
              type="number"
              required
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon3">
                cm
              </span>
            </div>
          </div>

          <label htmlFor="basic-url">Dein aktuelles Gewicht</label>
          <div className="input-group mb-3">
            <input
              pattern="\d*"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
              onChange={(e) => setWeight(e.target.value)}
              value={weight}
              placeholder="0"
              type="number"
              required
            />
            <div className="input-group-append">
              <span className="input-group-text" id="basic-addon3">
                kg
              </span>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <BlockButton
            text={'Weiter'}
            disabled={!height || !age || !weight}
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}
          ></BlockButton>
        </div>
      </>
    );
  }

  function askForGoalWeight() {
    return (
      <>
        <div className="onboarding-question">Okay, was ist dein Wunschgewicht?</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Dein Wunschgewicht hilft uns dabei, dir einen Ernährungsplan zu erstellen, der genau auf
          dich zugeschnitten ist.
        </div>

        <div className="onboarding-values-input">
          <label htmlFor="basic-url">Wunschgewicht</label>
          <div className="input-group mb-3">
            <input
              pattern="\d*"
              className="form-control"
              id="basic-url"
              onChange={(e) => setGoalWeight(e.target.value)}
              value={goalWeight}
              placeholder="0"
              type="number"
              required
            />
            <div className="input-group-append">
              <span className="input-group-text">kg</span>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <BlockButton
            text={'Weiter'}
            disabled={!goalWeight}
            onClick={() => {
              setCurrentStep(currentStep + 1);
              /*setTimeout(() => {
                handleSetUserData();
              }, 3000);*/
            }}
          ></BlockButton>
        </div>
      </>
    );
  }

  function selectActivity() {
    return (
      <>
        <div className="onboarding-question">Wie aktiv bist du im Alltag?</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Wir nutzen deine Essgewohnheiten um deinen Plan bestmöglich an deinen Alltag anzupassen
          und dir Aufwand zu sparen. Du kannst das später jederzeit ändern.
        </div>

        <div className="row p-4">
          <div
            className={`col-6 p-2 activity-level-box text-center ${
              activity === 1.1 ? 'is-selected' : ''
            }`}
            onClick={() => {
              setActivity(1.1);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
              setTimeout(() => {
                setCurrentStep(currentStep + 2);
              }, 3000);
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-sitting-on-chair.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle mb-2">Geringfügig</div>
            <div className="daily-activity-item-description centered">
              Überwiegend sitzend (z. B. Büroangestellte:r)
            </div>
          </div>
          <div
            className={`col-6 p-2 activity-level-box text-center ${
              activity === 1.3 ? 'is-selected' : ''
            }`}
            onClick={() => {
              setActivity(1.3);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
              setTimeout(() => {
                setCurrentStep(currentStep + 2);
              }, 3000);
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-stand-up.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle  mb-2">Mäßig</div>
            <div className="daily-activity-item-description centered">
              Meistens stehend (z. B. Lehrer:in, Kassierer:in)
            </div>
          </div>
          <div
            className={`col-6 p-2 activity-level-box text-center ${
              activity === 1.5 ? 'is-selected' : ''
            }`}
            onClick={() => {
              setActivity(1.5);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
              setTimeout(() => {
                setCurrentStep(currentStep + 2);
              }, 3000);
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-walking.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle  mb-2">Aktiv</div>
            <div className="daily-activity-item-description centered">
              Meistens zu Fuß (z. B. Verkauf, Pflegeberufe)
            </div>
          </div>
          <div
            className={`col-6 p-2 activity-level-box text-center ${
              activity === 1.7 ? 'is-selected' : ''
            }`}
            onClick={() => {
              setActivity(1.7);
              setCurrentStep(currentStep + 1);
              scrollToWidget();
              setTimeout(() => {
                setCurrentStep(currentStep + 2);
              }, 3000);
            }}
          >
            <img
              className="icon-svg small"
              src={`${ASSETS_URL}icon-manual-handling.svg`}
              alt="Lightly active"
              type="image/svg+xml"
            />
            <div className="choice-btn grey font-subtitle mb-2">Sehr aktiv</div>
            <div className="daily-activity-item-description centered">
              Körperlich anstrengende Tätigkeit (z. B. Bauarbeiter:in)
            </div>
          </div>
        </div>
      </>
    );
  }

  function selectMealsPerDay() {
    return (
      <>
        <div className="onboarding-question">
          Wie viele Mahlzeiten isst du <br /> normalerweise pro Tag?
        </div>

        <div className="onboarding-question-text mt-2 mb-4">
          Wir nutzen deine Essgewohnheiten um deinen Plan bestmöglich an deinen Alltag anzupassen
          und dir Aufwand zu sparen. Du kannst das später jederzeit ändern.
        </div>

        <div className="form-group onboarding-answer-wrapper">
          <select
            className="form-control"
            id="num_meals_selector"
            defaultValue={mealsAmount}
            onChange={(e) => {
              setMealsAmount(e.target.value);
            }}
          >
            <option value="1">1 Mahlzeit</option>
            <option value="2">2 Mahlzeiten</option>
            <option value="3">3 Mahlzeiten</option>
            <option value="4">4 Mahlzeiten</option>
            <option value="5">5 Mahlzeiten</option>
            <option value="6">6 Mahlzeiten</option>
            <option value="7">7 Mahlzeiten</option>
            <option value="8">8 Mahlzeiten</option>
            <option value="9">9 Mahlzeiten</option>
          </select>
        </div>

        <div className="mt-5">
          <BlockButton
            text={'Weiter'}
            onClick={() => {
              setCurrentStep(currentStep + 1);
              scrollToWidget();

              setTimeout(() => {
                handleSetUserData();
              }, 3000);
            }}
          ></BlockButton>
        </div>
      </>
    );
  }

  function selectDiet() {
    return (
      <>
        <div className="onboarding-question">Wie möchtest du dich ernähren?</div>

        <div className="onboarding-question-text mt-2 mb-4">
          Möchtest du eine bestimmte Diät befolgen? Du kannst diese Einstellung jederzeit wieder
          ändern.
        </div>

        <div className="onboarding-answer-wrapper">
          {diets.map((diet, index) => (
            <div
              key={`diets-${index}`}
              className={`p-4 onboarding-answer-button d-flex justify-content-between align-items-center ${
                selectedDiet === diet ? 'is-selected' : ''
              }`}
              onClick={() => {
                setSelectedDiet(diet);
                setCurrentStep(currentStep + 1);
                scrollToWidget();
              }}
            >
              <div className="d-flex justify-content-end align-items-center">
                <div className="me-3">
                  <img src={`${ASSETS_URL}${diet}.png`}></img>
                </div>
                <div className="text-left">
                  <span className="d-block">{t(`diet-${diet}-title`)}</span>
                  <small>{t(`diet-${diet}-text`)}</small>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
