import React from 'react';
import styled from 'styled-components';

const StyledCTAGreen = styled.div`
  border: none;
  padding: 16px 0;
  text-decoration: none;
  border-radius: 8px;
  transition: 0.1s all ease;
  font-weight: 700;
  font-size: 14px;
  background-color: rgb(18, 156, 144);
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //transition: 0.4s all;
  box-shadow: 0 2px 6px rgb(0 0 0 / 16%);

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: none !important;
  }

  .icon svg {
    width: 22px;
    height: 22px;
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    padding: 20px 20px;
    font-size: 16px;
  }
`;

export default function CTAGreen({ text, onClick, disabled, icon }) {
  return (
    <StyledCTAGreen
      className={`${disabled ? 'is-disabled' : ''}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {icon && <span className="icon">{icon}</span>}
      <div className="text">{text}</div>
    </StyledCTAGreen>
  );
}
