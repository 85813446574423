import styled from 'styled-components';

export const StyledDesktopNavigation = styled.div`
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 999;
  padding-bottom: env(safe-area-inset-bottom);
  left: 0;
  border-top: 1px solid #ddd;

  .sidenav {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      list-style: none;
      padding: 10px 0;
      font-size: 12px;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #8d8d8d;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #fff;
      transition: all 0.1s ease-in;
      border-top: 2px solid whitesmoke;

      &:last-child {
        margin-right: 0;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-bottom: 4px;
      }

      &:last-child {
        border: 0 none;
      }

      &:hover {
        color: #03a9f4;
        background-color: #fff;
        border-top: 2px solid #03a9f4;
      }

      &.is-active {
        color: #03a9f4;
        background-color: #fff;
        border-top: 2px solid #03a9f4;
        font-weight: bold;
      }
    }
  }

  @media (min-width: 768px) {
    position: initial;
    padding: 0;
    margin-bottom: 20px;
    box-shadow: none;
    border: 0 none;
    overflow: visible;

    .sidenav {
      margin: 0;
      padding: 0;
      display: flex;

      li {
        list-style: none;
        padding: 18px 20px;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #111;
        border-radius: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        margin-right: 20px;
        transition: all 0.1s ease-in;
        //box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
        box-shadow: rgb(0 0 0 / 12%) 0px 0px 50px;
        flex-direction: row;
        border: 0 none;

        &:last-child {
          margin-right: 0;
        }

        svg {
          margin-right: 16px;
          width: 20px;
          height: 20px;
          margin-bottom: 0;
        }

        &:last-child {
          border: 0 none;
        }

        &:hover {
          color: #fff;
          background-color: #03a9f4;
          border: 0 none;
        }

        &.is-active {
          color: #fff;
          background-color: #03a9f4;
          border: 0 none;
          font-weight: initial;
        }
      }
    }
  }
`;
