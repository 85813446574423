import {
  MEAL_PLAN_DATE_FORMAT,
  MEAL_PLAN_DAYS,
  MEAL_TYPE_MIX_MATCH,
} from 'constants/mealPlanConstants';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import locale from 'dayjs/locale/de';
import { MEAL_SLOT_EMPTY, MEAL_SLOT_SNACK } from 'constants/mealSlotNames';
import { filterMeals } from './filterMeals';

export function generateMealPlanDay(
  mixAndMatchChoices,
  targetCalories,
  amountOfMeals,
  diet,
  nutrition,
  useHalfUnits,
) {
  let mealForDay = [];

  let filteredMixAndMatchChoices = filterMeals(mixAndMatchChoices, diet, nutrition);

  for (let i = 0; i < amountOfMeals; i++) {
    let slot = {};
    const meal = JSON.parse(JSON.stringify(generateMeal(filteredMixAndMatchChoices)));
    const stepCalorieGoal = targetCalories / amountOfMeals;

    slot.detail = scaleMeal(meal, stepCalorieGoal, useHalfUnits);
    slot.type = MEAL_TYPE_MIX_MATCH;
    slot.stepCalorieGoal = stepCalorieGoal;
    slot.isReplaceable = true; // TODO: change this to false for slot plans (eg. smoothie diet)
    slot.isDone = false;
    slot.slotTitle = MEAL_SLOT_EMPTY;
    mealForDay.push(slot);
  }

  return mealForDay;
}

export function createMealSlot(
  mixAndMatchChoices,
  diet,
  nutrition,
  targetCalories,
  amountOfMeals,
  useHalfUnits,
) {
  let filteredMixAndMatchChoices = filterMeals(mixAndMatchChoices, diet, nutrition);

  let slot = {};
  const meal = JSON.parse(JSON.stringify(generateMeal(filteredMixAndMatchChoices)));
  const stepCalorieGoal = targetCalories / amountOfMeals;

  slot.detail = scaleMeal(meal, stepCalorieGoal, useHalfUnits);
  slot.type = MEAL_TYPE_MIX_MATCH;
  slot.stepCalorieGoal = stepCalorieGoal;
  slot.isReplaceable = true; // TODO: change this to false for slot plans (eg. smoothie diet)
  slot.isDone = false;
  slot.slotTitle = MEAL_SLOT_EMPTY;

  return slot;
}

export function generateMeal(mixAndMatchChoices) {
  let randomMeal = Math.floor(Math.random() * mixAndMatchChoices.length);
  let meal = mixAndMatchChoices[randomMeal];
  meal.steps.map((mealStep) => {
    if (mealStep.follow_order) {
      mealStep.selectedIngredient = [mealStep.ingredients[0]];
    } else {
      const random = Math.floor(Math.random() * mealStep.ingredients.length);
      mealStep.selectedIngredient = [mealStep.ingredients[random]];
    }
  });

  let mealWithCalories = calculateBaseNutritionValues(meal);

  return mealWithCalories;
}

export function generateFixedMeal(meal) {
  meal.steps.map((mealStep) => {
    if (mealStep.follow_order) {
      mealStep.selectedIngredient = [mealStep.ingredients[0]];
    } else {
      const random = Math.floor(Math.random() * mealStep.ingredients.length);
      mealStep.selectedIngredient = [mealStep.ingredients[random]];
    }
  });

  let mealWithCalories = calculateBaseNutritionValues(meal);

  return mealWithCalories;
}

export function replaceMeal(mixAndMatchChoices, diet, nutrition) {
  let filteredMeals = filterMeals(mixAndMatchChoices, diet, nutrition);
  return JSON.parse(JSON.stringify(generateMeal(filteredMeals)));
}

export function replaceIngredient(
  meal,
  stepIndex,
  ingredientIndex,
  newIngredientIndex,
  useHalfUnits,
) {
  let newMeal = JSON.parse(JSON.stringify(meal));

  newMeal.steps[stepIndex].selectedIngredient[ingredientIndex] =
    newMeal.steps[stepIndex].ingredients[newIngredientIndex];

  /*if (newMeal.steps[stepIndex].ingredients.length > 1) {
    do {
      const randomIndex = Math.floor(Math.random() * newMeal.steps[stepIndex].ingredients.length);
      newMeal.steps[stepIndex].selectedIngredient[ingredientIndex] =
        newMeal.steps[stepIndex].ingredients[randomIndex];
    } while (newMeal.steps[stepIndex].selectedIngredient[ingredientIndex].ingredient === currentID);
  }*/

  let mealWithCalories = calculateBaseNutritionValues(newMeal);
  let scaledMeal = scaleMeal(mealWithCalories, meal.scaledCalories, useHalfUnits);

  return scaledMeal;
}

export function addIngredient(meal, stepIndex, useHalfUnits) {
  let newMeal = JSON.parse(JSON.stringify(meal));

  // ToDo: Sichergehen, dass die Zutat nicht schon drin ist mit Do-While auf Index 0
  const randomIndex = Math.floor(Math.random() * newMeal.steps[stepIndex].ingredients.length);

  newMeal.steps[stepIndex].selectedIngredient.push(
    newMeal.steps[stepIndex].ingredients[randomIndex],
  );

  let mealWithCalories = calculateBaseNutritionValues(newMeal);
  let scaledMeal = scaleMeal(mealWithCalories, meal.scaledCalories, useHalfUnits);

  return scaledMeal;
}

export function calculateBaseNutritionValues(meal) {
  meal.calories = 0;
  meal.protein = 0;
  meal.carbs = 0;
  meal.sugar = 0;
  meal.fat = 0;
  meal.fiber = 0;

  meal.steps.map((mealStep) => {
    mealStep.selectedIngredient.map((selectedIngredient) => {
      selectedIngredient.totalAmountInGrams =
        Number(selectedIngredient.amount) * Number(selectedIngredient.detail.serving_unit_in_grams);

      // calories
      meal.calories += Math.round(
        (selectedIngredient.detail.calories / 100) * selectedIngredient.totalAmountInGrams,
      );

      // protein
      meal.protein += Math.round(
        (selectedIngredient.detail.protein / 100) * selectedIngredient.totalAmountInGrams,
      );

      // carbs
      meal.carbs += Math.round(
        (selectedIngredient.detail.carbs / 100) * selectedIngredient.totalAmountInGrams,
      );

      // sugar
      meal.sugar += Math.round(
        (selectedIngredient.detail.sugar / 100) * selectedIngredient.totalAmountInGrams,
      );

      // fat
      meal.fat += Math.round(
        (selectedIngredient.detail.fat / 100) * selectedIngredient.totalAmountInGrams,
      );

      // fiber
      meal.fiber += Math.round(
        (selectedIngredient.detail.fiber / 100) * selectedIngredient.totalAmountInGrams,
      );
    });
  });

  return meal;
}

export function scaleMeal(meal, calories, useHalfUnits) {
  let ratio = Number(calories / meal.calories);

  if (ratio < 1) {
    ratio = roundHalf(ratio);
  } else {
    if (useHalfUnits) {
      ratio = roundHalf(ratio);
    } else {
      ratio = Math.round(ratio);
    }
  }

  const scaledMeal = Object.assign({}, meal);

  scaledMeal.scaledCalories = Number(meal.calories * ratio);
  scaledMeal.steps.map((mealStep) => {
    mealStep.selectedIngredient.map((selectedIngredient) => {
      selectedIngredient.amount = selectedIngredient.defaultAmount * ratio;
    });
  });

  scaledMeal.ratio = ratio;

  return scaledMeal;
}

export function scaleRecipe(recipe, calories) {
  const ratio = roundHalf(calories / meal.calories);
  //const ratio = Math.round(calories / recipe.calories);
  const scaledRecipe = Object.assign({}, recipe);

  scaledRecipe.scaledCalories = recipe.calories * ratio;

  scaledRecipe.ratio = ratio;

  return scaledRecipe;
}

function roundHalf(num) {
  return Math.round(num * 2) / 2;
}

export const fillMealPlanDays = (mealPlan, targetCalories) => {
  dayjs.extend(customParseFormat);
  let today = dayjs();

  if (mealPlan && mealPlan[mealPlan.length - 1]) {
    today = dayjs(mealPlan[mealPlan.length - 1].date, MEAL_PLAN_DATE_FORMAT);
  }

  do {
    let day = {
      date: today.format(MEAL_PLAN_DATE_FORMAT),
      targetCalories: targetCalories,
      weekDay: today.locale(locale).format('dddd'),
      dateOfMonth: today.locale(locale).format('DD'), // DD MMMM
      month: today.locale(locale).format('MMMM'), // DD MMMM
      meals: [],
      recipes: [],
    };

    mealPlan.push(day);
    today = today.add(1, 'day');
  } while (mealPlan.length <= MEAL_PLAN_DAYS);

  return mealPlan;
};

export function rescaleMealPlanDay(recipesForDay, mealsForDay, targetCalories, useHalfUnits) {
  // todo: wenn mealsForDay typ recipe ist, dann muss ich die targetCalories neu berechnen
  let caloriesFromRecipes = 0;

  if (recipesForDay) {
    recipesForDay.forEach((recipe) => {
      caloriesFromRecipes += recipe.selectedServing * Number(recipe.calories);
    });
  }

  const targetCaloriesForMeals = Number(targetCalories) - Number(caloriesFromRecipes);
  const stepCalorieGoal = targetCaloriesForMeals / mealsForDay.length;

  if (mealsForDay.length > 0) {
    mealsForDay.forEach((meal) => {
      let newMeal = meal.detail;

      newMeal.steps.map((mealStep) => {
        mealStep.selectedIngredient.map((selectedIngredient) => {
          selectedIngredient.amount = selectedIngredient.defaultAmount;
        });
      });

      newMeal = calculateBaseNutritionValues(newMeal);
      newMeal = scaleMeal(newMeal, stepCalorieGoal, useHalfUnits);
      meal.detail = newMeal;
    });
  }

  return mealsForDay;
}
